import {
  IAuditLogReferencesBody,
  IAuditLogsBody,
  IUpdateOrgBody,
} from 'types/api.type';
import instance from './api';

export const postGetAccountInfo = async (def: null) => {
  const { data } = await instance.post('v1/account', def);

  return data;
};

export const postRetrieveOrganization = async (orgId: number) => {
  const { data } = await instance.post('v1/organization', { id: orgId });

  return data;
};

export const postUpdateOrganization = async (body: IUpdateOrgBody) => {
  const { data } = await instance.post('v1/update-org', body);

  return data;
};

export const postRetrieveClientIntegrations = async (clientId: number) => {
  const { data } = await instance.post('v1/retrieve-client-intgrns', {
    client_id: clientId,
  });

  return data;
};

export const postRetrieveAuditLogs = async (body: IAuditLogsBody) => {
  const { data } = await instance.post('v1/log-events', body);

  return data;
};

export const postRetrieveAuditLogRefs = async (
  body: IAuditLogReferencesBody
) => {
  const { data } = await instance.post('v1/log-event-refs', body);

  return data;
};
