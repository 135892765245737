import {
  Box,
  BoxProps,
  Divider,
  MenuItem,
  PaperProps,
  Select,
  SelectProps,
  styled,
} from '@mui/material';
import { INTER } from 'constants/fonts';
import { theme } from 'theme';

export const ActionsWrapper = styled(Box)<BoxProps>(() => ({
  width: 'fit-content',
  height: '36px',
  position: 'relative',
}));

export const MenuProps: Partial<{ PaperProps: Partial<PaperProps> }> = {
  PaperProps: {
    style: {
      boxSizing: 'border-box',
      padding: '0px 10px',
      borderRadius: '9px',
      background: theme.palette.common.white,
      boxShadow:
        '6px 2px 16px 0px rgba(136, 165, 191, 0.48), -6px -2px 16px 0px rgba(255, 255, 255, 0.80)',
    },
  },
};

export const Selection = styled(Select)<SelectProps>(({ theme }) => ({
  width: '100%',
  height: '36px',
  borderRadius: '21px',
  background: 'rgba(255, 255, 255, 0)',
  padding: '6px 18x',
  color: theme.palette.secondary.main,
  fontFamily: INTER,
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '18px',
  letterSpacing: '-0.264px',
  textTransform: 'uppercase',
  backgroundColor: 'inherit',
  '&.MuiSelect-icon': {
    color: theme.palette.secondary.main,
    //padding: '0px 10px',
  },
  '&.Mui-focused': {
    background: theme.palette.shades.main,
    color: theme.palette.secondary.main,
    '&.Mui-selected': {
      background: theme.palette.shades.main,
      color: theme.palette.common.white,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.text.disabled}`,
    },
  },
}));

export const ActionsDivider = styled(Divider)(() => ({
  display: 'block',
  width: '132px',
  height: '1px',
  background: '#D7D7D7',
}));

export const ActionMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '14px',
  fontFamily: INTER,
  fontWeight: 600,
  lineHeight: '21px',
  letterSpacing: '-0.308px',
  textTransform: 'uppercase',
  padding: '0',
  gap: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover, &:focus': {
    color: theme.palette.primary.main,
    background: 'transparent',
  },
  '& +.MuiDivider-root': {
    margin: 0,
  },
}));

export const SelectedOption = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  border: '0px',
}));
