import { ROUTES } from 'routes/routes.const';
import {
  WrapperChildren,
  WrapperSaaSAlertsOverviewPage,
} from './SaaSAlertsOverviewPage.styled';
import { TextTabs } from 'ui';
import { Navigate, Outlet, useLocation } from 'react-router';

const SaaSAlertsOverviewPage = () => {
  const location = useLocation();

  const navOptions = [
    {
      title: 'Dashboard',
      url: ROUTES.SAAS_ALERTS_OVERVIEW_DASHBOARD,
    },
    {
      title: 'Tickets',
      url: ROUTES.SAAS_ALERTS_OVERVIEW_TICKETS,
    },
  ];

  // Redirect if the user visits /huntress_dashboard directly
  if (location.pathname === ROUTES.SAAS_ALERTS_OVERVIEW) {
    return <Navigate to={ROUTES.SAAS_ALERTS_OVERVIEW_DASHBOARD} replace />;
  }

  return (
    <WrapperSaaSAlertsOverviewPage>
      <WrapperChildren>
        <TextTabs height="20px" options={navOptions} hierarchy="child" />

        <Outlet />
      </WrapperChildren>
    </WrapperSaaSAlertsOverviewPage>
  );
};

export default SaaSAlertsOverviewPage;
