import {
  ICreateAssignmentBody,
  ICreateClientContactBody,
  IUpdateAssignmentBody,
  IUpdateClientContactBody,
} from 'types/assignments.type';
import instance from './api';

export const postRetrieveAssignments = async (clientId: number) => {
  const { data } = await instance.post('v1/retrieve-assignments', {
    client_id: clientId,
  });

  return data;
};

export const postRemoveAssignment = async (assignmentId: number) => {
  const { data } = await instance.post('v1/remove-assignment', {
    id: assignmentId,
  });

  return data;
};

export const postUpdateAssignment = async (body: IUpdateAssignmentBody) => {
  const { data } = await instance.post('v1/update-assignment', body);

  return data;
};

export const postCreateAssignment = async (body: ICreateAssignmentBody) => {
  const { data } = await instance.post('v1/create-assignment', body);

  return data;
};

export const postRetrieveClientContacts = async (clientId: number) => {
  const { data } = await instance.post('v1/retrieve-client-contacts', {
    client_id: clientId,
  });

  return data;
};

export const postCreateClientContact = async (
  body: ICreateClientContactBody
) => {
  const { data } = await instance.post('v1/create-client-contact', body);

  return data;
};

export const postUpdateClientContact = async (
  body: IUpdateClientContactBody
) => {
  const { data } = await instance.post('v1/update-client-contact', body);

  return data;
};

export const postRemoveClientContact = async (id: number) => {
  const { data } = await instance.post('v1/remove-client-contact', { id: id });

  return data;
};
