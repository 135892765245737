export const ROUTES = {
  DASHBOARD: '/',
  CLIENT_DASHBOARD: '/client_dashboard',
  SIGNIN: '/login',

  REDIRECT: 'redirect', //right now does not used
  PROCESS_AUTH: '/process-auth',

  //steps in account creation
  ORGANIZATION_PROFILE: '/organization_profile',
  CLIENTS_CONFIG: '/clients_config',
  SERVICES_CONFIG: '/services_config',
  TEAM_MEMBERS_CONFIG: '/team_members_config',

  // --- TEAM MEMBERS PAGES ---
  TEAM_MEMBERS: '/team_members',
  TEAM_MEMBERS_LIST: '/team_members/team_members_list',
  ADD_OR_UPDATE_TEAM_MEMBER: '/team_members/add_or_update_team_member',
  DELETE_TEAM_MEMBER: '/team_members/delete_team_member',

  // --- CLIENTS PAGES ---
  CLIENTS: '/clients',
  CLIENTS_LIST: '/clients/clients_list',
  ADD_CLIENT: '/clients/add_client',
  UPDATE_CLIENT: '/clients/update_client',
  DELETE_CLIENT: '/clients/delete_client',

  // --- SERVICES PAGES ---
  SERVICES: '/services',
  ADD_SERVICE: '/services/add_service',
  DELETE_INTEGRATION: '/services/delete_integration',

  // --- TICKETS PAGES ---
  TICKETS: '/tickets',

  // --- AUDIT LOGS PAGES ---
  AUDIT_LOGS: '/audit_logs',

  // --- HUNTRESS PAGES ---
  HUNTRESS_DASHBOARD: '/services/huntress_dashboard',
  // --- Huntress Dashboard Children ---
  HUNTRESS_DASHBOARD_AGENTS: '/services/huntress_dashboard/agents',
  HUNTRESS_DASHBOARD_INCIDENTS: '/services/huntress_dashboard/incidents',
  HUNTRESS_DASHBOARD_REPORTS: '/services/huntress_dashboard/reports',

  HUNTRESS_EDR: '/services/huntress_edr',
  HUNTRESS_ITDR: '/services/huntress_itdr',

  // --- SAAS ALERTS PAGES ---
  SAAS_ALERTS_OVERVIEW: '/services/saas_alerts_overview',
  // --- SaaS Alerts Overview Children
  SAAS_ALERTS_OVERVIEW_DASHBOARD: '/services/saas_alerts_overview/dashboard',
  SAAS_ALERTS_OVERVIEW_TICKETS: '/services/saas_alerts_overview/tickets',

  SAAS_ALERTS_EVENT_MONITORING: '/services/saas_alerts_event_monitoring',
  // --- SaaS Alerts Event Monitoring Children ---
  SAAS_ALERTS_EVENT_MONITORING_CRITICAL:
    '/services/saas_alerts_event_monitoring/critical',
  SAAS_ALERTS_EVENT_MONITORING_MEDIUM:
    '/services/saas_alerts_event_monitoring/medium',
  SAAS_ALERTS_EVENT_MONITORING_LOW:
    '/services/saas_alerts_event_monitoring/low',

  // --- SaaS Alerts Account Management Page
  SAAS_ALERTS_ACCOUNT_MANAGEMENT: '/services/saas_alerts_account_management',

  // --- SaaS Alerts Analysis Page ---
  SAAS_ALERTS_ANALYSIS: '/services/saas_alerts_analysis',

  // --- CONNECTWISE PAGES ---
  CONNECT_WISE_DASHBOARD: '/services/connect_wise_dashboard',

  CONNECT_WISE_DEVICES: '/services/connect_wise_devices',
  // --- ConnectWise Devices Children ---
  CONNECT_WISE_NETWORK_DEVICES:
    '/services/connect_wise_devices/network_devices',

  CONNECT_WISE_ALERTS: '/services/connect_wise_alerts',
  CONNECT_WISE_USERS: '/services/connect_wise_users',

  // --- SETTINGS PAGES ---
  BUSINESS_SETTINGS: '/business_settings',
  ACCOUNT_SETTINGS: '/account_settings',
};

export const CREATE_ORG_ROUTES = [
  ROUTES.ORGANIZATION_PROFILE,
  ROUTES.CLIENTS_CONFIG,
  ROUTES.SERVICES_CONFIG,
  ROUTES.TEAM_MEMBERS_CONFIG,
];
