import { RootState } from 'reduxStore/store';

// --- Huntress Selectors
const getHuntressGeneralStatistics = (state: RootState) =>
  state.tools.huntressGeneralStatistics;
const getHuntressAgents = (state: RootState) => state.tools.huntressAgents;
const getHuntressIncidentRefs = (state: RootState) =>
  state.tools.huntressIncidentRefs;
const getHuntressIncidents = (state: RootState) =>
  state.tools.huntressIncidents;
const getHuntressReportRefs = (state: RootState) =>
  state.tools.huntressReportRefs;
const getHuntressReports = (state: RootState) => state.tools.huntressReports;

// --- SaaS Alerts Selectors
const getSaaSAlertsTicketStatistics = (state: RootState) =>
  state.tools.saasAlertsTicketStatistics;
const getSaaSAlertsSeverityLevels = (state: RootState) =>
  state.tools.saasAlertsSeverityLevels;
const getSaaSAlertsEvents = (state: RootState) => state.tools.saasAlertsEvents;
const getSaasAlertsEventRefs = (state: RootState) =>
  state.tools.saasAlertsEventRefs;
const getSaasAlertsUsers = (state: RootState) => state.tools.saasAlertsUsers;
const getSaasAlertsLocations = (state: RootState) =>
  state.tools.saasAlertsLocations;

// --- General Tools Selectors
const getToolsAmountOfElements = (state: RootState) =>
  state.tools.amountOfElements;
const getToolsSelectedOrgId = (state: RootState) => state.tools.selectedOrgId;
const getToolsSelectedOrgName = (state: RootState) =>
  state.tools.selectedOrgName;
const getToolsSelectedToolId = (state: RootState) => state.tools.selectedToolId;
const getToolsError = (state: RootState) => state.tools.error;
const getToolsLoading = (state: RootState) => state.tools.loading;

export {
  getHuntressGeneralStatistics,
  getHuntressAgents,
  getHuntressIncidentRefs,
  getHuntressIncidents,
  getHuntressReportRefs,
  getHuntressReports,
  getSaaSAlertsTicketStatistics,
  getSaaSAlertsSeverityLevels,
  getSaaSAlertsEvents,
  getSaasAlertsEventRefs,
  getSaasAlertsUsers,
  getSaasAlertsLocations,
  getToolsAmountOfElements,
  getToolsSelectedOrgName,
  getToolsSelectedOrgId,
  getToolsSelectedToolId,
  getToolsError,
  getToolsLoading,
};
