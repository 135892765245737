import {
  IImportUsersBody,
  ISetUpSelectedCloudsBody,
  ICreateMSPContactBody,
  ICreateClientBody,
  IReceiveToolOrgsBody,
  ICreateIntegrationBody,
  IDeleteIntgrnBody,
  IDeleteContactBody,
  IOrganizationProfileRequestBody,
  IUpdateMSPContactBody,
  IRemoveListOfMSPContactsBody,
} from 'types/api.type';
import instance from './api';

export const postCreateMSP = async (body: IOrganizationProfileRequestBody) => {
  const { data } = await instance.post('v1/create-msp', body);

  return data;
};

export const postCreateClient = async (body: ICreateClientBody) => {
  const { data } = await instance.post('v1/create-client', body);

  return data;
};

export const postGetClouds = async (id: number | null) => {
  const { data } = await instance.post('v1/clouds', { org_id: id });

  return data;
};

export const postSetUpClouds = async (body: ISetUpSelectedCloudsBody) => {
  const { data } = await instance.post('v1/setup-cloud-regions', body);

  return data;
};

export const postImportUsers = async (body: IImportUsersBody) => {
  const { data } = await instance.post('v1/import-users', body);

  return data;
};

export const postRetrieveMSPContacts = async (def: null) => {
  const { data } = await instance.post('v1/retrieve-msp-contacts', {
    def: null,
  });

  return data;
};

export const postRetrieveAssignableContacts = async (def: null) => {
  const { data } = await instance.post('v1/assignable-contacts', {
    def: null,
  });

  return data;
};

export const postCreateMSPContact = async (body: ICreateMSPContactBody) => {
  const { data } = await instance.post('v1/create-msp-contact', body);

  return data;
};

export const postUpdateMSPContact = async (body: IUpdateMSPContactBody) => {
  const { data } = await instance.post('v1/update-msp-contact', body);

  return data;
};

export const postRetrieveMSPRoles = async (def: null) => {
  const { data } = await instance.post('v1/msp-roles', { def: null });

  return data;
};

export const postRetrieveAssignableRoles = async (def: null) => {
  const { data } = await instance.post('v1/assignable-roles', { def: null });

  return data;
};

export const postRetrieveClients = async (tool_id: number | null) => {
  const { data } = await instance.post('v1/clients', { tool_id: tool_id });

  return data;
};

export const postRetrieveClientsRef = async (tool_id: number | null) => {
  const { data } = await instance.post('v1/clients-ref', { tool_id: tool_id });

  return data;
};

export const postRetrieveTools = async (def: null) => {
  const { data } = await instance.post('v1/tools', { def: null });

  return data;
};

export const postRetrieveToolsRef = async (def: null) => {
  const { data } = await instance.post('v1/tools-ref', { def: null });

  return data;
};

export const postCheckAPICredentials = async (body: IReceiveToolOrgsBody) => {
  const { data } = await instance.post('v1/check-intgrn-auth', body);

  return data;
};

export const postCreateIntegration = async (body: ICreateIntegrationBody) => {
  const { data } = await instance.post('v1/create-intgrn', body);

  return data;
};

export const postRetrieveToolOrgs = async (id: number) => {
  const { data } = await instance.post('v1/tool-orgs', { tool_id: id });

  return data;
};

export const postDeleteIntegration = async (body: IDeleteIntgrnBody) => {
  const { data } = await instance.post('v1/delete-intgrn', body);

  return data;
};

export const postRemoveClient = async (id: number) => {
  const { data } = await instance.post('v1/remove-client', { id: id });

  return data;
};

export const postRemoveListOfClients = async (ids: number[]) => {
  const { data } = await instance.post('v1/remove-clients', { ids });

  return data;
};

export const postRemoveMSPContact = async (body: IDeleteContactBody) => {
  const { data } = await instance.post('v1/remove-msp-contact', body);

  return data;
};

export const postRemoveListOfMSPContacts = async (
  body: IRemoveListOfMSPContactsBody
) => {
  const { data } = await instance.post('v1/remove-msp-contacts', body);

  return data;
};
