import { Box, BoxProps, styled } from '@mui/material';
import { COLORS } from 'theme/colors.const';

export const WrapperConnectWiseDevicesPage = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  marginTop: '20px',
}));

export const WrapperChildren = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  gap: '33px',
  padding: '10px 30px',
  borderRadius: '16px',
  border: `2px solid ${COLORS.gray}`,
}));
