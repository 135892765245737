import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { INTER } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

interface ILogoProps extends BoxProps {
  src: string;
}

export const Wrapper = styled(Box)<BoxProps>(() => ({
  background: COLORS.white,
  padding: '30px',
  borderRadius: '12px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  gap: '50px',
}));

export const WrapperElements = styled(Box)<BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'left',
  gap: '6.5%',
  rowGap: '30px',
}));

export const WrapperSection = styled(Box)<BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  gap: '10px',
}));

export const Element = styled(Button)<ButtonProps>(({ theme }) => ({
  width: 'fit-content',
  minWidth: '20%',
  background: COLORS.white,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '18px',
  border: `1px solid ${theme.palette.black.dark}`,
  padding: '15px',
  gap: '5px',
  '&:hover': {
    border: `1px solid ${theme.palette.extra.main}`,
    background: COLORS.white,
  },
}));

export const LogoBox = styled(Box)<ILogoProps>(({ src }) => ({
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '48px',
  height: '48px',
  borderRadius: '50%',
}));

export const TitleSection = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.secondary,
  fontFamily: INTER,
  fontSize: '28px',
  fontWeight: 600,
  lineHeight: '150%',
  letterSpacing: '-2.2%',
}));

export const TitleElement = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.secondary,
  fontFamily: INTER,
  fontSize: '22px',
  fontWeight: 500,
  lineHeight: '150%',
  letterSpacing: '-2.2%',
}));

export const MainTextElement = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.secondary,
  fontFamily: INTER,
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '150%',
  letterSpacing: '-2.2%',
}));
