import {
  IHuntressIncidentsBody,
  IHuntressReportsBody,
} from 'types/huntress.type';
import instance from './api';
import {
  ISaaSAlertsDetailedLocationsBody,
  ISaaSAlertsEventReferencesBody,
  ISaaSAlertsEventsBody,
  ISaaSAlertsTicketsBody,
  ISaaSAlertsUsersBody,
} from 'types/saasAlerts.type';

// --- Huntress Requests ---
export const postRetrieveHuntressGeneralStatistics = async (orgId: number) => {
  const { data } = await instance.post('v1/huntress-stats', { org_id: orgId });

  return data;
};

export const postRetrieveHuntressAgents = async (orgId: number) => {
  const { data } = await instance.post('v1/huntress-agents', { org_id: orgId });

  return data;
};

export const postRetrieveHuntressIncidentsRefs = async (orgId: number) => {
  const { data } = await instance.post('v1/huntress-incident-refs', {
    org_id: orgId,
  });

  return data;
};

export const postRetrieveHuntressIncidents = async (
  body: IHuntressIncidentsBody
) => {
  const { data } = await instance.post('v1/huntress-incidents', body);

  return data;
};

export const postRetrieveHuntressReportsRefs = async (orgId: number) => {
  const { data } = await instance.post('v1/huntress-report-refs', {
    org_id: orgId,
  });

  return data;
};

export const postRetrieveHuntressReports = async (
  body: IHuntressReportsBody
) => {
  const { data } = await instance.post('v1/huntress-reports', body);

  return data;
};

// --- SaaS Alerts Requests ---
export const postRetrieveSaaSAlertsTicketStats = async (
  body: ISaaSAlertsTicketsBody
) => {
  const { data } = await instance.post('v1/saas-ticket-stats', body);

  return data;
};

export const postRetrieveSaaSAlertsSeverityLevels = async (
  clientId: number | null
) => {
  const { data } = await instance.post('v1/saas-severity', {
    client_id: clientId,
  });

  return data;
};

export const postRetrieveSaaSAlertsAllSeverityLevels = async (def: null) => {
  const { data } = await instance.post('v1/saas-all-severity', {
    def: null,
  });

  return data;
};

export const postRetrieveSaaSAlertsEvents = async (
  body: ISaaSAlertsEventsBody
) => {
  const { data } = await instance.post('v1/saas-events', body);

  return data;
};

export const postRetrieveSaaSAlertsEventRefs = async (
  body: ISaaSAlertsEventReferencesBody
) => {
  const { data } = await instance.post('v1/saas-event-refs', body);

  return data;
};

export const postRetrieveSaaSAlertsUsers = async (
  body: ISaaSAlertsUsersBody
) => {
  const { data } = await instance.post('v1/saas-users', body);

  return data;
};

export const postRetrieveSaaSAlertsLocations = async (
  body: ISaaSAlertsDetailedLocationsBody
) => {
  const { data } = await instance.post('v1/saas-locs', body);

  return data;
};
