import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setError, setLoading } from 'reduxStore/extraReducersHelpers';
import { ILoadableState, IReference } from 'types';
import {
  IHuntressAgent,
  IHuntressGeneralStatistics,
  IHuntressIncident,
  IHuntressIncidentsRef,
  IHuntressReport,
  IHuntressReportsRef,
} from 'types/huntress.type';
import {
  retrieveHuntressAgents,
  retrieveHuntressGeneralStatistics,
  retrieveHuntressIncidents,
  retrieveHuntressIncidentsRefs,
  retrieveHuntressReports,
  retrieveHuntressReportsRefs,
  retrieveSaaSAlertsAllSeverityLevels,
  retrieveSaaSAlertsEventRefs,
  retrieveSaaSAlertsEvents,
  retrieveSaaSAlertsLocations,
  retrieveSaaSAlertsSeverityLevels,
  retrieveSaaSAlertsTicketStats,
  retrieveSaaSAlertsUsers,
} from './toolsOperations';
import {
  ISaaSAlertsDetailedLocation,
  ISaaSAlertsEvent,
  ISaaSAlertsEventReferences,
  ISaaSAlertsTicketsRecord,
  ISaaSAlertsUser,
} from 'types/saasAlerts.type';

interface IToolsState extends ILoadableState {
  huntressGeneralStatistics: IHuntressGeneralStatistics | null;
  huntressAgents: IHuntressAgent[];
  huntressIncidentRefs: IHuntressIncidentsRef | null;
  huntressIncidents: IHuntressIncident[];
  huntressReportRefs: IHuntressReportsRef | null;
  huntressReports: IHuntressReport[];
  saasAlertsTicketStatistics: ISaaSAlertsTicketsRecord[];
  saasAlertsSeverityLevels: IReference[];
  saasAlertsEvents: ISaaSAlertsEvent[];
  saasAlertsEventRefs: ISaaSAlertsEventReferences | null;
  saasAlertsUsers: ISaaSAlertsUser[];
  saasAlertsLocations: ISaaSAlertsDetailedLocation[];
  selectedOrgName: string;
  selectedOrgId: number;
  selectedToolId: number;
  amountOfElements: number;
}

const initialState: IToolsState = {
  huntressGeneralStatistics: null,
  huntressAgents: [],
  huntressIncidentRefs: null,
  huntressIncidents: [],
  huntressReportRefs: null,
  huntressReports: [],
  saasAlertsTicketStatistics: [],
  saasAlertsSeverityLevels: [],
  saasAlertsEvents: [],
  saasAlertsEventRefs: null,
  saasAlertsUsers: [],
  saasAlertsLocations: [],
  selectedOrgName: '',
  selectedOrgId: 0,
  selectedToolId: 1,
  amountOfElements: 0,
  loading: false,
  error: null,
};

const toolsSlice = createSlice({
  name: 'tools',
  initialState,
  reducers: {
    setToolsSelectedOrgName(state, action: PayloadAction<string>) {
      state.selectedOrgName = action.payload;
    },
    setToolsSelectedOrgId(state, action: PayloadAction<number>) {
      state.selectedOrgId = action.payload;
    },
    setToolsSelectedToolId(state, action: PayloadAction<number>) {
      state.selectedToolId = action.payload;
    },
    resetToolsError(state) {
      state.error = null;
    },
    resetToolsSelectedOrgAndTool(state) {
      state.selectedOrgId = 0;
      state.selectedOrgName = '';
      state.selectedToolId = 1;
    },
    resetHuntressData: state => {
      state.huntressAgents = [];
      state.huntressGeneralStatistics = null;
      state.huntressIncidentRefs = null;
      state.huntressIncidents = [];
      state.huntressReportRefs = null;
      state.huntressReports = [];
    },
    resetSaaSAlertsData: state => {
      state.saasAlertsTicketStatistics = [];
      state.saasAlertsSeverityLevels = [];
      state.saasAlertsEvents = [];
      state.amountOfElements = 0;
      state.saasAlertsEventRefs = null;
      state.saasAlertsUsers = [];
    },
    resetSaaSAlertsEvents: state => {
      state.amountOfElements = 0;
      state.saasAlertsEvents = [];
    },
    resetSaaSAlertsUsers: state => {
      state.saasAlertsUsers = [];
    },
    resetToolsAllData: () => initialState,
  },
  extraReducers: builder => {
    builder
      // --- Huntress Requests ---
      //retrieveHuntressGeneralStatistics
      .addCase(retrieveHuntressGeneralStatistics.pending, setLoading)
      .addCase(
        retrieveHuntressGeneralStatistics.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.huntressGeneralStatistics = payload;
        }
      )
      .addCase(retrieveHuntressGeneralStatistics.rejected, setError)
      //retrieveHuntressAgents
      .addCase(retrieveHuntressAgents.pending, setLoading)
      .addCase(retrieveHuntressAgents.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.huntressAgents = payload.agents;
      })
      .addCase(retrieveHuntressAgents.rejected, setError)
      //retrieveHuntressIncidentsRefs
      .addCase(retrieveHuntressIncidentsRefs.pending, setLoading)
      .addCase(
        retrieveHuntressIncidentsRefs.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.huntressIncidentRefs = payload;
        }
      )
      .addCase(retrieveHuntressIncidentsRefs.rejected, setError)
      //retrieveHuntressIncidents
      .addCase(retrieveHuntressIncidents.pending, setLoading)
      .addCase(retrieveHuntressIncidents.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.huntressIncidents = payload.incidents;
      })
      .addCase(retrieveHuntressIncidents.rejected, setError)
      //retrieveHuntressReportsRefs
      .addCase(retrieveHuntressReportsRefs.pending, setLoading)
      .addCase(retrieveHuntressReportsRefs.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.huntressReportRefs = payload;
      })
      .addCase(retrieveHuntressReportsRefs.rejected, setError)
      //retrieveHuntressReports
      .addCase(retrieveHuntressReports.pending, setLoading)
      .addCase(retrieveHuntressReports.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.huntressReports = payload.reports;
      })
      .addCase(retrieveHuntressReports.rejected, setError)
      // --- SaaS Alerts Requests ---
      //retrieveSaaSAlertsTicketStats
      .addCase(retrieveSaaSAlertsTicketStats.pending, setLoading)
      .addCase(
        retrieveSaaSAlertsTicketStats.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.saasAlertsTicketStatistics = payload.records;
        }
      )
      .addCase(retrieveSaaSAlertsTicketStats.rejected, setError)
      //retrieveSaaSAlertsAllSeverityLevels
      .addCase(retrieveSaaSAlertsAllSeverityLevels.pending, setLoading)
      .addCase(
        retrieveSaaSAlertsAllSeverityLevels.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.saasAlertsSeverityLevels = payload.severity;
        }
      )
      .addCase(retrieveSaaSAlertsAllSeverityLevels.rejected, setError)
      //retrieveSaaSAlertsSeverityLevels
      .addCase(retrieveSaaSAlertsSeverityLevels.pending, setLoading)
      .addCase(
        retrieveSaaSAlertsSeverityLevels.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.saasAlertsSeverityLevels = payload.severity;
        }
      )
      .addCase(retrieveSaaSAlertsSeverityLevels.rejected, setError)
      //retrieveSaaSAlertsEvents
      .addCase(retrieveSaaSAlertsEvents.pending, setLoading)
      .addCase(retrieveSaaSAlertsEvents.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.saasAlertsEvents = payload.events;
        state.amountOfElements = payload.count;
      })
      .addCase(retrieveSaaSAlertsEvents.rejected, setError)
      //retrieveSaaSAlertsEventRefs
      .addCase(retrieveSaaSAlertsEventRefs.pending, setLoading)
      .addCase(retrieveSaaSAlertsEventRefs.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.saasAlertsEventRefs = payload;
      })
      .addCase(retrieveSaaSAlertsEventRefs.rejected, setError)
      //retrieveSaaSAlertsUsers
      .addCase(retrieveSaaSAlertsUsers.pending, setLoading)
      .addCase(retrieveSaaSAlertsUsers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.saasAlertsUsers = payload.users;
      })
      .addCase(retrieveSaaSAlertsUsers.rejected, setError)
      //retrieveSaaSAlertsLocations
      .addCase(retrieveSaaSAlertsLocations.pending, setLoading)
      .addCase(retrieveSaaSAlertsLocations.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.saasAlertsLocations = payload.locs;
      })
      .addCase(retrieveSaaSAlertsLocations.rejected, setError);
  },
});

export const {
  setToolsSelectedOrgName,
  setToolsSelectedOrgId,
  setToolsSelectedToolId,
  resetHuntressData,
  resetToolsError,
  resetToolsSelectedOrgAndTool,
  resetToolsAllData,
  resetSaaSAlertsData,
  resetSaaSAlertsEvents,
  resetSaaSAlertsUsers,
} = toolsSlice.actions;

export default toolsSlice.reducer;
