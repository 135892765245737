import { Box, BoxProps, styled, TextField, Typography } from '@mui/material';
import { INTER } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

export const Wrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  padding: '30px',
  borderRadius: '12px',
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  gap: '25px',
}));

export const CustomTextField = styled(TextField)(({ theme }) => ({
  width: '100%',

  '& .MuiInputBase-root': {
    height: '40px',
    borderRadius: '4px',
  },
  '& label': {
    padding: '0px 6px',
  },
  '& .MuiInputBase-input': {
    paddingLeft: '20px',
    color: theme.palette.primary.dark,
    fontFamily: INTER,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '21px',
    letterSpacing: '-0.308px',
  },
  '& legend': {
    marginLeft: '5px',
  },
}));

export const MenuItemBox = styled('li')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '21px',
  letterSpacing: '-0.308px',
}));

export const MenuItemText = styled(Typography)(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '21px',
  letterSpacing: '-0.308px',
}));

export const CustomMenuItem = {
  display: 'flex',
  justifyContent: 'center',
};
