import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { INTER } from 'constants/fonts';
import { theme } from 'theme';
import { COLORS } from 'theme/colors.const';

interface IBoxProps extends BoxProps {
  isactive?: 'true' | 'false';
}

interface ILogoProps extends BoxProps {
  src: string;
}

export const WrapperGeneralStatistics = styled(Box)<IBoxProps>(
  ({ isactive }) => ({
    display: 'flex',
    height: 'fit-content',
    flexDirection: 'column',
    gap: '30px',
    padding: '4%',
    borderRadius: '25px',
    border:
      isactive === 'true' ? `2px solid ${theme.palette.primary.main}` : 'none',
    background: theme.palette.common.white,
    cursor: 'pointer',
    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  })
);

export const WrapperTop = styled(Box)<BoxProps>(() => ({
  height: '52px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const WrapperBottom = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '52px',
  padding: '4px',
  border: `2px solid ${theme.palette.action.disabled}`,
  borderRadius: '31px',
}));

export const WrapperIcons = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '52px',
  padding: '4px',
}));

export const Title = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '24px',
  fontWeight: 500,
  lineHeight: '36px',
  letterSpacing: '-0.528px',
}));

export const Amount = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '46px',
  fontWeight: 600,
  lineHeight: '42px',
  letterSpacing: '-0.528px',
}));

export const SubTitle = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.orangeRed,
  fontFamily: INTER,
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '150%',
  letterSpacing: '-0.450px',
}));

export const LogoBox = styled(Box)<ILogoProps>(({ src }) => ({
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '42px',
  height: '42px',
  borderRadius: '50%',
}));
