import React, { useEffect, useState } from 'react';
import { CustomTextField, MenuItemBox, Wrapper } from './TicketsList.styled';
import { TICKETS_TABLE } from 'constants/tablesHead';
import { ClientModeDataTable, FlatButton } from 'ui';
import { Field, Form, Formik } from 'formik';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Grid,
} from '@mui/material';
import { IClientStatisticBody, IReceiveContactBody } from 'types';
import { ITicket } from 'types/connectWise.type';
import {
  getMSPClients,
  getMSPContacts,
  getTools,
} from 'reduxStore/createProfile/createProfileSelectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { generateTickets } from 'utils/mockDataGenerators';
import { getMainOrganization } from 'reduxStore/organization/organizationSelectors';
import {
  retrieveMSPClients,
  retrieveMSPContacts,
  retrieveTools,
} from 'reduxStore/createProfile/createProfileOperations';

interface ITicketListProps {
  organization: string;
  user: string;
  status: string;
  severity: string;
}

//TODO: IMPLEMENT REAL TICKETS
const TicketsList = () => {
  const dispatch = useAppDispatch();

  const clients = useAppSelector(getMSPClients);
  const teamMembers = useAppSelector(getMSPContacts);
  const tools = useAppSelector(getTools);
  const mainOrg = useAppSelector(getMainOrganization);

  //TODO: Refactor it when Tickets will be implemented
  // const [rowCount] = useState(0); // Total available rows
  // const [page, setPage] = useState(0); // Current page index
  // const [pageSize, setPageSize] = useState(5); // Rows per page
  // const loading = useAppSelector(getOrganizationLoading);

  const [tickets, setTickets] = useState<ITicket[]>([]);

  const severities = ['Low', 'Medium', 'High'];
  const statuses = ['Opened', 'Closed'];

  const reformatedTickets = tickets.map(ticket => {
    return {
      organization: ticket.organization,
      summary: ticket.summary,
      contact: ticket.contact_name,
      contact_phone: ticket.contact_phone,
      contact_email: ticket.contact_email,
      assigned_to: ticket.assigned_to,
      status: ticket.status,
      priority: ticket.priority,
      id: ticket.id,
    };
  });

  useEffect(() => {
    dispatch(retrieveTools(null));
    dispatch(retrieveMSPClients(null));
    dispatch(retrieveMSPContacts(null));
  }, [dispatch]);

  const initialValues: ITicketListProps = {
    organization: '',
    severity: '',
    user: '',
    status: '',
  };

  const handleSelectTicket = () => {};

  const handleSubmit = (value: ITicketListProps) => {
    if (
      tools.find(
        tool =>
          tool.code === 'connect-wise' &&
          (mainOrg?.role.id === 1
            ? (tool.intgrn !== null && tool.intgrn.has_creds === true) ||
              (tool.clients_count !== null && tool.clients_count !== 0)
            : tool.clients_count !== null && tool.clients_count !== 0)
      )
    ) {
      const amount =
        (value.organization !== '' && value.organization !== null) ||
        (value.user !== '' && value.user !== null) ||
        (value.severity !== '' && value.severity !== null) ||
        (value.status !== '' && value.status !== null)
          ? 67
          : 134;
      setTickets(
        generateTickets(
          amount,
          value.organization !== '' ? value.organization : undefined,
          value.user !== '' ? value.user : undefined,
          value.severity !== '' ? value.severity : undefined,
          value.status !== '' ? value.status : undefined,
          clients.map(client => {
            return client.name;
          }),
          teamMembers.map(teamMember => {
            return `${teamMember.first_name} ${teamMember.last_name}`;
          })
        )
      );
    } else setTickets([]);
  };

  return (
    <Wrapper>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <Grid
              container
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Grid item xs={2}>
                <Field name="organization" id="organization">
                  {({ field }: any) => (
                    <Autocomplete
                      {...field}
                      id="organization"
                      options={clients ?? []}
                      value={
                        clients.find(client => client.name === field.value) ||
                        null
                      }
                      getOptionLabel={(client: IClientStatisticBody) =>
                        client.name
                      }
                      renderOption={(props, client: IClientStatisticBody) => (
                        <MenuItemBox
                          {...(props as React.HTMLAttributes<HTMLLIElement>)}
                          key={props.key}
                        >
                          {client.name}
                        </MenuItemBox>
                      )}
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: IClientStatisticBody | null
                      ) =>
                        setFieldValue('organization', newValue?.name ?? null)
                      }
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <CustomTextField
                          {...params}
                          label="Choose Organization"
                          placeholder="Organizations"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{
                            htmlFor: 'organization',
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={2}>
                <Field name="user" id="user">
                  {({ field }: any) => (
                    <Autocomplete
                      {...field}
                      id="user"
                      options={teamMembers ?? []}
                      value={
                        teamMembers.find(
                          teamMember =>
                            `${teamMember?.first_name} ${teamMember.last_name}` ===
                            field.value
                        ) || null
                      }
                      getOptionLabel={(teamMember: IReceiveContactBody) =>
                        teamMember?.last_name && teamMember.first_name
                          ? `${teamMember?.first_name} ${teamMember.last_name}`
                          : ''
                      }
                      renderOption={(
                        props,
                        teamMember: IReceiveContactBody
                      ) => (
                        <MenuItemBox
                          {...(props as React.HTMLAttributes<HTMLLIElement>)}
                          key={props.key}
                        >
                          {`${teamMember?.first_name} ${teamMember.last_name}`}
                        </MenuItemBox>
                      )}
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: IReceiveContactBody | null
                      ) =>
                        setFieldValue(
                          'user',
                          newValue?.last_name && newValue.first_name
                            ? `${newValue?.first_name} ${newValue.last_name}`
                            : null
                        )
                      }
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <CustomTextField
                          {...params}
                          label="Choose User"
                          placeholder="Users"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{
                            htmlFor: 'user',
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={2}>
                <Field name="severity" id="severity">
                  {({ field }: any) => (
                    <Autocomplete
                      {...field}
                      id="severity"
                      options={severities}
                      value={
                        severities.find(severity => severity === field.value) ||
                        null
                      }
                      getOptionLabel={(severity: string) => severity}
                      renderOption={(props, severity: string) => (
                        <MenuItemBox
                          {...(props as React.HTMLAttributes<HTMLLIElement>)}
                          key={props.key}
                        >
                          {severity}
                        </MenuItemBox>
                      )}
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: string | null
                      ) => setFieldValue('severity', newValue)}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <CustomTextField
                          {...params}
                          label="Choose Severity"
                          placeholder="Severities"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{
                            htmlFor: 'severity',
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={2}>
                <Field name="status" id="status">
                  {({ field }: any) => (
                    <Autocomplete
                      {...field}
                      id="status"
                      options={statuses}
                      value={
                        statuses.find(status => status === field.value) || null
                      }
                      getOptionLabel={(status: string) => status}
                      renderOption={(props, status: string) => (
                        <MenuItemBox
                          {...(props as React.HTMLAttributes<HTMLLIElement>)}
                          key={props.key}
                        >
                          {status}
                        </MenuItemBox>
                      )}
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: string | null
                      ) => setFieldValue('status', newValue)}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <CustomTextField
                          {...params}
                          label="Choose Status"
                          placeholder="Statuses"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{
                            htmlFor: 'status',
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={2}>
                <FlatButton
                  width="100%"
                  type="submit"
                  variant="contained"
                  height="40px"
                >
                  Show Tickets
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <ClientModeDataTable
        title="Tickets"
        rows={reformatedTickets}
        columns={TICKETS_TABLE}
        nowRowsText="No Rows. If you already pressed Show Tickets button, try to integrate ConnectWise"
        onClick={handleSelectTicket}
      />
    </Wrapper>
  );
};

export default TicketsList;
