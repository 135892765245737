import { GridColDef } from '@mui/x-data-grid';

export const TEAM_MEMBERS_TABLE: GridColDef[] = [
  { headerName: 'Id', field: 'id' },
  { headerName: 'Icon', field: 'icon' },
  {
    headerName: 'First Name',
    field: 'first_name',
  },
  { headerName: 'Last Name', field: 'last_name' },
  { headerName: 'Phone Number', field: 'phone' },
  { headerName: 'E-mail', field: 'email' },
  { headerName: 'Role', field: 'role' },
];

export const CLIENTS_TABLE: GridColDef[] = [
  { headerName: 'Id', field: 'id' },
  { headerName: 'Icon', field: 'icon' },
  { headerName: 'Client Name', field: 'name' },
  { headerName: 'Client Phone', field: 'phone' },
  { headerName: 'Services', field: 'services_count' },
  { headerName: 'Devices', field: 'devices_count' },
  { headerName: 'Team', field: 'admins_count' },
  { headerName: 'Alerts', field: 'alerts_count' },
];

export const SERVICES_TABLE: GridColDef[] = [
  { headerName: 'Id', field: 'id' },
  { headerName: 'Icon', field: 'icon' },
  { headerName: 'Service Name', field: 'service_name' },
  { headerName: 'Client Count', field: 'client_count' },
  { headerName: 'Devices', field: 'devices' },
  { headerName: 'Alerts', field: 'alerts_count' },
  { headerName: 'Agents', field: 'agents_count' },
  { headerName: 'Status', field: 'status' },
  { headerName: 'Is Connected', field: 'is_connected' },
];

export const CLIENT_INTEGRATED_SERVICES_TABLE: GridColDef[] = [
  { headerName: 'Id', field: 'id' },
  { headerName: 'Icon', field: 'icon' },
  { headerName: 'Service Name', field: 'service_name' },
  { headerName: 'Devices', field: 'devices' },
  { headerName: 'Agents', field: 'agents' },
  { headerName: 'Alerts', field: 'alerts' },
];

export const AUDIT_LOGS_CLIENT_MODE_TABLE: GridColDef[] = [
  { headerName: 'Id', field: 'id' },
  { headerName: 'Organization', field: 'org' },
  { headerName: 'Admin', field: 'admin' },
  { headerName: 'Route', field: 'route' },
  { headerName: 'Status', field: 'status' },
  { headerName: 'Date/Time', field: 'stamp' },
  { headerName: 'IP', field: 'ip' },
  //{ headerName: 'Description', field: 'description' }, --- Might implement later
];

export const AUDIT_LOGS_SERVER_MODE_TABLE: GridColDef[] = [
  { headerName: 'Id', field: 'id' },
  { headerName: 'Organization', field: 'org' },
  { headerName: 'Admin', field: 'admin' },
  { headerName: 'Route', field: 'route' },
  { headerName: 'Status', field: 'status', sortable: false },
  { headerName: 'Date/Time', field: 'stamp' },
  { headerName: 'IP', field: 'ip', sortable: false },
  //{ headerName: 'Description', field: 'description' }, --- Might implement later
];

export const TICKETS_TABLE: GridColDef[] = [
  { headerName: 'Organization', field: 'organization' },
  { headerName: 'Summary', field: 'summary' },
  { headerName: 'Contact', field: 'contact' },
  { headerName: 'Contact Phone', field: 'contact_phone' },
  { headerName: 'Contact E-mail', field: 'contact_email' },
  { headerName: 'Assigned To', field: 'assigned_to' },
  { headerName: 'Status', field: 'status' },
  { headerName: 'Priority', field: 'priority' },
];

export const HUNTRESS_AGENTS_TABLE: GridColDef[] = [
  { headerName: 'Name', field: 'name' },
  { headerName: 'OS Name', field: 'os_name' },
  { headerName: 'EDR', field: 'edr_version' },
  { headerName: 'External IP', field: 'ext_ip' },
  { headerName: 'Internal IP', field: 'int_ip' },
  { headerName: 'Registered', field: 'registered_at' },
  { headerName: 'Last Seen', field: 'last_seen_at' },
  { headerName: 'Id', field: 'id' },
];

export const HUNTRESS_INCIDENTS_TABLE: GridColDef[] = [
  { headerName: 'Host Name', field: 'entity' },
  { headerName: 'Sent At', field: 'sent_at' },
  { headerName: 'Platform', field: 'platform' },
  { headerName: 'Actions', field: 'actions' },
  { headerName: 'Remediations', field: 'remediations' },
  { headerName: 'Severity', field: 'severity' },
  { headerName: 'Status', field: 'status' },
  { headerName: 'Id', field: 'id' },
];

export const HUNTRESS_REPORTS_TABLE: GridColDef[] = [
  { headerName: 'Type', field: 'type' },
  { headerName: 'Date Range', field: 'date_range' },
  { headerName: 'Id', field: 'id' },
];

export const SAAS_ALERTS_MSP_VIEW_CONNECTION_POINTS_TABLE: GridColDef[] = [
  { headerName: 'Organizations', field: 'organizations' },
  { headerName: 'Name', field: 'names' },
  { headerName: 'E-mails', field: 'emails' },
  { headerName: 'Last Time', field: 'last_time' },
  { headerName: 'Events', field: 'events' },
  { headerName: 'IP/Location', field: 'ip_location' },
  { headerName: 'Id', field: 'id' },
];

export const SAAS_ALERTS_CLIENT_VIEW_CONNECTION_POINTS_TABLE: GridColDef[] = [
  { headerName: 'Names', field: 'names' },
  { headerName: 'E-mails', field: 'emails' },
  { headerName: 'Last Time', field: 'last_time' },
  { headerName: 'Events', field: 'events' },
  { headerName: 'IP/Location', field: 'ip_location' },
  { headerName: 'Id', field: 'id' },
];

export const SAAS_ALERTS_EVENTS_MSP_VIEW_CRITICAL_AND_MEDIUM_TABLE: GridColDef[] =
  [
    { headerName: 'Organization', field: 'organization' },
    { headerName: 'Product', field: 'product' },
    { headerName: 'Date/Time', field: 'date_time' },
    { headerName: 'User', field: 'user' },
    { headerName: 'IP/Location', field: 'ip_location' },
    { headerName: 'Description', field: 'description' },
    //{ headerName: 'Tickets', field: 'tickets' },
    { headerName: 'Id', field: 'id' },
  ];

export const SAAS_ALERTS_EVENTS_MSP_VIEW_LOW_TABLE: GridColDef[] = [
  { headerName: 'Organization', field: 'organization' },
  { headerName: 'Product', field: 'product' },
  { headerName: 'Date/Time', field: 'date_time' },
  { headerName: 'User', field: 'user' },
  { headerName: 'IP/Location', field: 'ip_location' },
  { headerName: 'Description', field: 'description' },
  { headerName: 'Id', field: 'id' },
];

export const SAAS_ALERTS_EVENTS_CLIENT_VIEW_CRITICAL_AND_MEDIUM_TABLE: GridColDef[] =
  [
    { headerName: 'Product', field: 'product' },
    { headerName: 'Date/Time', field: 'date_time' },
    { headerName: 'User', field: 'user' },
    { headerName: 'IP/Location', field: 'ip_location' },
    { headerName: 'Description', field: 'description' },
    //{ headerName: 'Tickets', field: 'tickets' },
    { headerName: 'Id', field: 'id' },
  ];

export const SAAS_ALERTS_EVENTS_CLIENT_VIEW_LOW_TABLE: GridColDef[] = [
  { headerName: 'Product', field: 'product' },
  { headerName: 'Date/Time', field: 'date_time' },
  { headerName: 'User', field: 'user' },
  { headerName: 'IP/Location', field: 'ip_location' },
  { headerName: 'Description', field: 'description' },
  { headerName: 'Id', field: 'id' },
];

export const SAAS_ALERTS_MSP_VIEW_ACCOUNT_MANAGEMENT_TABLE: GridColDef[] = [
  { headerName: 'Organization', field: 'organization' },
  { headerName: 'Account', field: 'account' },
  { headerName: 'Last Activity', field: 'last_activity' },
  { headerName: 'Billable', field: 'billable' },
  { headerName: 'Licensed', field: 'licensed' },
  { headerName: 'Product', field: 'product' },
  { headerName: 'Id', field: 'id' },
];

export const SAAS_ALERTS_CLIENT_VIEW_ACCOUNT_MANAGEMENT_TABLE: GridColDef[] = [
  { headerName: 'Account', field: 'account' },
  { headerName: 'Last Activity', field: 'last_activity' },
  { headerName: 'Billable', field: 'billable' },
  { headerName: 'Licensed', field: 'licensed' },
  { headerName: 'Product', field: 'product' },
  { headerName: 'Id', field: 'id' },
];

export const SAAS_ALERTS_MSP_VIEW_ANALYSIS_TABLE: GridColDef[] = [
  { headerName: 'Organization', field: 'org' },
  { headerName: 'Product', field: 'app' },
  { headerName: 'Date/Time', field: 'stamp' },
  { headerName: 'User', field: 'user' },
  { headerName: 'IP/Location', field: 'ip_location', sortable: false },
  { headerName: 'Alert Status', field: 'alert_status', sortable: false },
  { headerName: 'Description', field: 'description', sortable: false },
  //{ headerName: 'Tickets', field: 'tickets', sortable: false },
  { headerName: 'Id', field: 'id' },
];

export const SAAS_ALERTS_CLIENT_VIEW_ANALYSIS_TABLE: GridColDef[] = [
  { headerName: 'Product', field: 'app' },
  { headerName: 'Date/Time', field: 'stamp' },
  { headerName: 'User', field: 'user' },
  { headerName: 'IP/Location', field: 'ip_location', sortable: false },
  { headerName: 'Alert Status', field: 'alert_status', sortable: false },
  { headerName: 'Description', field: 'description', sortable: false },
  //{ headerName: 'Tickets', field: 'tickets', sortable: false },
  { headerName: 'Id', field: 'id' },
];

export const CONNECT_WISE_MSP_VIEW_USERS_TABLE: GridColDef[] = [
  { headerName: 'Id', field: 'id' },
  { headerName: 'Organization', field: 'organization' },
  {
    headerName: 'First Name',
    field: 'first_name',
  },
  { headerName: 'Last Name', field: 'last_name' },
  { headerName: 'Phone Number', field: 'phone' },
  { headerName: 'E-mail', field: 'email' },
  { headerName: 'Security Roles', field: 'security_roles' },
  { headerName: 'Work Roles', field: 'work_roles' },
];

export const CONNECT_WISE_CLIENT_VIEW_USERS_TABLE: GridColDef[] = [
  { headerName: 'Id', field: 'id' },
  {
    headerName: 'First Name',
    field: 'first_name',
  },
  { headerName: 'Last Name', field: 'last_name' },
  { headerName: 'Phone Number', field: 'phone' },
  { headerName: 'E-mail', field: 'email' },
  { headerName: 'Security Roles', field: 'security_roles' },
  { headerName: 'Work Roles', field: 'work_roles' },
];

export const CONNECT_WISE_MSP_VIEW_ALERTS_TABLE: GridColDef[] = [
  { headerName: 'Id', field: 'id' },
  { headerName: 'Organization', field: 'organization' },
  { headerName: 'Name', field: 'name' },
  { headerName: 'Description', field: 'description' },
  { headerName: 'Priority', field: 'priority' },
  { headerName: 'Trigger Conditions', field: 'triggerConditions' },
  { headerName: 'Associated Monitor', field: 'associatedMonitor' },
  { headerName: 'Action', field: 'action' },
  { headerName: 'Notification Recipients', field: 'notificationRecipients' },
  { headerName: 'Escalation Policy', field: 'escalationPolicy' },
  { headerName: 'Suppression Rules', field: 'suppressionRules' },
  { headerName: 'Alert Frequency', field: 'alertFrequency' },
  { headerName: 'Recovery Actions', field: 'recoveryActions' },
];

export const CONNECT_WISE_CLIENT_VIEW_ALERTS_TABLE: GridColDef[] = [
  { headerName: 'Id', field: 'id' },
  { headerName: 'Name', field: 'name' },
  { headerName: 'Description', field: 'description' },
  { headerName: 'Severity', field: 'severity' },
  { headerName: 'Trigger Conditions', field: 'triggerConditions' },
  { headerName: 'Associated Monitor', field: 'associatedMonitor' },
  { headerName: 'Action', field: 'action' },
  { headerName: 'Notification Recipients', field: 'notificationRecipients' },
  { headerName: 'Escalation Policy', field: 'escalationPolicy' },
  { headerName: 'Suppression Rules', field: 'suppressionRules' },
  { headerName: 'Alert Frequency', field: 'alertFrequency' },
  { headerName: 'Recovery Actions', field: 'recoveryActions' },
];

export const CONNECT_WISE_MSP_VIEW_COMPUTERS_TABLE: GridColDef[] = [
  { headerName: 'Id', field: 'id' },
  { headerName: 'Organization', field: 'organization' },
  { headerName: 'Name', field: 'name' },
  { headerName: 'Last Login', field: 'last_log_in' },
  { headerName: 'Type', field: 'type' },
  { headerName: 'Last User', field: 'last_user' },
  { headerName: 'OS', field: 'os' },
];

export const CONNECT_WISE_CLIENT_VIEW_COMPUTERS_TABLE: GridColDef[] = [
  { headerName: 'Id', field: 'id' },
  { headerName: 'Name', field: 'name' },
  { headerName: 'Last Login', field: 'last_log_in' },
  { headerName: 'Type', field: 'type' },
  { headerName: 'Last User', field: 'last_user' },
  { headerName: 'OS', field: 'os' },
];

export const CONNECT_WISE_MSP_VIEW_NETWORK_DEVICES_TABLE: GridColDef[] = [
  { headerName: 'Id', field: 'id' },
  { headerName: 'Organization', field: 'organization' },
  { headerName: 'Name', field: 'name' },
  { headerName: 'Status', field: 'status' },
  { headerName: 'Type', field: 'type' },
  { headerName: 'IP', field: 'ip' },
  { headerName: 'Vendor', field: 'vendor' },
];

export const CONNECT_WISE_CLIENT_VIEW_NETWORK_DEVICES_TABLE: GridColDef[] = [
  { headerName: 'Id', field: 'id' },
  { headerName: 'Name', field: 'name' },
  { headerName: 'Status', field: 'status' },
  { headerName: 'Type', field: 'type' },
  { headerName: 'IP', field: 'ip' },
  { headerName: 'Vendor', field: 'vendor' },
];
