import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { INTER } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

export const WrapperVerticalOrHorizontalBar = styled(Box)<BoxProps>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    padding: '15px 20px',
    borderRadius: '25px',
    background: theme.palette.common.white,
  })
);

export const WrapperTop = styled(Box)<BoxProps>(() => ({
  width: '100%',
  height: '52px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

// NOW WE DO NOT USE THIS
export const WrapperTooltip = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '5px',
  backgroundColor: '#58B3FF',
  border: '0px',
  borderRadius: '14px',
  padding: '3px 10px',
}));

export const Title = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '24px',
  fontWeight: 500,
  lineHeight: '36px',
  letterSpacing: '-0.528px',
}));

// NOW WE DO NOT USE THIS
export const TooltipTitle = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.palette.common.white,
    fontFamily: INTER,
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: '-0.264px',
  })
);
