import { ActionsDropdown, IconButton, RoundButton } from 'ui';
import {
  Error,
  LogoBox,
  OrganizationCounter,
  OrganizationName,
  SelectField,
  SelectorInfoContainer,
  SelectorRenderWrapper,
  ServiceName,
  ServiceOrgsList,
  Wrapper,
  WrapperServiceOrgs,
} from './ServicesListPage.styled';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import {
  deleteIntegration,
  retrieveConnectedToServiceClients,
  retrieveMSPClients,
  retrieveTools,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getMSPClients,
  getTools,
} from 'reduxStore/createProfile/createProfileSelectors';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Box, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  getAdminInfo,
  getMainOrganization,
  getMainOrganizationID,
} from 'reduxStore/organization/organizationSelectors';
import { retrieveHuntressGeneralStatistics } from 'reduxStore/tools/toolsOperations';
import {
  resetToolsAllData,
  setToolsSelectedOrgId,
  setToolsSelectedOrgName,
  setToolsSelectedToolId,
} from 'reduxStore/tools/toolsSlice';
import {
  getToolsError,
  getToolsSelectedOrgId,
  getToolsSelectedToolId,
} from 'reduxStore/tools/toolsSelectors';
import { IActionsProps } from 'types/selectItem.type';
import { getIsAuth } from 'reduxStore/auth/authSelectors';
import { resetAuth } from 'reduxStore/auth/authSlice';
import { resetOrganization } from 'reduxStore/organization/organizationSlice';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  IClientStatisticBody,
  IDeleteIntgrnBody,
  ITool,
  IToolOrg,
} from 'types/api.type';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OrganizationElement } from './components';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import { retrieveAccountInfo } from 'reduxStore/organization/organizationOperations';

interface IServiceAndConnectedOrgs {
  expanded: boolean;
  service: ITool;
  orgs: IToolOrg[];
}

//TODO: PUT HERE HeaderServices to Header or delete HeaderServices at all
const ServicesListPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const mainOrgId = useAppSelector(getMainOrganizationID);
  const mainOrg = useAppSelector(getMainOrganization);
  const admin = useAppSelector(getAdminInfo);
  const tools = useAppSelector(getTools);
  const clients = useAppSelector(getMSPClients);
  const error = useAppSelector(getToolsError);
  const selectedOrgId = useAppSelector(getToolsSelectedOrgId);
  const selectedToolId = useAppSelector(getToolsSelectedToolId);
  const isAuth = useAppSelector(getIsAuth);

  const handleLogout = () => {
    if (isAuth) {
      dispatch(resetAuth());
      dispatch(resetOrganization());
    }
  };

  const [isSelectActive, setIsSelectActive] = useState(false);

  const actions: IActionsProps[] = [
    {
      icon: <SettingsOutlinedIcon fontSize="small" />,
      title: 'Settings',
      onClick: async () => {
        await dispatch(retrieveAccountInfo(null)).unwrap();
        navigate(ROUTES.ACCOUNT_SETTINGS);
      },
    },
    {
      icon: <LogoutIcon fontSize="small" />,
      title: 'Logout',
      onClick: handleLogout,
    },
  ];

  const connectedTools = tools.filter(tool =>
    mainOrg?.role.id === 1
      ? (tool.intgrn !== null && tool.intgrn.has_creds === true) ||
        (tool.clients_count !== null && tool.clients_count !== 0)
      : tool.clients_count !== null && tool.clients_count !== 0
  );

  const [orgs] = useState(
    clients.map(client => {
      return { id: client.id, name: client.name };
    })
  );
  orgs.unshift({ id: mainOrgId, name: mainOrg?.name });

  const [serviceClientRelationships, setServiceClientRelationships] = useState<
    IServiceAndConnectedOrgs[]
  >([]);

  const processRelationships = async (): Promise<
    IServiceAndConnectedOrgs[]
  > => {
    const promises = connectedTools.map(async connectedTool => {
      const toolClients = await dispatch(
        retrieveConnectedToServiceClients(connectedTool.id)
      ).unwrap();

      const toolOrgs = toolClients.clients.map(
        (client: IClientStatisticBody) => {
          return { id: client.id, name: client.name };
        }
      );
      if (
        mainOrg?.role.id === 1 &&
        connectedTool.intgrn !== null &&
        connectedTool.intgrn.has_creds === true
      )
        toolOrgs.unshift({ id: mainOrgId, name: mainOrg?.name });

      return { expanded: false, service: connectedTool, orgs: toolOrgs };
    });

    const results = await Promise.all(promises);
    return results;
  };

  const relationshipInitialization = async () => {
    try {
      const results = await processRelationships();
      setServiceClientRelationships(results);
      setIsSelectActive(true);
    } catch (error) {
      return;
    }
  };

  const onOpen = () => {
    relationshipInitialization();
  };

  useEffect(() => {
    dispatch(retrieveTools(null));
    dispatch(retrieveMSPClients(null));
  }, [dispatch, location.pathname]);

  const handleOrgSelect = async (
    orgId: number,
    orgName: string,
    serviceId: number
  ) => {
    try {
      setIsSelectActive(false);
      if (tools.find(tool => tool.id === serviceId)?.name === 'Huntress') {
        await dispatch(retrieveHuntressGeneralStatistics(orgId)).unwrap();
        dispatch(setToolsSelectedOrgName(orgName));
        dispatch(setToolsSelectedOrgId(orgId));
        dispatch(setToolsSelectedToolId(serviceId));
        navigate(ROUTES.HUNTRESS_DASHBOARD);
      } else if (
        tools.find(tool => tool.id === serviceId)?.name === 'SaaS Alerts'
      ) {
        dispatch(setToolsSelectedOrgName(orgName));
        dispatch(setToolsSelectedOrgId(orgId));
        dispatch(setToolsSelectedToolId(serviceId));
        navigate(ROUTES.SAAS_ALERTS_OVERVIEW);
      } else if (
        tools.find(tool => tool.id === serviceId)?.name === 'Connect Wise'
      ) {
        dispatch(setToolsSelectedOrgName(orgName));
        dispatch(setToolsSelectedOrgId(orgId));
        dispatch(setToolsSelectedToolId(serviceId));
        navigate(ROUTES.CONNECT_WISE_DASHBOARD);
      }
    } catch (error) {
      return;
    }
  };

  const handleToggle = async (toolId: number) => {
    const neededRelationship = serviceClientRelationships.find(
      relationship => relationship.service.id === toolId
    );

    if (neededRelationship) {
      //neededRelationship.expanded === false && setSelectedTool(toolId);

      const reformatedRelationships = serviceClientRelationships.map(
        relationship =>
          relationship.service.id === toolId
            ? { ...relationship, expanded: !relationship.expanded }
            : relationship
      );
      setServiceClientRelationships(reformatedRelationships);
    }
  };

  const handleDisconnectOrganization = async (
    orgId: number,
    orgName: string,
    serviceId: number,
    serviceName: string
  ) => {
    const body: IDeleteIntgrnBody = {
      tool_id: serviceId,
      org_id: orgId,
      is_total: false,
    };
    try {
      await dispatch(deleteIntegration(body)).unwrap();
      setIsSelectActive(false);
      dispatch(resetToolsAllData());
      dispatch(
        setModalProps({
          radius: '18px',
          title: `integration between ${orgName} and ${serviceName} was successfully deleted`,
        })
      );
      dispatch(openModal());
      dispatch(setContent('ActionSuccess'));
      dispatch(retrieveTools(null));
      navigate(ROUTES.SERVICES);
    } catch (error) {
      if (error === 'There are specific client integrations') {
        setIsSelectActive(false);
        dispatch(
          setModalProps({
            radius: '18px',
            title: 'integration',
            apiValue: body,
            additionalText: `integration between ${orgName} and ${serviceName} was successfully deleted`,
          })
        );
        dispatch(openModal());
        dispatch(setContent('DeleteConfirmation'));
        navigate(ROUTES.SERVICES);
      }

      return;
    }
  };

  const handleAddIntegration = () => {
    dispatch(resetToolsAllData());
    navigate(ROUTES.ADD_SERVICE);
  };

  return (
    <Wrapper>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
          {connectedTools.length > 0 && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="10px"
            >
              <SelectField
                open={isSelectActive}
                value={selectedToolId}
                haserror={error ? 'true' : undefined}
                onOpen={onOpen}
                onClose={() => {
                  setIsSelectActive(false);
                }}
              >
                <MenuItem sx={{ display: 'none' }} value={selectedToolId}>
                  <SelectorRenderWrapper>
                    <LogoBox
                      src={
                        tools.find(tool => tool.id === selectedToolId)?.icon ||
                        ''
                      }
                      width="42px"
                      height="42px"
                    />
                    <SelectorInfoContainer>
                      <ServiceName>
                        {tools.find(tool => tool.id === selectedToolId)?.name}
                      </ServiceName>
                      <OrganizationName>
                        {mainOrg?.role.id === 1
                          ? orgs.find(org => org.id === selectedOrgId)?.name
                          : clients.find(client => client.id === selectedOrgId)
                              ?.name}
                      </OrganizationName>
                    </SelectorInfoContainer>
                  </SelectorRenderWrapper>
                </MenuItem>
                {serviceClientRelationships.map((relationship, index) => (
                  <WrapperServiceOrgs key={index}>
                    <ServiceOrgsList
                      onClick={() => {
                        handleToggle(relationship.service.id);
                      }}
                    >
                      <Box>
                        <LogoBox
                          src={relationship.service.icon}
                          width="42px"
                          height="42px"
                        />
                      </Box>
                      <Box
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <OrganizationName>
                          {relationship.service.name}
                        </OrganizationName>
                        <OrganizationCounter>
                          {relationship.orgs.length}
                        </OrganizationCounter>
                      </Box>
                      {relationship.expanded ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </ServiceOrgsList>
                    {relationship.expanded && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap="5px"
                        marginTop="9px"
                        padding="8px"
                      >
                        {relationship.orgs.map((org, index) => (
                          <span
                            role="button"
                            key={index}
                            onClick={() =>
                              handleOrgSelect(
                                org.id,
                                org.name,
                                relationship.service.id
                              )
                            }
                            tabIndex={0}
                          >
                            <OrganizationElement
                              orgName={org.name}
                              onDisconnect={() =>
                                handleDisconnectOrganization(
                                  org.id,
                                  org.name,
                                  relationship.service.id,
                                  relationship.service.name
                                )
                              }
                            />
                          </span>
                        ))}
                      </Box>
                    )}
                  </WrapperServiceOrgs>
                ))}
              </SelectField>
            </Box>
          )}
          <RoundButton
            width="52px"
            height="52px"
            type="submit"
            variant="contained"
            setExpansion={connectedTools.length > 0 ? undefined : 'true'}
            needsExpansion={true}
            expansionText="Create Integration"
            onClick={handleAddIntegration}
          >
            <AddIcon fontSize="medium" />
          </RoundButton>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" gap="25px">
          <IconButton>
            <NotificationsNoneOutlinedIcon fontSize="medium" />
          </IconButton>
          <ActionsDropdown actions={actions}>
            {admin?.icon ? (
              <LogoBox src={admin.icon} />
            ) : (
              <AccountCircleOutlinedIcon fontSize="medium" />
            )}
          </ActionsDropdown>
        </Box>
      </Box>
      <Error>{error}</Error>
      <Outlet />
    </Wrapper>
  );
};

export default ServicesListPage;
