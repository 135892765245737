import { styled, Box, BoxProps, Typography } from '@mui/material';
import { POPPINS } from 'constants/fonts';

export const WrapperActionSuccess = styled(Box)<BoxProps>(({ theme }) => ({
  height: '388px',
  width: '629px',
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.common.white,
  justifyContent: 'center',
  alignItems: 'center',
  padding: '119px 0px 26px',
  gap: '97px',
}));

export const MainText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: POPPINS,
  fontSize: '32px',
  fontWeight: 500,
  lineHeight: '150%',
  letterSpacing: '-0.704px',
  textAlign: 'center',
}));
