import { styled } from '@mui/material';
import TableContainer, {
  TableContainerProps,
} from '@mui/material/TableContainer';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Box, { BoxProps } from '@mui/material/Box';
import { INTER, ISTOK_WEB } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';
import { DataGrid } from '@mui/x-data-grid';

interface ITableContainerProps extends TableContainerProps {
  height?: string;
  width?: string;
  iswarning?: 'true';
}

export const StyledTableContainer = styled(
  TableContainer
)<ITableContainerProps>(({ theme, height, width, iswarning }) => ({
  position: 'relative',
  border: iswarning ? `2px solid ${theme.palette.error.light}` : 'none',
  borderRadius: '25px',
  background: theme.palette.common.white,
  width: width ? width : '100%',
  height: height ? height : '100%',
  padding: '19px 0 0',
  display: 'flex',
  flexDirection: 'column',
  overflowY: height ? 'hidden' : 'visible',
  overflowX: width ? 'auto' : 'visible',
  scrollBehavior: 'smooth',
  '& .MuiTableHead-root': {
    borderBottom: 'none',
    '& .MuiTableCell-head': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.dark,
    },
  },
  '& .MuiTableRow-root': {
    paddingLeft: '40px',
    paddingRight: '0px',
    '&:nth-of-type(even)': {
      background: theme.palette.common.white,
    },
    '&:nth-of-type(odd)': {
      background: theme.palette.common.white,
    },
    '&:hover': {
      //backgroundColor: type === SUPER_ADMIN && '#005A8D',
    },
  },
  '& .MuiTableCell-root': {
    fontSize: '16px',
    fontFamily: ISTOK_WEB,
    lineHeight: '24px',
    letterSpacing: '-0.352px',
    color: theme.palette.secondary.light,
  },
  '& .MuiTableCell-paddingCheckbox:first-of-type': {
    width: '24px',
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.secondary.contrastText,
    borderRadius: '17px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(0, 0, 0, 0.1)',
  },
}));

export const DataTable = styled(DataGrid)(({ theme }) => ({
  border: 0,
  '& .MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
    padding: '15px 0px',
    fontSize: '16px',
    fontFamily: ISTOK_WEB,
    lineHeight: '24px',
    letterSpacing: '-0.352px',
    color: theme.palette.secondary.light,
  },
  '& .MuiDataGrid-columnHeaders div[role="row"]': {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.common.white,
    fontFamily: INTER,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '21px',
    letterSpacing: '-0.308px',
    textTransform: 'uppercase',
  },
  '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input': {
    display: 'inline-block !important', //Forces visibility of 'Rows per page' part
  },

  '& .MuiInputBase-input.MuiSelect-select': {
    textAlign: 'start',
    textAlignLast: 'start',
    WebkitTextAlignLast: 'start',
  },
}));

export const StyledWrapperTitle = styled(Box)<BoxProps>(() => ({
  height: '27px',
  position: 'relative',
  boxSizing: 'border-box',
  marginBottom: '20px',
  padding: '0px 40px 0px 50px',
}));

export const Title = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.secondary,
  fontFamily: INTER,
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '150%',
  letterSpacing: '-0.396px',
}));

export const StyledActionBlock = styled(Box)<BoxProps>(() => ({
  position: 'absolute',
  top: 0,
  right: '40px',
  width: 'fit-content',
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
}));
