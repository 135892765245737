import { Box, BoxProps, styled, TextField } from '@mui/material';
import { INTER } from 'constants/fonts';

interface ILogoProps extends BoxProps {
  src: string;
}

export const WrapperHeader = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '25px',
  width: '100%',
}));

export const WrapperIcons = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '25px',
}));

export const WrapperOrgSelectionElements = styled(Box)<BoxProps>(() => ({
  width: '90%',
  padding: '0px 15px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '25px',
}));

export const LogoBox = styled(Box)<ILogoProps>(({ src }) => ({
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  height: '100%',
  borderRadius: '50%',
}));

export const CustomTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,

  '& .MuiInputBase-root': {
    height: '40px',
    borderRadius: '4px',
  },
  '& label': {
    padding: '0px 6px',
  },
  '& .MuiInputBase-input': {
    paddingLeft: '20px',
    color: theme.palette.primary.dark,
    fontFamily: INTER,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '21px',
    letterSpacing: '-0.308px',
  },
  '& legend': {
    marginLeft: '5px',
  },
}));
