import {
  Box,
  BoxProps,
  FormControl,
  FormControlProps,
  Select,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { INTER, ISTOK_WEB, POPPINS } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';
import ListSubheader, { ListSubheaderProps } from '@mui/material/ListSubheader';
import { SelectFieldProps } from 'material-ui';

interface IFormControlProps extends FormControlProps {
  isactive?: 'true' | 'false';
}

interface ISelectFieldProps extends SelectFieldProps {
  haserror?: 'true';
}

interface ILogoProps extends BoxProps {
  src: string;
  width?: string;
  height?: string;
}

export const Wrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '38px',
}));

export const MenuItemText = styled(Typography)(() => ({
  color: COLORS.black,
  fontFamily: POPPINS,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '150%',
  letterSpacing: '-2.2%',
}));

export const ServiceName = styled(Typography)(() => ({
  color: COLORS.gray,
  fontFamily: POPPINS,
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '150%',
  letterSpacing: '-2.2%',
}));

export const OrganizationName = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontFamily: POPPINS,
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '150%',
  letterSpacing: '-2.2%',
}));

export const OrganizationCounter = styled(Typography)(({ theme }) => ({
  color: COLORS.superBlue,
  fontFamily: POPPINS,
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '150%',
  letterSpacing: '-2.2%',
}));

export const MenuItemBox = {
  display: 'flex',
  justifyContent: 'center',
};

export const SelectContainer = styled(FormControl)<IFormControlProps>(
  ({ theme, isactive }) => ({
    width: '291px',
    borderRadius: '43px',
    background: isactive === 'true' ? theme.palette.blue.light : 'transparent',
    transition: 'background 0.4s ease',
    '& .MuiInputLabel-root.MuiInputLabel-shrink': {
      transform: 'translate(16px, -9px) scale(0.9)',
    },
  })
);

export const SelectField = styled(Select)<ISelectFieldProps>(
  ({ theme, haserror }) => ({
    width: '300px',
    height: '54px',
    borderRadius: '30px',
    border: haserror
      ? `1px solid ${COLORS.error}`
      : `1px solid ${COLORS.oceanBlue}`,
    boxSizing: 'border-box',
    overflow: 'hidden',
    '& .MuiInputBase-input': {
      display: 'flex',
      flexDirection: 'row',
      color: theme.palette.text.secondary,
      fontSize: '14px',
      fontFamily: INTER,
      fontWeight: 400,
      lineHeight: '21px',
      letterSpacing: '-0.308px',
      background: theme.palette.background.paper,
      '&.MuiOutlinedInput-input': {
        border: haserror
          ? `1px solid ${COLORS.error}`
          : `1px solid ${COLORS.oceanBlue}`,
      },
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: haserror
          ? `1px solid ${COLORS.error}`
          : `1px solid ${COLORS.oceanBlue}`,
      },
    },
    '&:hover': {
      border: haserror
        ? `1px solid ${COLORS.error}`
        : `1px solid ${COLORS.oceanBlue}`,
      '& .MuiOutlinedInput-notchedOutline': {
        border: haserror
          ? `1px solid ${COLORS.error}`
          : `1px solid ${COLORS.oceanBlue}`,
      },
    },
  })
);

export const Error = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.error.main,
  fontFamily: ISTOK_WEB,
  fontSize: '20px',
  lineHeight: '150%',
  fontWeight: '500',
  marginTop: '10px',
  letterSpacing: '-0.352px',
}));

export const LogoBox = styled(Box)<ILogoProps>(({ src, width, height }) => ({
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: width ? width : '100%',
  height: height ? height : '100%',
  borderRadius: '50%',
}));

export const SelectorRenderWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '5px',
  width: '100%',
  height: 'fit-content',
}));

export const SelectorInfoContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0px',
  height: 'fit-content',
}));

export const ServiceOrgsList = styled(ListSubheader)<ListSubheaderProps>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '9px',
    alignItems: 'center',
    height: '40px',
    width: '100%',
    borderRadius: '21px',
    paddingLeft: '11px',
    background: COLORS.originalBlue,
    color: theme.palette.secondary.main,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    letterSpacing: '-0.308px',
    cursor: 'pointer',
  })
);

export const WrapperServiceOrgs = styled(Box)<BoxProps>(() => ({
  background: COLORS.originalBlue,
  borderRadius: '21px',
  margin: '6px',
  padding: '8px 0px',
  overflow: 'hidden',
}));
