import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import {
  retrieveHuntressGeneralStatistics,
  retrieveHuntressIncidents,
} from 'reduxStore/tools/toolsOperations';
import {
  getHuntressGeneralStatistics,
  getHuntressIncidentRefs,
  getHuntressIncidents,
  getToolsSelectedOrgId,
} from 'reduxStore/tools/toolsSelectors';
import { WrapperITDR } from './HuntressITDRPage.styled';
import { theme } from 'theme';
import { COLORS } from 'theme/colors.const';
import { ClientModeDataTable, DataBox } from 'ui';
import { IHuntressIncidentsBody } from 'types/huntress.type';
import { HUNTRESS_INCIDENTS_TABLE } from 'constants/tablesHead';

const HuntressITDRPage = () => {
  const dispatch = useAppDispatch();

  const generalStatistics = useAppSelector(getHuntressGeneralStatistics);
  const selectedOrgId = useAppSelector(getToolsSelectedOrgId);
  const incidents = useAppSelector(getHuntressIncidents);
  const incidentRefs = useAppSelector(getHuntressIncidentRefs);

  useEffect(() => {
    const body: IHuntressIncidentsBody = {
      org_id: selectedOrgId,
      indicator_id: null,
      status_id:
        incidentRefs?.statuses.find(status => status.code === 'sent')?.id ??
        null,
      platform_id: null,
      severity_id: null,
    };
    dispatch(retrieveHuntressIncidents(body));
    dispatch(retrieveHuntressGeneralStatistics(selectedOrgId));
  }, [dispatch, incidentRefs?.statuses, selectedOrgId]);

  const activeIncidentsData = [
    {
      title: 'Critical',
      amount: generalStatistics?.incidents.sent.crit_count || 0,
      color: theme.palette.error.main,
    },
    {
      title: 'High',
      amount: generalStatistics?.incidents.sent.high_count || 0,
      color: COLORS.yellowDark,
    },
    {
      title: 'Low',
      amount: generalStatistics?.incidents.sent.low_count || 0,
      color: COLORS.greenDark,
    },
  ];

  const reformatedIncidents = incidents.map(incident => {
    return {
      entity: incident.entity,
      sent_at: incident.sent_at,
      platform: incident.platform.name,
      actions: incident.actions,
      remediations: incident.remediations,
      severity: incident.severity.name,
      status: incident.status.name,
      id: incidents.indexOf(incident),
    };
  });

  return (
    <WrapperITDR>
      <DataBox
        title="Active Incidents"
        data={activeIncidentsData}
        width="100%"
        height="30%"
        needsGraph="true"
      />
      <ClientModeDataTable
        title="Active Incidents"
        columns={HUNTRESS_INCIDENTS_TABLE}
        rows={reformatedIncidents}
        addCreateTicket="true"
      />
    </WrapperITDR>
  );
};

export default HuntressITDRPage;
