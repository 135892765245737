import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  getDeleteDataType,
  getDeleteList,
} from 'reduxStore/organization/organizationSelectors';
import {
  HeaderPart,
  Title,
  WrapperButtons,
  WrapperElementsToDelete,
  WrapperMassDeletionConfirm,
  Error,
} from './MassDeletionConfirm.styled';
import InfoIcon from '@mui/icons-material/Info';
import { ActionButton, PositiveNegativeNeutralButton } from 'ui/buttons';
import {
  removeListOfClients,
  removeListOfMSPContacts,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  closeModal,
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import {
  removeElementFromDeleteList,
  resetDeleteData,
} from 'reduxStore/organization/organizationSlice';
import {
  retrieveMSPClients,
  retrieveMSPContacts,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getMSPClients,
  getCreateProfileError,
  getMSPContacts,
} from 'reduxStore/createProfile/createProfileSelectors';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { COLORS } from 'theme/colors.const';
import { theme } from 'theme';
import { IRemoveListOfMSPContactsBody } from 'types';

interface IMassDeletionConfirm {
  title?: string;
}

const MassDeletionConfirm = ({ title }: IMassDeletionConfirm) => {
  const dispatch = useAppDispatch();

  const elementsToDelete = useAppSelector(getDeleteList);
  const deleteDataType = useAppSelector(getDeleteDataType);
  const error = useAppSelector(getCreateProfileError);
  const clients = useAppSelector(getMSPClients);
  const contacts = useAppSelector(getMSPContacts);

  useEffect(() => {
    if (deleteDataType === 'Clients') dispatch(retrieveMSPClients(null));
    else if (deleteDataType === 'Team Members')
      dispatch(retrieveMSPContacts(null));
  }, [deleteDataType, dispatch]);

  const handleRemove = (id: number) => {
    dispatch(removeElementFromDeleteList(id));
  };

  const handleCancel = () => {
    dispatch(resetDeleteData());
    dispatch(closeModal());
  };

  const handleDelete = async () => {
    try {
      if (deleteDataType === 'Clients') {
        await dispatch(removeListOfClients(elementsToDelete)).unwrap();
        dispatch(retrieveMSPClients(null));
      } else if (deleteDataType === 'Team Members') {
        const body: IRemoveListOfMSPContactsBody = {
          ids: elementsToDelete,
          is_total: true,
        };
        await dispatch(removeListOfMSPContacts(body)).unwrap();
        dispatch(retrieveMSPContacts(null));
      }
      dispatch(resetDeleteData());
      dispatch(closeModal());
      dispatch(
        setModalProps({
          radius: '18px',
          title: `${deleteDataType} \n were successfully deleted.`,
        })
      );
      dispatch(openModal());
      dispatch(setContent('ActionSuccess'));
    } catch (error) {
      return;
    }
  };

  return (
    <WrapperMassDeletionConfirm>
      {error && <Error>{error}</Error>}
      <HeaderPart>
        <InfoIcon
          sx={{
            fontSize: '50px',
            color: '#868686',
          }}
        />
        <Title>
          {title} {elementsToDelete.length}
        </Title>
      </HeaderPart>
      <WrapperElementsToDelete>
        {elementsToDelete.map(element =>
          deleteDataType === 'Clients' ? (
            <ActionButton
              key={`client - ${element}`}
              width="100%"
              onClick={() => handleRemove(element)}
              endIcon={<HighlightOffIcon fontSize="large" />}
              styles={{
                justifyContent: 'space-between',
                '&:hover': {
                  background: COLORS.lightPink,
                  border: '1px solid',
                  borderColor: COLORS.lightPink,
                  color: theme.palette.error.main,
                },
              }}
            >
              {clients.find(client => client.id === element)?.name}
            </ActionButton>
          ) : deleteDataType === 'Team Members' ? (
            <ActionButton
              key={`tm-${element}`}
              width="100%"
              onClick={() => handleRemove(element)}
              endIcon={<HighlightOffIcon fontSize="large" />}
              styles={{
                fontSize: '15px',
                justifyContent: 'space-between',
                '&:hover': {
                  background: COLORS.lightPink,
                  border: '1px solid',
                  borderColor: COLORS.lightPink,
                  color: theme.palette.error.main,
                },
              }}
            >
              {contacts?.find(contact => contact.id === element)?.email}
            </ActionButton>
          ) : (
            <></>
          )
        )}
      </WrapperElementsToDelete>
      <WrapperButtons>
        <PositiveNegativeNeutralButton
          onClick={handleCancel}
          width="157px"
          height="44px"
          borderradius="23px"
        >
          Cancel
        </PositiveNegativeNeutralButton>
        <PositiveNegativeNeutralButton
          onClick={handleDelete}
          width="157px"
          height="44px"
          state="Negative"
          borderradius="23px"
        >
          Delete
        </PositiveNegativeNeutralButton>
      </WrapperButtons>
    </WrapperMassDeletionConfirm>
  );
};

export default MassDeletionConfirm;
