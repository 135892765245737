export const DATE_RANGE = [
  { id: 1, name: 'Today', dayDifference: 0 },
  { id: 2, name: 'Week', dayDifference: 6 },
  { id: 3, name: 'Last 30 Days', dayDifference: 29 },
  { id: 4, name: 'Last 182 Days', dayDifference: 181 },
  { id: 5, name: 'Last 365 Days', dayDifference: 364 },
];

export const EXTENDED_DATE_RANGE = [
  { id: 1, name: 'No Time Limit', dayDifference: 999 },
  { id: 2, name: '> 30 Days', dayDifference: 29 },
  { id: 3, name: '> 90 Days', dayDifference: 89 },
  { id: 4, name: '> 182 Days', dayDifference: 181 },
  { id: 5, name: '> 365 Days', dayDifference: 364 },
  { id: 6, name: 'Never', dayDifference: 0 },
];

export const PRIORITIES = ['Low', 'Medium', 'High', 'Critical'];

export const STATUSES = ['Opened', 'Closed'];

export const SECURITY_ROLES = [
  'Admin',
  'Finance',
  'Service Desk',
  'Sales',
  'Project Management',
];

export const WORK_ROLES = ['Project Manager', 'Developer', 'Technical Support'];

export const CONNECT_WISE_ALERTS_ACTIONS = [
  'Send email notification',
  'Create service ticket',
  'Restart service',
  'Notify IT Manager',
];

export const OSES = [
  'Windows PC',
  'Mac',
  'Windows Server',
  'Linux Server',
  'VM Host',
];

export const CONNECT_WISE_COMPUTER_TYPES = [
  'Desktop',
  'Server',
  'Desktop (Virtual)',
  'Server (Virtual)',
];

export const CONNECT_WISE_NETWORK_DEVICES_TYPES = [
  'Router',
  'Switch',
  'Firewall',
  'Generic',
  'Other',
];

export const VENDORS = ['HP', 'Microsoft', 'Dell'];

export const AVAILABILITY_STATUSES = ['Online', 'Offline'];
