import {
  AUDIT_LOGS_CLIENT_MODE_TABLE,
  CLIENTS_TABLE,
  SERVICES_TABLE,
  TEAM_MEMBERS_TABLE,
} from 'constants/tablesHead';
import {
  ClientModeDataTable,
  GeneralStatistics,
  VerticalOrHorizontalBar,
} from 'ui';
import {
  WrapperMSPDashboard,
  WrapperStatistics,
} from './MSPDashboardView.styled';
import { ROUTES } from 'routes/routes.const';
import {
  retrieveAuditLogs,
  retrieveClientOrganization,
} from 'reduxStore/organization/organizationOperations';
import {
  resetSelectedClient,
  resetTeamMemberToUpdate,
  setTeamMemberToUpdate,
} from 'reduxStore/organization/organizationSlice';
import {
  IAuditLogsBody,
  IClientStatisticBody,
  IReceiveContactBody,
  ITool,
  IUpdateMSPContactBody,
} from 'types';
import { resetAssignmentsData } from 'reduxStore/assignments/assignmentsSlice';
import {
  retrieveMSPClients,
  retrieveMSPContacts,
  retrieveMSPRoles,
  retrieveTools,
} from 'reduxStore/createProfile/createProfileOperations';
import { useEffect, useState } from 'react';
import { resetToolsAllData } from 'reduxStore/tools/toolsSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  getAdminInfo,
  getAuditLogs,
  getMainOrganization,
} from 'reduxStore/organization/organizationSelectors';
import {
  getMSPClients,
  getMSPContacts,
  getMSPRoles,
  getTools,
} from 'reduxStore/createProfile/createProfileSelectors';
import { useLocation, useNavigate } from 'react-router';
import parsePhoneNumber from 'libphonenumber-js';

interface IMenuOptions {
  onClickAdd: () => void;
  onClickViewAll: () => void;
  title: 'Team Members' | 'Clients' | 'Connected Services';
  data: ITool[] | IClientStatisticBody[] | IReceiveContactBody[] | null;
}

const MSPDashboardView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const tools = useAppSelector(getTools);
  const clients = useAppSelector(getMSPClients);
  const teamMembers = useAppSelector(getMSPContacts);
  const mspRoles = useAppSelector(getMSPRoles);
  const mainOrg = useAppSelector(getMainOrganization);
  const admin = useAppSelector(getAdminInfo);
  const auditLogs = useAppSelector(getAuditLogs);

  const [selectedElement, setSelectedElement] = useState<
    'Team Members' | 'Clients' | 'Connected Services'
  >('Clients');

  const connectedTools = tools.filter(tool =>
    mainOrg?.role.id === 1
      ? (tool.intgrn !== null && tool.intgrn.has_creds === true) ||
        (tool.clients_count !== null && tool.clients_count !== 0)
      : tool.clients_count !== null && tool.clients_count !== 0
  );

  const body: IAuditLogsBody = {
    page: { size: 100, num: 0 },
    filter: {},
    sort_key_id: 0,
  };

  const reformatedAuditLogs = auditLogs.map(log => {
    return {
      admin: `${log.admin.first_name} ${log.admin.last_name} - ${log.admin.email}`,
      route: log.route.name,
      org: log.org?.name,
      status: `${log.http_status.code} - ${log.http_status.name}`,
      stamp: `${log.stamp.split(' ')[0]} - ${log.stamp.split(' ')[1]}`,
      ip: log.ip,
      id: log.id,
    };
  });

  const reformatedClients = Array.isArray(clients)
    ? clients.map(client => {
        return {
          name: client.name || '-',
          phone: client.phone
            ? parsePhoneNumber(client.phone)?.formatInternational()
            : '-',
          devices_count: client.devices_count,
          admins_count: client.admins_count,
          alerts_count: client.alerts_count,
          services_count: client.tools_count,
          icon: client.icon || '',
          id: client.id,
        };
      })
    : [];

  const reformatedContacts = Array.isArray(teamMembers)
    ? teamMembers?.map(teamMember => {
        return {
          first_name: teamMember.first_name || '-',
          last_name: teamMember.last_name || '-',
          phone: teamMember.phone
            ? parsePhoneNumber(teamMember.phone)?.formatInternational()
            : '-',
          email: teamMember.email,
          role: teamMember.role?.name || '-',
          icon: teamMember.icon || '',
          id: teamMember.id,
        };
      })
    : [];

  const reformatedServices = Array.isArray(connectedTools)
    ? connectedTools.map(connectedTool => {
        return {
          service_name: connectedTool.name,
          client_count: connectedTool.clients_count,
          devices:
            connectedTool.code !== 'connect-wise'
              ? connectedTool.devices_count
              : 134,
          alerts_count:
            connectedTool.code !== 'connect-wise'
              ? connectedTool.alerts_count
              : 134,
          agents_count:
            connectedTool.code !== 'connect-wise'
              ? connectedTool.agents_count
              : 134,
          status: connectedTool.status?.name || '-',
          is_connected: 'Connected',
          icon: connectedTool.icon || '',
          id: connectedTool.id,
        };
      })
    : [];

  const data = [
    { id: 1, completedAt: '2024-03-01', amount: 22 },
    { id: 2, completedAt: '2024-03-02', amount: 2 },
    { id: 3, completedAt: '2024-03-03', amount: 15 },
    { id: 4, completedAt: '2024-03-04', amount: 30 },
    { id: 5, completedAt: '2024-03-05', amount: 25 },
    { id: 6, completedAt: '2024-03-06', amount: 58 },
    { id: 7, completedAt: '2024-04-11', amount: 44 },
    { id: 8, completedAt: '2024-04-14', amount: 20 },
    { id: 9, completedAt: '2024-05-02', amount: 87 },
    { id: 10, completedAt: '2024-06-20', amount: 10 },
  ];

  const options: IMenuOptions[] = [
    {
      onClickAdd: () => navigate(ROUTES.ADD_CLIENT),
      onClickViewAll: () => navigate(ROUTES.CLIENTS),
      title: 'Clients',
      data: clients,
    },
    {
      onClickAdd: () => navigate(ROUTES.ADD_OR_UPDATE_TEAM_MEMBER),
      onClickViewAll: () => navigate(ROUTES.TEAM_MEMBERS),
      title: 'Team Members',
      data: teamMembers,
    },
    {
      onClickAdd: () => {
        dispatch(resetToolsAllData());
        navigate(ROUTES.ADD_SERVICE);
      },
      onClickViewAll: () => {
        dispatch(resetToolsAllData());
        navigate(ROUTES.SERVICES);
      },
      title: 'Connected Services',
      data: connectedTools,
    },
  ];

  useEffect(() => {
    const body: IAuditLogsBody = {
      page: { size: 100, num: 0 },
      filter: {},
      sort_key_id: 0,
    };
    dispatch(retrieveAuditLogs(body));
    dispatch(retrieveMSPClients(null));
    dispatch(retrieveTools(null));
    dispatch(retrieveMSPContacts(null));
    dispatch(retrieveMSPRoles(null));

    if (location.pathname === ROUTES.DASHBOARD) dispatch(resetSelectedClient());
  }, [dispatch, location.pathname]);

  const handleAdd = (
    option: 'Team Members' | 'Clients' | 'Connected Services'
  ) => {
    switch (option) {
      case 'Team Members':
        dispatch(resetTeamMemberToUpdate());
        navigate(ROUTES.ADD_OR_UPDATE_TEAM_MEMBER);
        break;
      case 'Clients':
        dispatch(resetSelectedClient());
        dispatch(resetAssignmentsData());
        navigate(ROUTES.ADD_CLIENT);
        break;
      case 'Connected Services':
        navigate(ROUTES.SERVICES);
        break;
      default:
        return;
    }
  };

  //TODO: If we will not use single deletions for Clients and Team Members I need to refactor it
  const handleDelete = (
    option: 'Team Members' | 'Clients' | 'Connected Services'
  ) => {
    switch (option) {
      case 'Team Members':
        navigate(ROUTES.DELETE_TEAM_MEMBER);
        break;
      case 'Clients':
        navigate(ROUTES.DELETE_CLIENT);
        break;
      case 'Connected Services':
        navigate(ROUTES.DELETE_INTEGRATION);
        break;
      default:
        return;
    }
  };

  const handleUpdateTeamMember = (row: any) => {
    if (row.email === admin?.email) {
      navigate(ROUTES.ACCOUNT_SETTINGS);
      return;
    }
    const teamMemberToUpdate: IUpdateMSPContactBody = {
      first_name: row.first_name,
      last_name: row.last_name,
      email: row.email,
      phone: row.phone,
      id: row.id,
      role_id: mspRoles.find(role => role.name === row.role)?.id ?? 0,
      icon: row.icon,
    };
    dispatch(setTeamMemberToUpdate(teamMemberToUpdate));
    navigate(ROUTES.ADD_OR_UPDATE_TEAM_MEMBER);
  };

  const handleSelectClient = async (row: any) => {
    try {
      await dispatch(retrieveClientOrganization(row.id)).unwrap();
      navigate(ROUTES.CLIENT_DASHBOARD);
    } catch (error) {
      return;
    }
  };

  const handleUpdateClient = async (row: any) => {
    try {
      await dispatch(retrieveClientOrganization(row.id)).unwrap();
      navigate(ROUTES.UPDATE_CLIENT);
    } catch (error) {
      return;
    }
  };

  const onReload = async () => {
    await dispatch(retrieveAuditLogs(body)).unwrap();
  };

  return (
    <WrapperMSPDashboard>
      <WrapperStatistics>
        {options.map((option, index) => (
          <span
            role="button"
            key={index}
            onClick={() => setSelectedElement(option.title)}
            tabIndex={0}
          >
            <GeneralStatistics
              onClickAdd={
                mainOrg?.role.id !== 1 && option.title !== 'Connected Services'
                  ? undefined
                  : option.onClickAdd
              }
              onClickMainAction={option.onClickViewAll}
              width="25vw"
              title={option.title}
              data={option.data}
              isactive={selectedElement === option.title ? 'true' : 'false'}
            />
          </span>
        ))}
      </WrapperStatistics>
      <WrapperStatistics>
        <ClientModeDataTable
          rows={reformatedAuditLogs}
          columns={AUDIT_LOGS_CLIENT_MODE_TABLE}
          title="Last 100 Audit Logs"
          width="66%"
          height="435px"
          handleReload={onReload}
        />
        <VerticalOrHorizontalBar
          title="Tickets"
          data={data}
          width="25vw"
          height="435px"
          onClickAdd={() =>
            window.open('https://home.connectwise.com/', '_blank')
          }
          layout="horizontal"
          barDataKey="amount"
          xAxisDataKey="completedAt"
          removeXAxisLines={true}
          removeYAxisLines={true}
        />
      </WrapperStatistics>
      {selectedElement === 'Clients' && (
        <ClientModeDataTable
          rows={reformatedClients}
          columns={CLIENTS_TABLE}
          title={selectedElement}
          showPictures={true}
          addAction={mainOrg?.role.id === 1 ? 'true' : undefined}
          onClick={handleSelectClient}
          handleAdd={() =>
            mainOrg?.role.id === 1 ? handleAdd(selectedElement) : undefined
          }
          onEdit={handleUpdateClient}
        />
      )}

      {selectedElement === 'Team Members' && (
        <ClientModeDataTable
          rows={reformatedContacts}
          columns={TEAM_MEMBERS_TABLE}
          title={selectedElement}
          addAction={mainOrg?.role.id === 1 ? 'true' : undefined}
          showPictures={true}
          onClick={mainOrg?.role.id === 1 ? handleUpdateTeamMember : undefined}
          handleAdd={() =>
            mainOrg?.role.id === 1 ? handleAdd(selectedElement) : undefined
          }
        />
      )}

      {selectedElement === 'Connected Services' && (
        <ClientModeDataTable
          rows={reformatedServices}
          columns={SERVICES_TABLE}
          title={selectedElement}
          addAction="true"
          showPictures={true}
          handleAdd={() => handleAdd(selectedElement)}
          handleDelete={() => handleDelete(selectedElement)}
        />
      )}
    </WrapperMSPDashboard>
  );
};

export default MSPDashboardView;
