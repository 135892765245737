import { styled } from '@mui/material/styles';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import { COLORS } from 'theme/colors.const';
//import { INTER, ISTOK_WEB } from 'constants/fonts';

interface ITextFieldProps extends StandardTextFieldProps {
  height?: string;
  InputLabelProps?: any;
  background?: 'gray' | 'white';
  placeholder?: string;
}

export const StyledTextField = styled(
  ({ InputLabelProps, ...otherProps }: ITextFieldProps) => (
    <TextField
      InputLabelProps={{ ...InputLabelProps, shrink: true }}
      {...otherProps}
    />
  )
)(({ theme, height, background }) => ({
  width: '100%',
  background:
    background === 'gray'
      ? COLORS.grayBackgroundPrimary
      : theme.palette.common.white,

  '& .MuiInputBase-root': {
    height: height ? height : '59px',
    borderRadius: '12px',
  },

  //SOME OF THESE STYLING ELEMENTS MIGHT BE USED LATER
  // '& label': {
  //   //color: theme.palette.text.disabled,
  //   //fontFamily: ISTOK_WEB,
  //   fontSize: '13px',
  //   fontWeight: 400,
  //   lineHeight: '21px',
  //   letterSpacing: '-0.308px',
  //   background:
  //     background === 'gray'
  //       ? COLORS.grayBackgroundPrimary
  //       : theme.palette.common.white,
  //   padding: '0px 6px',
  // },
  // '& .MuiInputBase-input': {
  //   paddingLeft: '20px',
  //   color: theme.palette.primary.dark,
  //   fontFamily: INTER,
  //   fontSize: '14px',
  //   fontWeight: 600,
  //   lineHeight: '21px',
  //   letterSpacing: '-0.308px',
  // },
  // '& .MuiInputLabel-shrink': {
  //   transform: 'translate(14px, -50%) scale(1)',
  // },
  // '& .MuiOutlinedInput-notchedOutline': {
  //   border: `1px solid ${theme.palette.text.disabled}`,
  // },
  // '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
  //   border: `1px solid ${theme.palette.primary.main}`,
  //   boxShadow: '0px 0px 7.8px 1px rgba(3, 123, 192, 0.41)',
  // },
  // '& legend': {
  //   marginLeft: '5px',
  // },
  // '.MuiOutlinedInput-root:hover:not(.Mui-error):not(.Mui-focused):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline':
  //   {
  //     borderColor: theme.palette.text.disabled,
  //     boxShadow: `0px 0px 5px 2px rgba(3, 123, 192, 0.41)`,
  //   },
  // '& .MuiInputBase-input::placeholder': {
  //   fontWeight: '400',
  // },
}));
