import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from 'types';
import { IAssignment, IClientContact } from 'types/assignments.type';
import {
  createAssignment,
  createClientContact,
  removeAssignment,
  removeClientContact,
  retrieveAssignments,
  retrieveClientContacts,
  updateAssignment,
  updateClientContact,
} from './assignmentsOperations';
import { setError, setLoading } from 'reduxStore/extraReducersHelpers';

interface IAssignmentsState extends ILoadableState {
  assignments: IAssignment[];
  clientContacts: IClientContact[];
  clientContactToUpdate: IClientContact | null;
}

const initialState: IAssignmentsState = {
  assignments: [],
  clientContacts: [],
  clientContactToUpdate: null,
  loading: false,
  error: null,
};

const assignmentsSlice = createSlice({
  name: 'assignments',
  initialState,
  reducers: {
    resetAssignmentsError(state) {
      state.error = null;
    },
    resetAssignmentsAndClientContacts(state) {
      state.assignments = initialState.assignments;
      state.clientContacts = initialState.clientContacts;
    },
    setClientContactToUpdate(state, action: PayloadAction<IClientContact>) {
      state.clientContactToUpdate = action.payload;
    },
    resetClientContactToUpdate(state) {
      state.clientContactToUpdate = initialState.clientContactToUpdate;
    },
    resetAssignmentsData: () => initialState,
  },
  extraReducers(builder) {
    builder
      // retrieveAssignments
      .addCase(retrieveAssignments.pending, setLoading)
      .addCase(retrieveAssignments.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.assignments = payload.assignments;
      })
      .addCase(retrieveAssignments.rejected, setError)
      // retrieveClientContacts
      .addCase(retrieveClientContacts.pending, setLoading)
      .addCase(retrieveClientContacts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.clientContacts = payload.contacts;
      })
      .addCase(retrieveClientContacts.rejected, setError)
      // deleteAssignment
      .addCase(removeAssignment.pending, setLoading)
      .addCase(removeAssignment.fulfilled, state => {
        state.loading = false;
      })
      .addCase(removeAssignment.rejected, setError)
      // updateAssignment
      .addCase(updateAssignment.pending, setLoading)
      .addCase(updateAssignment.fulfilled, state => {
        state.loading = false;
      })
      .addCase(updateAssignment.rejected, setError)
      // createAssignment
      .addCase(createAssignment.pending, setLoading)
      .addCase(createAssignment.fulfilled, state => {
        state.loading = false;
      })
      .addCase(createAssignment.rejected, setError)
      // createClientContact
      .addCase(createClientContact.pending, setLoading)
      .addCase(createClientContact.fulfilled, state => {
        state.loading = false;
      })
      .addCase(createClientContact.rejected, setError)
      // updateClientContact
      .addCase(updateClientContact.pending, setLoading)
      .addCase(updateClientContact.fulfilled, state => {
        state.loading = false;
      })
      .addCase(updateClientContact.rejected, setError)
      // removeClientContact
      .addCase(removeClientContact.pending, setLoading)
      .addCase(removeClientContact.fulfilled, state => {
        state.loading = false;
      })
      .addCase(removeClientContact.rejected, setError);
  },
});

export const {
  resetAssignmentsError,
  resetAssignmentsAndClientContacts,
  setClientContactToUpdate,
  resetClientContactToUpdate,
  resetAssignmentsData,
} = assignmentsSlice.actions;

export default assignmentsSlice.reducer;
