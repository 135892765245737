import React from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import {
  retrieveAssignableContacts,
  retrieveAssignableRoles,
  retrieveClientsRef,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getAssignableContacts,
  getAssignableRoles,
  getClientsRef,
} from 'reduxStore/createProfile/createProfileSelectors';
import { getSelectedClient } from 'reduxStore/organization/organizationSelectors';
import { ICreateAssignmentBody } from 'types/assignments.type';
import * as Yup from 'yup';
import {
  Title,
  WrapperCreateAssignment,
  Error,
  MenuItemBox,
  MenuItemText,
  CustomMenuItem,
  CustomTextField,
} from './CreateAssignment.styled';
import { getAssignmentsError } from 'reduxStore/assignments/assignmentsSelectors';
import { Field, Form, Formik } from 'formik';
import {
  createAssignment,
  retrieveAssignments,
} from 'reduxStore/assignments/assignmentsOperations';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { FlatButton } from 'ui';
import { closeModal } from 'reduxStore/modal/modalSlice';
import { IAssignableContactBody, IReference } from 'types';

const validationSchema = Yup.object({
  client_id: Yup.string()
    .required('Client is required')
    .test('not-select_client', 'Client is required', value => value !== '0'),
  contact_id: Yup.string()
    .required('Team member is required')
    .test(
      'not-select_contact',
      'Team member is required',
      value => value !== '0'
    ),
  role_id: Yup.string()
    .required('Role is required')
    .test('not-select_role', 'Role is required', value => value !== '0'),
});

const CreateAssignment = () => {
  const dispatch = useAppDispatch();

  const clientsRefs = useAppSelector(getClientsRef);
  const assignableRoles = useAppSelector(getAssignableRoles);
  const teamMembers = useAppSelector(getAssignableContacts);
  const clientInfo = useAppSelector(getSelectedClient);
  const error = useAppSelector(getAssignmentsError);

  useEffect(() => {
    dispatch(retrieveAssignableRoles(null));
    dispatch(retrieveAssignableContacts(null));
    dispatch(retrieveClientsRef(null));
  }, [dispatch]);

  const initialValues: ICreateAssignmentBody = {
    client_id: clientInfo?.id || 0,
    contact_id: 0,
    role_id: 0,
  };

  const handleSubmit = async (value: ICreateAssignmentBody) => {
    try {
      await dispatch(createAssignment(value)).unwrap();
      await dispatch(retrieveAssignments(clientInfo?.id ?? 0)).unwrap();
      dispatch(closeModal());
    } catch (error) {
      return;
    }
  };

  return (
    <WrapperCreateAssignment>
      <Title>Create Assignment</Title>
      {!!error && <Error>{error}</Error>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <Grid container flexDirection="column" spacing={2}>
              <Grid item xs={10}>
                <Field
                  name="client_id"
                  error={touched.client_id && Boolean(errors.client_id)}
                  helperText={touched.client_id && errors.client_id}
                >
                  {({ field }: any) => (
                    <Autocomplete
                      {...field}
                      id="client_id"
                      options={clientsRefs}
                      value={
                        clientsRefs.find(client => client.id === field.value) ||
                        null
                      }
                      getOptionLabel={(client: IReference) => client.name || ''}
                      renderOption={(props, client: IReference) => (
                        <MenuItemBox
                          {...(props as React.HTMLAttributes<HTMLLIElement>)}
                          key={client.id}
                        >
                          {client.name}
                        </MenuItemBox>
                      )}
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: IReference | null
                      ) => setFieldValue('client_id', newValue?.id || 0)}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <CustomTextField
                          {...params}
                          label="Select Client"
                          placeholder="Client Name"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{
                            htmlFor: 'client_id',
                            shrink: true,
                          }}
                          error={touched.client_id && Boolean(errors.client_id)}
                          helperText={touched.client_id && errors.client_id}
                        />
                      )}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={10}>
                <Field
                  name="contact_id"
                  error={touched.contact_id && Boolean(errors.contact_id)}
                  helperText={touched.contact_id && errors.contact_id}
                >
                  {({ field }: any) => (
                    <Autocomplete
                      {...field}
                      id="contact_id"
                      options={teamMembers}
                      value={
                        teamMembers.find(
                          teamMember => teamMember.id === field.value
                        ) || null
                      }
                      getOptionLabel={(teamMember: IAssignableContactBody) =>
                        `${teamMember.first_name ?? 'Missing'} ${teamMember.last_name ?? 'Missing'} - ${teamMember.email} - ${teamMember.role.name}` ||
                        ''
                      }
                      renderOption={(
                        props,
                        teamMember: IAssignableContactBody
                      ) => (
                        <MenuItemBox
                          {...(props as React.HTMLAttributes<HTMLLIElement>)}
                          key={teamMember.id}
                        >
                          {`${teamMember.first_name ?? 'Missing'} ${teamMember.last_name ?? 'Missing'} - ${teamMember.email} - ${teamMember.role.name}`}
                        </MenuItemBox>
                      )}
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: IAssignableContactBody | null
                      ) => setFieldValue('contact_id', newValue?.id || 0)}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <CustomTextField
                          {...params}
                          label="Select Team Member"
                          placeholder="Team Member"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{
                            htmlFor: 'contact_id',
                            shrink: true,
                          }}
                          error={
                            touched.contact_id && Boolean(errors.contact_id)
                          }
                          helperText={touched.contact_id && errors.contact_id}
                        />
                      )}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={10}>
                <Field
                  name="role_id"
                  as={TextField}
                  label="Select Role"
                  variant="outlined"
                  select
                  fullWidth
                  error={touched.role_id && Boolean(errors.role_id)}
                  helperText={touched.role_id && errors.role_id}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      height: '59px',
                    },
                  }}
                >
                  <MenuItem value={0} style={{ display: 'none' }}>
                    <MenuItemText>No Role</MenuItemText>
                  </MenuItem>
                  {assignableRoles.map((role, index) => (
                    <MenuItem
                      key={index}
                      value={role.id}
                      style={CustomMenuItem}
                    >
                      <MenuItemText>{role.name}</MenuItemText>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={8} marginTop="15px">
                <FlatButton
                  width="250px"
                  type="submit"
                  variant="contained"
                  isIcon="true"
                >
                  Create
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </WrapperCreateAssignment>
  );
};

export default CreateAssignment;
