import { styled, Box, BoxProps, Typography } from '@mui/material';
import { POPPINS } from 'constants/fonts';

export const WrapperServicesPromoteModal = styled(Box)<BoxProps>(
  ({ theme }) => ({
    height: '400px',
    width: '700px',
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.common.white,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '70px 50px 26px',
    gap: '40px',
  })
);

export const Buttons = styled(Box)<BoxProps>(() => ({
  width: '100%',
  height: '59px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const MainText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: POPPINS,
  fontSize: '28px',
  fontWeight: 500,
  lineHeight: '150%',
  letterSpacing: '-0.704px',
  textAlign: 'center',
}));
