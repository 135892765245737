import { CLIENTS_TABLE } from 'constants/tablesHead';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { resetAssignmentsData } from 'reduxStore/assignments/assignmentsSlice';
import { retrieveMSPClients } from 'reduxStore/createProfile/createProfileOperations';
import { getMSPClients } from 'reduxStore/createProfile/createProfileSelectors';
import { retrieveClientOrganization } from 'reduxStore/organization/organizationOperations';
import { getMainOrganization } from 'reduxStore/organization/organizationSelectors';
import { resetSelectedClient } from 'reduxStore/organization/organizationSlice';
import { ROUTES } from 'routes/routes.const';
import { ClientModeDataTable } from 'ui';
import parsePhoneNumber from 'libphonenumber-js';

const ClientsList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const clients = useAppSelector(getMSPClients);
  const mainOrg = useAppSelector(getMainOrganization);

  useEffect(() => {
    dispatch(retrieveMSPClients(null));
  }, [dispatch]);

  const handleAddClient = () => {
    dispatch(resetSelectedClient());
    dispatch(resetAssignmentsData());
    navigate(ROUTES.ADD_CLIENT);
  };

  const reformatedClients = Array.isArray(clients)
    ? clients.map(client => {
        return {
          name: client.name || '-',
          phone: client.phone
            ? parsePhoneNumber(client.phone)?.formatInternational()
            : '-',
          devices_count: client.devices_count,
          admins_count: client.admins_count,
          alerts_count: client.alerts_count,
          services_count: client.tools_count,
          icon: client.icon || '',
          id: client.id,
        };
      })
    : [];

  const handleSelectClient = async (row: any) => {
    try {
      await dispatch(retrieveClientOrganization(row.id)).unwrap();
      navigate(ROUTES.CLIENT_DASHBOARD);
    } catch (error) {
      return;
    }
  };

  const handleUpdateClient = async (row: any) => {
    try {
      await dispatch(retrieveClientOrganization(row.id)).unwrap();
      navigate(ROUTES.UPDATE_CLIENT);
    } catch (error) {
      return;
    }
  };

  return (
    <ClientModeDataTable
      rows={reformatedClients}
      columns={CLIENTS_TABLE}
      title="Clients"
      showPictures={true}
      addAction={mainOrg?.role.id === 1 ? 'true' : undefined}
      onClick={handleSelectClient}
      handleAdd={mainOrg?.role.id === 1 ? handleAddClient : undefined}
      onEdit={handleUpdateClient}
    />
  );
};

export default ClientsList;
