import React, { useCallback, useEffect, useMemo } from 'react';
import { FlatButton, ServerModeDataTable } from 'ui';
import {
  CustomTextField,
  MenuItemBox,
  Wrapper,
} from './SaaSAlertsAnalysis.styled';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';
import {
  getSaasAlertsEventRefs,
  getSaaSAlertsEvents,
  getSaaSAlertsSeverityLevels,
  getToolsAmountOfElements,
  getToolsLoading,
  getToolsSelectedOrgId,
} from 'reduxStore/tools/toolsSelectors';
import {
  SAAS_ALERTS_CLIENT_VIEW_ANALYSIS_TABLE,
  SAAS_ALERTS_MSP_VIEW_ANALYSIS_TABLE,
} from 'constants/tablesHead';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Grid,
} from '@mui/material';
import { IReference } from 'types';
import {
  retrieveSaaSAlertsAllSeverityLevels,
  retrieveSaaSAlertsEventRefs,
  retrieveSaaSAlertsEvents,
} from 'reduxStore/tools/toolsOperations';
import {
  ISaaSAlertsEventsBody,
  ISaaSAlertsEventsAndLocationsFilter,
} from 'types/saasAlerts.type';
import { resetSaaSAlertsEvents } from 'reduxStore/tools/toolsSlice';
import { GridSortModel } from '@mui/x-data-grid';

interface IAnalysisProps {
  from: Date | null;
  to: Date | null;
  app_id: number | null;
  severity_id: number | null;
  client_id: number | null;
  user_id: number | null;
  alert_id: number | null;
  class_id: number | null;
  threat_score: number | null;
  //ticket_status_id: number | null;
}

const validationSchema = Yup.object({
  from: Yup.date().required('Start Date is required'),
  to: Yup.date().required('End Date is required'),
  threat_score: Yup.number()
    .nullable()
    .notRequired()
    .min(0, 'Value must be at least 0')
    .max(100, 'Value must be at most 100'),
});

const SaaSAlertsAnalysis = () => {
  const dispatch = useAppDispatch();

  const mainOrgId = useAppSelector(getMainOrganizationID);
  const selectedOrgId = useAppSelector(getToolsSelectedOrgId);
  const severityLevels = useAppSelector(getSaaSAlertsSeverityLevels);
  const eventRefs = useAppSelector(getSaasAlertsEventRefs);
  const events = useAppSelector(getSaaSAlertsEvents);

  const sortKeys = eventRefs?.sort_keys;

  const [filters, setFilters] =
    useState<ISaaSAlertsEventsAndLocationsFilter | null>(null);

  const rowCount = useAppSelector(getToolsAmountOfElements); // Total available rows
  const [page, setPage] = useState(0); // Current page index
  const [pageSize, setPageSize] = useState(5); // Rows per page
  const [sortModel, setSortModel] = useState<
    { field: string; sort?: 'asc' | 'desc' | null }[]
  >([]);
  const loading = useAppSelector(getToolsLoading);

  const reformatedEvents = Array.isArray(events)
    ? events.map(event => {
        return {
          org: event.client.name,
          app: event.app.name,
          stamp: `${event.stamp.split(' ')[0]} - ${event.stamp.split(' ')[1]}`,
          user: `${event.user?.name ?? 'No Name'} - ${event.user?.email}`,
          ip_location: `${event.ip?.address} - ${event.ip?.owner?.name} - ${event.loc?.country.name} - ${event.loc?.region} - ${event.loc?.city}`,
          alert_status: event.severity.name,
          description: `${event.alert.code ?? ' - '} ${event.alert.name}`,
          tickets: '-',
          id: event.id,
        };
      })
    : [];

  const expandedClasses = [...(eventRefs?.classes ?? [])];
  expandedClasses?.unshift({
    id: 0,
    code: null,
    name: 'All Malicious IPs',
    icon: null,
  });

  const combinerUsers = useMemo(
    () => [...(eventRefs?.user.emails ?? []), ...(eventRefs?.user.names ?? [])],
    [eventRefs?.user.emails, eventRefs?.user.names]
  );
  combinerUsers.unshift({
    id: 0,
    code: null,
    name: 'Everyone with a name',
    icon: null,
  });

  const fetchEvents = useCallback(
    async (sortId?: number, pageNum?: number, pageSizing?: number) => {
      try {
        const body: ISaaSAlertsEventsBody = {
          page: {
            size: pageSizing ?? pageSize,
            num: pageNum ?? page,
          },
          filter: filters ?? {},
          sort_key_id: sortId ?? 0,
        };
        await dispatch(retrieveSaaSAlertsEvents(body)).unwrap();
      } catch (error) {
        return;
      }
    },
    [dispatch, filters, page, pageSize]
  );

  const handleSortChange = (model: GridSortModel) => {
    if (filters !== null) {
      let sortId = 0;
      if (model?.length && model?.length > 0) {
        const { field, sort } = model.length > 0 ? model[0] : sortModel[0];
        const neededId =
          sortKeys?.find(
            key =>
              key.code === field ||
              (field === 'user' && key.code === 'user-email')
          )?.id ?? 0;

        sortId = sort === 'asc' ? neededId : -neededId;
      }
      setSortModel(model);
      fetchEvents(sortId);
    }
  };

  const handlePageOrPageSizeChange = (newPage: number, newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(newPage);
    if (filters !== null) {
      fetchEvents(undefined, newPage, newPageSize);
    }
  };

  useEffect(() => {
    dispatch(resetSaaSAlertsEvents());
    dispatch(retrieveSaaSAlertsAllSeverityLevels(null));
    dispatch(
      retrieveSaaSAlertsEventRefs({
        client_id: mainOrgId === selectedOrgId ? null : selectedOrgId,
      })
    );
  }, [dispatch, mainOrgId, selectedOrgId]);

  const initialValues: IAnalysisProps = {
    from: null,
    to: null,
    app_id: null,
    severity_id: null,
    client_id: null,
    user_id: null,
    alert_id: null,
    class_id: null,
    threat_score: null,
    //ticket_status_id: null,
  };

  const handleSubmit = async (value: IAnalysisProps) => {
    const body: ISaaSAlertsEventsBody = {
      page: {
        size: pageSize,
        num: page,
      },
      filter: {
        from: value.from?.toString() !== '' ? value.from?.toString() : null,
        to: value.to?.toString() !== '' ? value.to?.toString() : null,
        client_id: value.client_id
          ? value.client_id
          : mainOrgId === selectedOrgId
            ? null
            : selectedOrgId,
        app_id: value.app_id,
        severity_id: value.severity_id,
        user_id: value.user_id !== 0 ? value.user_id : null,
        user_has_name: value.user_id === 0 ? true : undefined,
        alert_id: value.alert_id,
        class_id: value.class_id,
        threat_score: value.threat_score,
      },
      sort_key_id: 0,
    };
    try {
      await dispatch(retrieveSaaSAlertsEvents(body)).unwrap();
      setFilters(body.filter);
    } catch (error) {
      return;
    }
  };

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <Grid
              container
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap="20px"
            >
              <Grid item xs={2}>
                <CustomTextField
                  name="from"
                  type="date"
                  label="Select Start Date"
                  value={values.from || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('from', e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                  error={touched.from && Boolean(errors.from)}
                  helperText={touched.from && errors.from}
                />
              </Grid>
              <Grid item xs={2}>
                <CustomTextField
                  name="to"
                  type="date"
                  label="Select End Date"
                  value={values.to || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('to', e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                  error={touched.to && Boolean(errors.to)}
                  helperText={touched.to && errors.to}
                />
              </Grid>
              <Grid item xs={2}>
                <Field name="app_id" id="app_id">
                  {({ field }: any) => (
                    <Autocomplete
                      {...field}
                      id="app_id"
                      options={eventRefs?.apps ?? []}
                      value={
                        eventRefs?.apps.find(app => app.id === field.value) ||
                        null
                      }
                      getOptionLabel={(product: IReference) =>
                        product.name || ''
                      }
                      renderOption={(props, product: IReference) => (
                        <MenuItemBox
                          {...(props as React.HTMLAttributes<HTMLLIElement>)}
                          key={props.key}
                        >
                          {product.name}
                        </MenuItemBox>
                      )}
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: IReference | null
                      ) => setFieldValue('app_id', newValue?.id)}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <CustomTextField
                          {...params}
                          label="Choose Product"
                          placeholder="Products"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{
                            htmlFor: 'app_id',
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={2}>
                <Field name="severity_id" id="severity_id">
                  {({ field }: any) => (
                    <Autocomplete
                      {...field}
                      id="severity_id"
                      options={severityLevels}
                      value={
                        severityLevels.find(
                          severityLevel => severityLevel.id === field.value
                        ) || null
                      }
                      getOptionLabel={(severityLevel: IReference) =>
                        severityLevel.name || ''
                      }
                      renderOption={(props, severityLevel: IReference) => (
                        <MenuItemBox
                          {...(props as React.HTMLAttributes<HTMLLIElement>)}
                          key={props.key}
                        >
                          {severityLevel.name}
                        </MenuItemBox>
                      )}
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: IReference | null
                      ) => setFieldValue('severity_id', newValue?.id)}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <CustomTextField
                          {...params}
                          label="Choose Alert Status"
                          placeholder="Alert Statuses"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{
                            htmlFor: 'severity_id',
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  )}
                </Field>
              </Grid>
              {mainOrgId === selectedOrgId && (
                <Grid item xs={2}>
                  <Field name="client_id" id="client_id">
                    {({ field }: any) => (
                      <Autocomplete
                        {...field}
                        id="client_id"
                        options={eventRefs?.clients ?? []}
                        value={
                          eventRefs?.clients.find(
                            client => client.id === field.value
                          ) || null
                        }
                        getOptionLabel={(organization: IReference) =>
                          organization.name || ''
                        }
                        renderOption={(props, organization: IReference) => (
                          <MenuItemBox
                            {...(props as React.HTMLAttributes<HTMLLIElement>)}
                            key={props.key}
                          >
                            {organization.name}
                          </MenuItemBox>
                        )}
                        onChange={(
                          event: React.ChangeEvent<{}>,
                          newValue: IReference | null
                        ) => setFieldValue('client_id', newValue?.id)}
                        renderInput={(
                          params: AutocompleteRenderInputParams
                        ) => (
                          <CustomTextField
                            {...params}
                            label="Choose Organization"
                            placeholder="Organizations"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{
                              htmlFor: 'client_id',
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    )}
                  </Field>
                </Grid>
              )}

              <Grid item xs={2}>
                <Field name="user_id" id="user_id">
                  {({ field }: any) => (
                    <Autocomplete
                      {...field}
                      id="user_id"
                      options={combinerUsers}
                      value={
                        combinerUsers.find(user => user.id === field.value) ||
                        null
                      }
                      getOptionLabel={(user: IReference) => user.name || ''}
                      renderOption={(props, user: IReference) => (
                        <MenuItemBox
                          {...(props as React.HTMLAttributes<HTMLLIElement>)}
                          key={props.key}
                        >
                          {user.name}
                        </MenuItemBox>
                      )}
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: IReference | null
                      ) => setFieldValue('user_id', newValue?.id)}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <CustomTextField
                          {...params}
                          label="Choose User"
                          placeholder="Users"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{
                            htmlFor: 'user_id',
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={2}>
                <Field name="alert_id" id="alert_id">
                  {({ field }: any) => (
                    <Autocomplete
                      {...field}
                      id="alert_id"
                      options={eventRefs?.alerts ?? []}
                      value={
                        eventRefs?.alerts.find(
                          alert => alert.id === field.value
                        ) || null
                      }
                      getOptionLabel={(event_type: IReference) =>
                        event_type.name || ''
                      }
                      renderOption={(props, event_type: IReference) => (
                        <MenuItemBox
                          {...(props as React.HTMLAttributes<HTMLLIElement>)}
                          key={props.key}
                        >
                          {event_type.name}
                        </MenuItemBox>
                      )}
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: IReference | null
                      ) => setFieldValue('alert_id', newValue?.id)}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <CustomTextField
                          {...params}
                          label="Choose Event Type"
                          placeholder="Event Types"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{
                            htmlFor: 'alert_id',
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={2}>
                <Field name="class_id" id="class_id">
                  {({ field }: any) => (
                    <Autocomplete
                      {...field}
                      id="class_id"
                      options={expandedClasses}
                      value={
                        expandedClasses?.find(
                          element => element.id === field.value
                        ) || null
                      }
                      getOptionLabel={(element: IReference) =>
                        element.name || ''
                      }
                      renderOption={(props, element: IReference) => (
                        <MenuItemBox
                          {...(props as React.HTMLAttributes<HTMLLIElement>)}
                          key={props.key}
                        >
                          {element.name}
                        </MenuItemBox>
                      )}
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: IReference | null
                      ) => setFieldValue('class_id', newValue?.id)}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <CustomTextField
                          {...params}
                          label="Choose IP Status"
                          placeholder="IP Statuses"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{
                            htmlFor: 'class_id',
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={2}>
                <CustomTextField
                  name="threat_score"
                  label="IP Threat Score >"
                  type="number"
                  placeholder="0"
                  fullWidth
                  variant="outlined"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = isNaN(e.target.valueAsNumber)
                      ? null
                      : e.target.valueAsNumber;
                    setFieldValue('threat_score', value);
                  }}
                  InputLabelProps={{
                    htmlFor: 'threat_score',
                    shrink: true,
                  }}
                  error={touched.threat_score && Boolean(errors.threat_score)}
                  helperText={touched.threat_score && errors.threat_score}
                />
              </Grid>
              {/* <Grid item xs={2}>
                <Field name="ticket_status_id" id="ticket_status_id">
                  {({ field }: any) => (
                    <Autocomplete
                      {...field}
                      id="ticket_status_id"
                      options={[]}
                      //value={}
                      getOptionLabel={(client: IReference) => client.name || ''}
                      renderOption={(props, client: IReference) => (
                        <MenuItemBox
                          {...(props as React.HTMLAttributes<HTMLLIElement>)}
                          key={props.key}
                        >
                          {client.name}
                        </MenuItemBox>
                      )}
                      //   onChange={(
                      //     event: React.ChangeEvent<{}>,
                      //     newValue: IReference | null
                      //   ) => }
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <CustomTextField
                          {...params}
                          label="Choose Ticket Status"
                          placeholder="Ticket Statuses"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{
                            htmlFor: 'ticket_status_id',
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  )}
                </Field>
              </Grid> */}
              <Grid item xs={2}>
                <FlatButton
                  width="100%"
                  type="submit"
                  variant="contained"
                  height="40px"
                >
                  Run Analysis
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <ServerModeDataTable
        rows={reformatedEvents}
        columns={
          mainOrgId === selectedOrgId
            ? SAAS_ALERTS_MSP_VIEW_ANALYSIS_TABLE
            : SAAS_ALERTS_CLIENT_VIEW_ANALYSIS_TABLE
        }
        addCreateTicket="true"
        rowCount={rowCount}
        page={page}
        pageSize={pageSize}
        onPagePropsChange={handlePageOrPageSizeChange}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortChange={model => handleSortChange(model)}
        loading={loading}
        disableColumnMenus={true}
        nowRowsText="No Rows. For them to appear please select filters and press Run Analysis"
      />
    </Wrapper>
  );
};

export default SaaSAlertsAnalysis;
