import { useAppDispatch, useAppSelector } from 'hooks';
import { WrapperDataBoxes, WrapperEDR } from './HuntressEDRPage.styled';
import {
  getHuntressGeneralStatistics,
  getToolsSelectedOrgId,
} from 'reduxStore/tools/toolsSelectors';
import { retrieveHuntressGeneralStatistics } from 'reduxStore/tools/toolsOperations';
import { useEffect } from 'react';
import { DataBox, StatisticsCard } from 'ui';
import { IStatisticsCardData } from 'types/table.type';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import FmdBadOutlinedIcon from '@mui/icons-material/FmdBadOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { theme } from 'theme';
import { COLORS } from 'theme/colors.const';

const HuntressEDRPage = () => {
  const dispatch = useAppDispatch();
  const generalStatistics = useAppSelector(getHuntressGeneralStatistics);
  const selectedOrgId = useAppSelector(getToolsSelectedOrgId);

  const sumOfDefenders =
    (generalStatistics?.defenders.protected_count ?? 0) +
    (generalStatistics?.defenders.incompatible_count ?? 0) +
    (generalStatistics?.defenders.unmanaged_count ?? 0) +
    (generalStatistics?.defenders.healthy_count ?? 0) +
    (generalStatistics?.defenders.unhealthy_count ?? 0);

  const activeIncidentsData = [
    {
      title: 'Critical',
      amount: generalStatistics?.incidents.sent.crit_count || 0,
      color: theme.palette.error.main,
    },
    {
      title: 'High',
      amount: generalStatistics?.incidents.sent.high_count || 0,
      color: COLORS.yellowDark,
    },
    {
      title: 'Low',
      amount: generalStatistics?.incidents.sent.low_count || 0,
      color: COLORS.greenDark,
    },
  ];

  const resolvedIncidentsData = [
    {
      title: 'Month',
      amount: generalStatistics?.incidents.closed.monthly_count || 0,
      color: COLORS.greenLight,
    },
    {
      title: 'Quarter',
      amount: generalStatistics?.incidents.closed.quarterly_count || 0,
      color: COLORS.greenLight,
    },
    {
      title: 'Year',
      amount: generalStatistics?.incidents.closed.yearly_count || 0,
      color: COLORS.greenLight,
    },
  ];

  const reformatedAgents: IStatisticsCardData[] = [
    {
      icon: <ShieldOutlinedIcon fontSize="small" />,
      label: 'Protected',
      counter: generalStatistics?.agents.protected_count,
    },
    {
      icon: <FmdBadOutlinedIcon fontSize="small" />,
      label: 'Unresponsive',
      counter: generalStatistics?.agents.unresponsive_count,
    },
    {
      icon: <AccessTimeOutlinedIcon fontSize="small" />,
      label: 'Outdated',
      counter: generalStatistics?.agents.outdated_count,
    },
    {
      icon: <LockOutlinedIcon fontSize="small" />,
      label: 'Isolated',
      counter: generalStatistics?.agents.isolated_count,
    },
  ];

  useEffect(() => {
    dispatch(retrieveHuntressGeneralStatistics(selectedOrgId));
  }, [dispatch, selectedOrgId]);

  return (
    <WrapperEDR>
      <WrapperDataBoxes>
        <DataBox
          title="Active Incidents"
          data={activeIncidentsData}
          width="45%"
          height="30%"
        />
        <DataBox
          title="Resolved Incidents"
          data={resolvedIncidentsData}
          width="45%"
          height="30%"
          setSumOfElements={generalStatistics?.incidents.closed.yearly_count}
        />
      </WrapperDataBoxes>
      <StatisticsCard
        title="Agent Status"
        subTitle={`Win Defender Firewall is on (${sumOfDefenders}) Hosts `}
        statistics={reformatedAgents}
      />
    </WrapperEDR>
  );
};

export default HuntressEDRPage;
