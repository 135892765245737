import { Box, BoxProps, styled } from '@mui/material';

export const WrapperMSPDashboard = styled(Box)<BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '50px',
}));

export const WrapperStatistics = styled(Box)<BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  //for later enhancements
  // gap: '20px',
  // flexWrap: 'wrap',
}));
