import { Stack } from '@mui/material';
import { Outlet } from 'react-router';
import { TextTabs } from 'ui';
import { ROUTES } from 'routes/routes.const';
import {
  CustomDivider,
  SaaSAlertsContainer,
} from './SaaSAlertsLayoutPage.styled';
import { useAppDispatch } from 'hooks';
import { useEffect } from 'react';
import { retrieveSaaSAlertsAllSeverityLevels } from 'reduxStore/tools/toolsOperations';

const SaaSAlertsLayoutPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(retrieveSaaSAlertsAllSeverityLevels(null));
  }, [dispatch]);

  const navOptions = [
    {
      title: 'Overview',
      url: ROUTES.SAAS_ALERTS_OVERVIEW,
    },
    {
      title: 'Event Monitoring',
      url: ROUTES.SAAS_ALERTS_EVENT_MONITORING,
    },
    {
      title: 'Account Management',
      url: ROUTES.SAAS_ALERTS_ACCOUNT_MANAGEMENT,
    },
    {
      title: 'Analysis',
      url: ROUTES.SAAS_ALERTS_ANALYSIS,
    },
  ];

  return (
    <Stack direction="row" width="100%">
      <SaaSAlertsContainer>
        <TextTabs
          height="20px"
          //width="100%"
          options={navOptions}
          hierarchy="parent"
        ></TextTabs>
        <CustomDivider />
        <Outlet />
      </SaaSAlertsContainer>
    </Stack>
  );
};

export default SaaSAlertsLayoutPage;
