//import { HeaderWithSearch } from 'ui';
import { HeaderWithOrgSelection } from 'ui';
import { WrapperGeneral } from './DashboardPage.styled';
import { Outlet } from 'react-router';

const DashboardPage = () => {
  return (
    <WrapperGeneral>
      <HeaderWithOrgSelection />
      <Outlet />
    </WrapperGeneral>
  );
};

export default DashboardPage;
