import { AUDIT_LOGS_SERVER_MODE_TABLE } from 'constants/tablesHead';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import {
  getAmountOfElements,
  getAuditLogRefs,
  getAuditLogs,
  getOrganizationLoading,
} from 'reduxStore/organization/organizationSelectors';
import { FlatButton, ServerModeDataTable } from 'ui';
import { CustomTextField, MenuItemBox, Wrapper } from './AuditLogList.styled';
import { Field, Form, Formik } from 'formik';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Grid,
} from '@mui/material';
import {
  IAuditLogAdmin,
  IAuditLogsBody,
  IAuditLogsFilter,
  IReference,
} from 'types';
import { resetAuditLogs } from 'reduxStore/organization/organizationSlice';
import {
  retrieveAuditLogRefs,
  retrieveAuditLogs,
} from 'reduxStore/organization/organizationOperations';
import { GridSortModel } from '@mui/x-data-grid';

interface IAuditLogsProps {
  start_date: Date | null;
  end_date: Date | null;
  client_id: number | null;
  user_id: number | null;
  route_id: number | null;
  status_id: number | null;
}

const AuditLogsList = () => {
  const dispatch = useAppDispatch();

  const rowCount = useAppSelector(getAmountOfElements); // Total available rows
  const [page, setPage] = useState(0); // Current page index
  const [pageSize, setPageSize] = useState(5); // Rows per page
  const loading = useAppSelector(getOrganizationLoading);
  const logRefs = useAppSelector(getAuditLogRefs);
  const logs = useAppSelector(getAuditLogs);

  const sortKeys = logRefs?.sort_keys;

  const [sortModel, setSortModel] = useState<
    { field: string; sort?: 'asc' | 'desc' | null }[]
  >([]);

  const [filters, setFilters] = useState<IAuditLogsFilter | null>(null);

  useEffect(() => {
    dispatch(resetAuditLogs());
    dispatch(retrieveAuditLogRefs({}));
  }, [dispatch]);

  const reformatedLogs = logs.map(log => {
    return {
      admin: `${log.admin.first_name} ${log.admin.last_name} - ${log.admin.email}`,
      route: log.route.name,
      org: log.org?.name,
      status: `${log.http_status.code} - ${log.http_status.name}`,
      stamp: `${log.stamp.split(' ')[0]} - ${log.stamp.split(' ')[1]}`,
      ip: log.ip,
      id: log.id,
    };
  });

  const initialValues: IAuditLogsProps = {
    start_date: null,
    end_date: null,
    client_id: null,
    user_id: null,
    route_id: null,
    status_id: null,
  };

  const orgs = [...(logRefs?.clients ?? [])];
  orgs.unshift({
    id: 0,
    code: null,
    name: 'Only MSP',
    icon: null,
  });

  const fetchLogs = useCallback(
    async (sortId?: number, pageNum?: number, pageSizing?: number) => {
      try {
        const body: IAuditLogsBody = {
          page: {
            size: pageSizing ?? pageSize,
            num: pageNum ?? page,
          },
          filter: filters ?? {},
          sort_key_id: sortId ?? 0,
        };
        await dispatch(retrieveAuditLogs(body)).unwrap();
      } catch (error) {
        return;
      }
    },
    [dispatch, filters, page, pageSize]
  );

  const handleSortChange = (model: GridSortModel) => {
    if (filters !== null) {
      let sortId = 0;
      if (model?.length && model?.length > 0) {
        const { field, sort } = model.length > 0 ? model[0] : sortModel[0];
        const neededId =
          sortKeys?.find(
            key =>
              key.code === field ||
              (field === 'admin' && key.code === 'admin-email')
          )?.id ?? 0;

        sortId = sort === 'asc' ? neededId : -neededId;
      }
      setSortModel(model);
      fetchLogs(sortId);
    }
  };

  const handlePageOrPageSizeChange = (newPage: number, newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(newPage);
    if (filters !== null) {
      fetchLogs(undefined, newPage, newPageSize);
    }
  };

  const handleSelectLog = () => {};

  const handleSubmit = async (value: IAuditLogsProps) => {
    const body: IAuditLogsBody = {
      page: {
        size: pageSize,
        num: page,
      },
      filter: {
        from:
          value.start_date?.toString() !== ''
            ? value.start_date?.toString()
            : null,
        to:
          value.end_date?.toString() !== '' ? value.end_date?.toString() : null,
        client_id: value.client_id,
        admin_id: value.user_id,
        route_id: value.route_id,
        http_status_id: value.status_id,
      },
      sort_key_id: 0,
    };
    try {
      await dispatch(retrieveAuditLogs(body)).unwrap();
      setFilters(body.filter);
    } catch (error) {
      return;
    }
  };

  return (
    <Wrapper>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <Grid
              container
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap="20px"
            >
              <Grid item xs={3}>
                <CustomTextField
                  name="start_date"
                  type="date"
                  label="Select Start Date"
                  value={values.start_date || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('start_date', e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomTextField
                  name="end_date"
                  type="date"
                  label="Select End Date"
                  value={values.end_date || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('end_date', e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={3}>
                <Field name="client_id" id="client_id">
                  {({ field }: any) => (
                    <Autocomplete
                      {...field}
                      id="client_id"
                      options={orgs}
                      value={orgs.find(org => org.id === field.value) || null}
                      getOptionLabel={(org: IReference) => org.name || ''}
                      renderOption={(props, org: IReference) => (
                        <MenuItemBox
                          {...(props as React.HTMLAttributes<HTMLLIElement>)}
                          key={props.key}
                        >
                          {org.name}
                        </MenuItemBox>
                      )}
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: IReference | null
                      ) => setFieldValue('client_id', newValue?.id)}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <CustomTextField
                          {...params}
                          label="Choose Organization"
                          placeholder="Organizations"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{
                            htmlFor: 'client_id',
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={3}>
                <Field name="user_id" id="user_id">
                  {({ field }: any) => (
                    <Autocomplete
                      {...field}
                      id="user_id"
                      options={logRefs?.admins ?? []}
                      value={
                        logRefs?.admins.find(
                          admin => admin.id === field.value
                        ) || null
                      }
                      getOptionLabel={(admin: IAuditLogAdmin) =>
                        admin.email || ''
                      }
                      renderOption={(props, admin: IAuditLogAdmin) => (
                        <MenuItemBox
                          {...(props as React.HTMLAttributes<HTMLLIElement>)}
                          key={props.key}
                        >
                          {`${admin.first_name} ${admin.last_name} - ${admin.email}`}
                        </MenuItemBox>
                      )}
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: IAuditLogAdmin | null
                      ) => setFieldValue('user_id', newValue?.id)}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <CustomTextField
                          {...params}
                          label="Choose Admin"
                          placeholder="Admins"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{
                            htmlFor: 'user_id',
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={3}>
                <Field name="route_id" id="route_id">
                  {({ field }: any) => (
                    <Autocomplete
                      {...field}
                      id="route_id"
                      options={logRefs?.routes ?? []}
                      value={
                        logRefs?.routes.find(
                          route => route.id === field.value
                        ) || null
                      }
                      getOptionLabel={(route: IReference) => route.name || ''}
                      renderOption={(props, route: IReference) => (
                        <MenuItemBox
                          {...(props as React.HTMLAttributes<HTMLLIElement>)}
                          key={props.key}
                        >
                          {route.name}
                        </MenuItemBox>
                      )}
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: IReference | null
                      ) => setFieldValue('route_id', newValue?.id)}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <CustomTextField
                          {...params}
                          label="Choose Route"
                          placeholder="Routes"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{
                            htmlFor: 'route_id',
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={3}>
                <Field name="status_id" id="status_id">
                  {({ field }: any) => (
                    <Autocomplete
                      {...field}
                      id="status_id"
                      options={logRefs?.http_statuses ?? []}
                      value={
                        logRefs?.http_statuses.find(
                          http_status => http_status.id === field.value
                        ) || null
                      }
                      getOptionLabel={(http_status: IReference) =>
                        http_status.name
                          ? `${http_status.code} - ${http_status.name}`
                          : ''
                      }
                      renderOption={(props, http_status: IReference) => (
                        <MenuItemBox
                          {...(props as React.HTMLAttributes<HTMLLIElement>)}
                          key={props.key}
                        >
                          {`${http_status.code} - ${http_status.name}`}
                        </MenuItemBox>
                      )}
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: IReference | null
                      ) => setFieldValue('status_id', newValue?.id)}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <CustomTextField
                          {...params}
                          label="Choose Status"
                          placeholder="Statuses"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{
                            htmlFor: 'status_id',
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={2}>
                <FlatButton
                  width="100%"
                  type="submit"
                  variant="contained"
                  height="40px"
                >
                  Show Logs
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <ServerModeDataTable
        rows={reformatedLogs}
        columns={AUDIT_LOGS_SERVER_MODE_TABLE}
        title="Audit Logs"
        onClick={handleSelectLog}
        rowCount={rowCount}
        loading={loading}
        page={page}
        pageSize={pageSize}
        onPagePropsChange={handlePageOrPageSizeChange}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortChange={model => handleSortChange(model)}
        disableColumnMenus={true}
        nowRowsText="No Rows. For them to appear please select filters and press Show Logs"
      />
    </Wrapper>
  );
};

export default AuditLogsList;
