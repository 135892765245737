import { useAppDispatch, useAppSelector } from 'hooks';
import { Wrapper } from './SaaSAlertsEventsMedium.styled';
import {
  SAAS_ALERTS_EVENTS_CLIENT_VIEW_CRITICAL_AND_MEDIUM_TABLE,
  SAAS_ALERTS_EVENTS_MSP_VIEW_CRITICAL_AND_MEDIUM_TABLE,
} from 'constants/tablesHead';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';
import {
  getSaaSAlertsEvents,
  getSaaSAlertsSeverityLevels,
  getToolsSelectedOrgId,
} from 'reduxStore/tools/toolsSelectors';
import { ClientModeDataTable } from 'ui';
import { useEffect } from 'react';
import { ISaaSAlertsEventsBody } from 'types/saasAlerts.type';
import { retrieveSaaSAlertsEvents } from 'reduxStore/tools/toolsOperations';

const SaaSAlertsEventsMedium = () => {
  const dispatch = useAppDispatch();
  const mainOrgId = useAppSelector(getMainOrganizationID);
  const selectedOrgId = useAppSelector(getToolsSelectedOrgId);
  const mediumEvents = useAppSelector(getSaaSAlertsEvents);
  const severityLevels = useAppSelector(getSaaSAlertsSeverityLevels);

  useEffect(() => {
    const body: ISaaSAlertsEventsBody = {
      page: {
        size: 100,
        num: 0,
      },
      filter: {
        client_id: mainOrgId === selectedOrgId ? null : selectedOrgId,
        severity_id: severityLevels.find(level => level.code === 'medium')?.id,
      },
      sort_key_id: 0,
    };

    dispatch(retrieveSaaSAlertsEvents(body));
  }, [dispatch, mainOrgId, selectedOrgId, severityLevels]);

  const reformatedMediumEvents = Array.isArray(mediumEvents)
    ? mediumEvents.map(mediumEvent => {
        return {
          organization: mediumEvent.client.name,
          product: mediumEvent.app.name,
          date_time: `${mediumEvent.stamp.split(' ')[0]} - ${mediumEvent.stamp.split(' ')[1]}`,
          user: `${mediumEvent.user?.name ?? 'No Name'} - ${mediumEvent.user?.email}`,
          ip_location: mediumEvent.ip?.address,
          description: `${mediumEvent.alert.code ?? ' - '} ${mediumEvent.alert.name}`,
          //tickets: '-',
          id: mediumEvent.id,
        };
      })
    : [];

  const handleReload = async () => {
    const body: ISaaSAlertsEventsBody = {
      page: {
        size: 100,
        num: 0,
      },
      filter: {
        client_id: mainOrgId === selectedOrgId ? null : selectedOrgId,
        severity_id: severityLevels.find(level => level.code === 'medium')?.id,
      },
      sort_key_id: 0,
    };

    try {
      await dispatch(retrieveSaaSAlertsEvents(body)).unwrap();
    } catch (error) {
      return;
    }
  };

  return (
    <Wrapper>
      <ClientModeDataTable
        title="Real Time Medium Alerts"
        handleReload={handleReload}
        rows={reformatedMediumEvents}
        columns={
          mainOrgId === selectedOrgId
            ? SAAS_ALERTS_EVENTS_MSP_VIEW_CRITICAL_AND_MEDIUM_TABLE
            : SAAS_ALERTS_EVENTS_CLIENT_VIEW_CRITICAL_AND_MEDIUM_TABLE
        }
        addCreateTicket="true"
      />
    </Wrapper>
  );
};

export default SaaSAlertsEventsMedium;
