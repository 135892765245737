import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { retrieveHuntressAgents } from 'reduxStore/tools/toolsOperations';
import {
  getHuntressAgents,
  getToolsSelectedOrgId,
} from 'reduxStore/tools/toolsSelectors';
import { Wrapper } from './HuntressAgents.styled';
import { ClientModeDataTable } from 'ui';
import { HUNTRESS_AGENTS_TABLE } from 'constants/tablesHead';

const HuntressAgents = () => {
  const dispatch = useAppDispatch();
  const agents = useAppSelector(getHuntressAgents);
  const selectedOrgId = useAppSelector(getToolsSelectedOrgId);

  const reformatedAgents = agents.map(agent => {
    return {
      name: agent.name,
      os_name: agent.os_name,
      edr_version: agent.edr_version,
      ext_ip: agent.ext_ip,
      int_ip: agent.int_ip,
      registered_at: agent.registered_at,
      last_seen_at: agent.last_seen_at,
      id: agents.indexOf(agent),
    };
  });

  useEffect(() => {
    dispatch(retrieveHuntressAgents(selectedOrgId));
  }, [dispatch, selectedOrgId]);

  return (
    <Wrapper>
      <ClientModeDataTable
        columns={HUNTRESS_AGENTS_TABLE}
        rows={reformatedAgents}
      />
    </Wrapper>
  );
};

export default HuntressAgents;
