import { TEAM_MEMBERS_TABLE } from 'constants/tablesHead';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import {
  retrieveMSPContacts,
  retrieveMSPRoles,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getMSPContacts,
  getMSPRoles,
} from 'reduxStore/createProfile/createProfileSelectors';
import {
  getAdminInfo,
  getMainOrganization,
} from 'reduxStore/organization/organizationSelectors';
import {
  resetTeamMemberToUpdate,
  setTeamMemberToUpdate,
} from 'reduxStore/organization/organizationSlice';
import { ROUTES } from 'routes/routes.const';
import { IUpdateMSPContactBody } from 'types/api.type';
import { ClientModeDataTable } from 'ui';
import parsePhoneNumber from 'libphonenumber-js';

const TeamMembersList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const contacts = useAppSelector(getMSPContacts);
  const mspRoles = useAppSelector(getMSPRoles);
  const mainOrg = useAppSelector(getMainOrganization);
  const admin = useAppSelector(getAdminInfo);

  const handleAddTeamMember = () => {
    dispatch(resetTeamMemberToUpdate());
    navigate(ROUTES.ADD_OR_UPDATE_TEAM_MEMBER);
  };

  const handleUpdateTeamMember = (row: any) => {
    if (row.email === admin?.email) {
      navigate(ROUTES.ACCOUNT_SETTINGS);
      return;
    }

    const teamMemberToUpdate: IUpdateMSPContactBody = {
      first_name: row.first_name,
      last_name: row.last_name,
      email: row.email,
      phone: parsePhoneNumber(row.phone)?.formatInternational() || row.phone,
      id: row.id,
      role_id: mspRoles.find(role => role.name === row.role)?.id ?? 0,
      icon: row.icon,
    };
    dispatch(setTeamMemberToUpdate(teamMemberToUpdate));
    navigate(ROUTES.ADD_OR_UPDATE_TEAM_MEMBER);
  };

  useEffect(() => {
    dispatch(retrieveMSPContacts(null));
    dispatch(retrieveMSPRoles(null));
  }, [dispatch]);

  const reformatedContacts =
    contacts?.map(contact => {
      return {
        first_name: contact.first_name || '-',
        last_name: contact.last_name || '-',
        phone: contact.phone
          ? parsePhoneNumber(contact.phone)?.formatInternational()
          : '-',
        email: contact.email,
        role: contact.role?.name || '-',
        icon: contact.icon || '',
        id: contact.id,
      };
    }) || [];

  return (
    <ClientModeDataTable
      rows={reformatedContacts}
      columns={TEAM_MEMBERS_TABLE}
      title="Team Members"
      addAction={mainOrg?.role.id === 1 ? 'true' : undefined}
      showPictures={true}
      handleAdd={mainOrg?.role.id === 1 ? handleAddTeamMember : undefined}
      onClick={mainOrg?.role.id === 1 ? handleUpdateTeamMember : undefined}
    />
  );
};

export default TeamMembersList;
