import { FlatButton } from 'ui';
import useAppDispatch from 'hooks/useAppDispatch';
import { closeModal } from 'reduxStore/modal/modalSlice';
import { WrapperActionSuccess, MainText } from './ActionSuccess.styled';
import { Box } from '@mui/material';

interface IActionSuccessProps {
  text?: string;
}

const ActionSuccess = ({ text }: IActionSuccessProps) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(closeModal());
  };

  return (
    <WrapperActionSuccess>
      <Box width="500px" height="96px">
        <MainText style={{ whiteSpace: 'pre-wrap' }}>{text}</MainText>
      </Box>

      <FlatButton
        onClick={handleClick}
        variant="contained"
        width="134px"
        height="50px"
      >
        OK
      </FlatButton>
    </WrapperActionSuccess>
  );
};
export default ActionSuccess;
