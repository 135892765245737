import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { INTER } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

export const Wrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '33px',
}));

export const WrapperHeader = styled(Box)<BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const Title = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '22px',
  fontWeight: 400,
  lineHeight: '28px',
  letterSpacing: '-0.308px',
}));

export const MenuItemText = styled(Typography)(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '21px',
  letterSpacing: '-0.308px',
}));

export const MenuItemBox = {
  display: 'flex',
  justifyContent: 'center',
};
