import { useEffect, useState } from 'react';
import {
  WrapperClientDashboard,
  WrapperErrors,
  WrapperStatistics,
  Error,
} from './ClientDashboardView.styled';
import {
  getAuditLogs,
  getClientIntegrations,
  getOrganizationError,
  getSelectedClient,
} from 'reduxStore/organization/organizationSelectors';
import {
  getAssignments,
  getAssignmentsError,
  getClientContacts,
} from 'reduxStore/assignments/assignmentsSelectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  retrieveAssignments,
  retrieveClientContacts,
} from 'reduxStore/assignments/assignmentsOperations';
import {
  retrieveAuditLogs,
  retrieveClientIntegrations,
  retrieveClientOrganization,
} from 'reduxStore/organization/organizationOperations';
import { retrieveAssignableRoles } from 'reduxStore/createProfile/createProfileOperations';
import { useNavigate } from 'react-router';
import {
  AUDIT_LOGS_CLIENT_MODE_TABLE,
  CLIENT_INTEGRATED_SERVICES_TABLE,
  TEAM_MEMBERS_TABLE,
} from 'constants/tablesHead';
import {
  ClientModeDataTable,
  GeneralStatistics,
  VerticalOrHorizontalBar,
} from 'ui';
import { IAssignment, IClientContact } from 'types/assignments.type';
import { IAuditLogsBody, IReference } from 'types';
import {
  resetToolsAllData,
  setToolsSelectedOrgId,
  setToolsSelectedOrgName,
  setToolsSelectedToolId,
} from 'reduxStore/tools/toolsSlice';
import { ROUTES } from 'routes/routes.const';
import { resetAssignmentsError } from 'reduxStore/assignments/assignmentsSlice';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import { retrieveHuntressGeneralStatistics } from 'reduxStore/tools/toolsOperations';
import parsePhoneNumber from 'libphonenumber-js';

interface IMenuOptions {
  onClickAdd: () => void;
  title: 'Assignments' | 'Client Contacts' | 'Connected Services';
  data: IAssignment[] | IClientContact[] | IReference[] | null;
}

const ClientDashboardView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const clientInfo = useAppSelector(getSelectedClient);
  const assignments = useAppSelector(getAssignments);
  const clientContacts = useAppSelector(getClientContacts);
  const clientIntegrations = useAppSelector(getClientIntegrations);
  const organizationError = useAppSelector(getOrganizationError);
  const assignmentsError = useAppSelector(getAssignmentsError);
  const auditLogs = useAppSelector(getAuditLogs);

  const connectedTools = clientIntegrations.map(clientIntegration => {
    return clientIntegration.tool;
  });

  const assignedContacts = assignments.map(assignment => {
    return assignment.contact;
  });

  const [selectedElement, setSelectedElement] = useState<
    'Assignments' | 'Client Contacts' | 'Connected Services'
  >('Assignments');

  useEffect(() => {
    const body: IAuditLogsBody = {
      page: { size: 100, num: 0 },
      filter: {
        client_id: clientInfo?.id,
      },
      sort_key_id: 0,
    };
    dispatch(retrieveAuditLogs(body));
    dispatch(retrieveAssignments(clientInfo?.id ?? 0));
    dispatch(retrieveClientContacts(clientInfo?.id ?? 0));
    dispatch(retrieveClientIntegrations(clientInfo?.id ?? 0));
    dispatch(retrieveAssignableRoles(null));
  }, [clientInfo?.id, dispatch]);

  const body: IAuditLogsBody = {
    page: { size: 100, num: 0 },
    filter: {
      client_id: clientInfo?.id,
    },
    sort_key_id: 0,
  };

  const reformatedServices = Array.isArray(clientIntegrations)
    ? clientIntegrations.map(integration => {
        return {
          service_name: integration.tool.name,
          devices:
            integration.tool.code !== 'connect-wise'
              ? integration.intgrn.devices_count
              : 67,
          agents:
            integration.tool.code !== 'connect-wise'
              ? integration.intgrn.agents_count
              : 67,
          alerts:
            integration.tool.code !== 'connect-wise'
              ? integration.intgrn.alerts_count
              : 67,
          icon: integration.tool.icon || '',
          id: integration.tool.id,
        };
      })
    : [];

  const reformatedAuditLogs = auditLogs.map(log => {
    return {
      admin: `${log.admin.first_name} ${log.admin.last_name} - ${log.admin.email}`,
      route: log.route.name,
      org: log.org?.name,
      status: `${log.http_status.code} - ${log.http_status.name}`,
      stamp: `${log.stamp.split(' ')[0]} - ${log.stamp.split(' ')[1]}`,
      ip: log.ip,
      id: log.id,
    };
  });

  const reformatedClientContacts = Array.isArray(clientContacts)
    ? clientContacts.map(clientContact => {
        return {
          first_name: clientContact.first_name || '-',
          last_name: clientContact.last_name || '-',
          phone: clientContact.phone
            ? parsePhoneNumber(clientContact.phone)?.formatInternational()
            : '-',
          email: clientContact.email,
          role: 'Client Admin',
          icon: clientContact.icon || '',
          id: clientContact.id,
        };
      })
    : [];

  const reformatedAssignments = Array.isArray(assignments)
    ? assignments.map(assignment => {
        return {
          first_name: assignment.contact.first_name || '-',
          last_name: assignment.contact.last_name || '-',
          phone: assignment.contact.phone
            ? parsePhoneNumber(assignment.contact.phone)?.formatInternational()
            : '-',
          email: assignment.contact.email,
          role: assignment.role.name,
          icon: assignment.contact.icon || '',
          id: assignment.id,
        };
      })
    : [];

  const data = [
    { id: 1, completedAt: '2024-03-01', amount: 10 },
    { id: 2, completedAt: '2024-03-02', amount: 33 },
    { id: 3, completedAt: '2024-03-03', amount: 15 },
    { id: 4, completedAt: '2024-03-04', amount: 27 },
    { id: 9, completedAt: '2024-05-02', amount: 45 },
    { id: 10, completedAt: '2024-06-20', amount: 10 },
  ];

  const options: IMenuOptions[] = [
    {
      onClickAdd: () => {
        dispatch(resetAssignmentsError());
        dispatch(
          setModalProps({
            radius: '22px',
          })
        );
        dispatch(openModal());
        dispatch(setContent('CreateAssignment'));
      },
      title: 'Assignments',
      data: assignedContacts,
    },
    {
      onClickAdd: () => {
        dispatch(
          setModalProps({
            radius: '22px',
          })
        );
        dispatch(openModal());
        dispatch(setContent('AddOrUpdateClientContactModal'));
      },
      title: 'Client Contacts',
      data: clientContacts,
    },
    {
      onClickAdd: () => {
        dispatch(resetToolsAllData());
        navigate(ROUTES.ADD_SERVICE, {
          state: { intgrLevel: 2, clientId: clientInfo?.id },
        });
      },
      title: 'Connected Services',
      data: connectedTools,
    },
  ];

  const handleEdit = async () => {
    try {
      await dispatch(retrieveClientOrganization(clientInfo?.id || 0)).unwrap();
      navigate(ROUTES.UPDATE_CLIENT);
    } catch (error) {
      return;
    }
  };

  const handleSelectService = async (row: any) => {
    try {
      if (row.service_name === 'Huntress') {
        await dispatch(
          retrieveHuntressGeneralStatistics(clientInfo?.id || 0)
        ).unwrap();
        dispatch(setToolsSelectedOrgName(clientInfo?.name || ''));
        dispatch(setToolsSelectedOrgId(clientInfo?.id || 0));
        dispatch(setToolsSelectedToolId(row.id));
        navigate(ROUTES.HUNTRESS_DASHBOARD);
      } else if (row.service_name === 'SaaS Alerts') {
        dispatch(setToolsSelectedOrgName(clientInfo?.name || ''));
        dispatch(setToolsSelectedOrgId(clientInfo?.id || 0));
        dispatch(setToolsSelectedToolId(row.id));
        navigate(ROUTES.SAAS_ALERTS_OVERVIEW_DASHBOARD);
      }
    } catch (error) {
      return;
    }
  };

  const onReload = async () => {
    await dispatch(retrieveAuditLogs(body)).unwrap();
  };

  return (
    <WrapperClientDashboard>
      {(organizationError || assignmentsError) && (
        <WrapperErrors>
          {organizationError && <Error>{organizationError}</Error>}
          {assignmentsError && <Error>{assignmentsError}</Error>}
        </WrapperErrors>
      )}
      <WrapperStatistics>
        {options.map((option, index) => (
          <span
            role="button"
            key={index}
            onClick={() => setSelectedElement(option.title)}
            tabIndex={0}
          >
            <GeneralStatistics
              onClickAdd={option.onClickAdd}
              onClickMainAction={handleEdit}
              mainActionButtonTitle="Edit"
              width="25vw"
              title={option.title}
              data={option.data}
              isactive={selectedElement === option.title ? 'true' : 'false'}
            />
          </span>
        ))}
      </WrapperStatistics>
      <WrapperStatistics>
        <ClientModeDataTable
          rows={reformatedAuditLogs}
          columns={AUDIT_LOGS_CLIENT_MODE_TABLE}
          title="Last 100 Audit Logs"
          width="66%"
          height="435px"
          handleReload={onReload}
        />
        <VerticalOrHorizontalBar
          title="Tickets"
          data={data}
          width="25vw"
          height="435px"
          onClickAdd={() =>
            window.open('https://home.connectwise.com/', '_blank')
          }
          layout="horizontal"
          barDataKey="amount"
          xAxisDataKey="completedAt"
          removeXAxisLines={true}
          removeYAxisLines={true}
        />
      </WrapperStatistics>
      {selectedElement === 'Assignments' && (
        <ClientModeDataTable
          rows={reformatedAssignments}
          columns={TEAM_MEMBERS_TABLE}
          title={selectedElement}
          showPictures={true}
        />
      )}

      {selectedElement === 'Client Contacts' && (
        <ClientModeDataTable
          rows={reformatedClientContacts}
          columns={TEAM_MEMBERS_TABLE}
          title={selectedElement}
          showPictures={true}
        />
      )}

      {selectedElement === 'Connected Services' && (
        <ClientModeDataTable
          rows={reformatedServices}
          columns={CLIENT_INTEGRATED_SERVICES_TABLE}
          title={selectedElement}
          showPictures={true}
          onClick={handleSelectService}
        />
      )}
    </WrapperClientDashboard>
  );
};

export default ClientDashboardView;
