import React, { useEffect } from 'react';
import { ClientModeDataTable, FlatButton } from 'ui';
import {
  CustomMenuItem,
  CustomTextField,
  MenuItemBox,
  MenuItemText,
  Wrapper,
} from './SaaSAlertsAccountManagement.styled';
import {
  SAAS_ALERTS_CLIENT_VIEW_ACCOUNT_MANAGEMENT_TABLE,
  SAAS_ALERTS_MSP_VIEW_ACCOUNT_MANAGEMENT_TABLE,
} from 'constants/tablesHead';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';
import {
  getSaasAlertsEventRefs,
  getSaasAlertsUsers,
  getToolsSelectedOrgId,
} from 'reduxStore/tools/toolsSelectors';
import { Field, Form, Formik } from 'formik';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { IReference } from 'types';
import { ISaaSAlertsUsersBody } from 'types/saasAlerts.type';
import {
  retrieveSaaSAlertsEventRefs,
  retrieveSaaSAlertsUsers,
} from 'reduxStore/tools/toolsOperations';
import { EXTENDED_DATE_RANGE } from 'constants/filterArrays';
import { resetSaaSAlertsUsers } from 'reduxStore/tools/toolsSlice';

interface IAccountManagementProps {
  org_id: number | null;
  last_activity: number;
}

const SaaSAlertsAccountManagement = () => {
  const dispatch = useAppDispatch();

  const mainOrgId = useAppSelector(getMainOrganizationID);
  const selectedOrgId = useAppSelector(getToolsSelectedOrgId);
  const saasAlertsUsers = useAppSelector(getSaasAlertsUsers);
  const eventRefs = useAppSelector(getSaasAlertsEventRefs);

  useEffect(() => {
    dispatch(resetSaaSAlertsUsers());
    dispatch(
      retrieveSaaSAlertsEventRefs({
        client_id: mainOrgId === selectedOrgId ? null : selectedOrgId,
      })
    );
  }, [dispatch, mainOrgId, selectedOrgId]);

  const reformatedUsers = Array.isArray(saasAlertsUsers)
    ? saasAlertsUsers.map(user => {
        return {
          organization: user.client.name,
          product: user.app?.name ?? 'No Product',
          last_activity: user.last_event_stamp
            ? `${user.last_event_stamp?.split(' ')[0]} - ${user.last_event_stamp?.split(' ')[1]}`
            : 'Never',
          account: `${user.name ?? 'No Name'} - ${user.email}`,
          billable: user.is_billable
            ? 'true'
            : user.is_billable === false
              ? 'false'
              : '-',
          licensed: user.is_licensed
            ? 'true'
            : user.is_licensed === false
              ? 'false'
              : '-',
          id: user.id + user.client.id,
        };
      })
    : [];

  const initialValues: IAccountManagementProps = {
    org_id: 0,
    last_activity: 0,
  };

  const handleSubmit = async (value: IAccountManagementProps) => {
    const end_date = new Date();
    const start_date = new Date();

    start_date.setDate(end_date.getDate() - value.last_activity);

    const body: ISaaSAlertsUsersBody = {
      client_id: value.org_id
        ? value.org_id
        : mainOrgId === selectedOrgId
          ? null
          : selectedOrgId,
      last_event:
        value.last_activity === 999
          ? undefined
          : {
              is_inside: false,
              from:
                value.last_activity === 0
                  ? null
                  : start_date.toISOString().split('T')[0],
              to:
                value.last_activity === 0
                  ? null
                  : end_date.toISOString().split('T')[0],
            },
    };

    try {
      await dispatch(retrieveSaaSAlertsUsers(body)).unwrap();
    } catch (error) {
      return;
    }
  };

  return (
    <Wrapper>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ setFieldValue }) => (
          <Form>
            <Grid container display="flex" flexDirection="row" gap="20px">
              {mainOrgId === selectedOrgId && (
                <Grid item xs={3}>
                  <Field name="org_id" id="org_id">
                    {({ field }: any) => (
                      <Autocomplete
                        {...field}
                        id="org_id"
                        options={eventRefs?.clients ?? []}
                        value={
                          eventRefs?.clients.find(
                            client => client.id === field.value
                          ) || null
                        }
                        getOptionLabel={(client: IReference) =>
                          client.name || ''
                        }
                        renderOption={(props, client: IReference) => (
                          <MenuItemBox
                            {...(props as React.HTMLAttributes<HTMLLIElement>)}
                            key={props.key}
                          >
                            {client.name}
                          </MenuItemBox>
                        )}
                        onChange={(
                          event: React.ChangeEvent<{}>,
                          newValue: IReference | null
                        ) => setFieldValue('org_id', newValue?.id)}
                        renderInput={(
                          params: AutocompleteRenderInputParams
                        ) => (
                          <CustomTextField
                            {...params}
                            label="Choose Organization"
                            placeholder="Organizations"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{
                              htmlFor: 'org_id',
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    )}
                  </Field>
                </Grid>
              )}
              <Grid item xs={3}>
                <Field
                  name="last_activity"
                  as={TextField}
                  id="last_activity"
                  label="Last Activity"
                  variant="outlined"
                  select
                  fullWidth
                  InputLabelProps={{ htmlFor: 'last_activity', shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      height: '40px',
                    },
                  }}
                >
                  {EXTENDED_DATE_RANGE.map((dateRange, index) => (
                    <MenuItem
                      key={index}
                      value={dateRange.dayDifference}
                      style={CustomMenuItem}
                      onClick={() =>
                        setFieldValue('last_activity', dateRange.dayDifference)
                      }
                    >
                      <MenuItemText>{dateRange.name}</MenuItemText>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={2}>
                <FlatButton
                  width="100%"
                  type="submit"
                  variant="contained"
                  height="40px"
                >
                  List Accounts
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <ClientModeDataTable
        rows={reformatedUsers}
        columns={
          mainOrgId === selectedOrgId
            ? SAAS_ALERTS_MSP_VIEW_ACCOUNT_MANAGEMENT_TABLE
            : SAAS_ALERTS_CLIENT_VIEW_ACCOUNT_MANAGEMENT_TABLE
        }
        nowRowsText="No Rows. For them to appear please select filters and press List Accounts"
      />
    </Wrapper>
  );
};

export default SaaSAlertsAccountManagement;
