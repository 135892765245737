import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { retrieveMSPContacts } from 'reduxStore/createProfile/createProfileOperations';
import { getMSPContacts } from 'reduxStore/createProfile/createProfileSelectors';
import { ROUTES } from 'routes/routes.const';
import { ButtonsContainer } from 'views';
import {
  SubTitle,
  Title,
  WrapperTeamMembersConfig,
} from './TeamMembersConfig.styled';
import { Box } from '@mui/material';
import { ElementBox } from 'ui';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import { retrieveAccountInfo } from 'reduxStore/organization/organizationOperations';
import {
  IUpdateMSPContactBody,
  IDeleteContactBody,
  IReceiveContactBody,
} from 'types/api.type';
import { setTeamMemberToUpdate } from 'reduxStore/organization/organizationSlice';

const TeamMembersConfig = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const teamMembers = useAppSelector(getMSPContacts);

  useEffect(() => {
    dispatch(retrieveAccountInfo(null));
    dispatch(retrieveMSPContacts(null));

    const handleModalClose = () => {
      window.location.reload();
    };

    window.addEventListener('modalClose', handleModalClose);

    return () => {
      window.removeEventListener('modalClose', handleModalClose);
    };
  }, [dispatch]);

  const AddTeamMember = () => {
    dispatch(
      setModalProps({
        radius: '18px',
      })
    );
    dispatch(openModal());
    dispatch(setContent('AddTeamMemberModal'));
  };

  const DeleteTeamMember = (teamMemberToDelete: IReceiveContactBody) => {
    const body: IDeleteContactBody = {
      id: teamMemberToDelete.id,
      is_total: true,
    };

    dispatch(
      setModalProps({
        radius: '18px',
        title: 'team member',
        apiValue: body,
        anyIsStatement: 'true',
        additionalText: `${teamMemberToDelete.email} has been successfully deleted`,
      })
    );
    dispatch(openModal());
    dispatch(setContent('DeleteConfirmation'));
  };

  const handleUpdateTeamMember = (teamMember: IReceiveContactBody) => {
    const teamMemberToUpdate: IUpdateMSPContactBody = {
      first_name: teamMember.first_name ?? '',
      last_name: teamMember.last_name ?? '',
      email: teamMember.email,
      phone: teamMember.phone ?? null,
      id: teamMember.id,
      role_id: teamMember.role?.id ?? 0,
      icon: teamMember.icon,
    };
    dispatch(setTeamMemberToUpdate(teamMemberToUpdate));
    dispatch(
      setModalProps({
        radius: '18px',
      })
    );
    dispatch(openModal());
    dispatch(setContent('AddTeamMemberModal'));
  };

  const handleSubmit = () => {
    navigate(ROUTES.CLIENTS_CONFIG);
  };

  const handleBack = () => {
    navigate(ROUTES.ORGANIZATION_PROFILE);
  };

  return (
    <WrapperTeamMembersConfig>
      <Box display="flex" flexDirection="column" gap="35px">
        <Box display="flex" flexDirection="column" gap="5px">
          <Title>Add Team Members</Title>
          <SubTitle>Add your Team members' details to the dashboard</SubTitle>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
          padding="0px 2.5%"
        >
          <ElementBox
            width="100%"
            height="44px"
            title="Add Team Member"
            functionality="Add"
            onClickFunctionality={AddTeamMember}
          />
          {teamMembers?.map((teamMember, index) => (
            <ElementBox
              key={index}
              width="100%"
              height="44px"
              title={`${teamMember.first_name || 'Missing'} ${teamMember.last_name || 'Missing'} - ${teamMember.role?.name}`}
              functionality="Remove"
              onClickFunctionality={() => DeleteTeamMember(teamMember)}
              additionalFunctionality="Edit"
              onClickAdditionalFunctionality={() =>
                handleUpdateTeamMember(teamMember)
              }
            />
          ))}
        </Box>
      </Box>

      <ButtonsContainer
        titleButton="Next"
        onClickBack={handleBack}
        onClick={handleSubmit}
      />
    </WrapperTeamMembersConfig>
  );
};

export default TeamMembersConfig;
