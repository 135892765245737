import { useAppDispatch } from 'hooks';
import { closeModal } from 'reduxStore/modal/modalSlice';
import {
  Buttons,
  MainText,
  WrapperServicesPromoteModal,
} from './ServicesPromoteModal.styled';
import { FlatButton } from 'ui';
import { ROUTES } from 'routes/routes.const';
import { useNavigate } from 'react-router';

interface IServicesPromoteModalProps {
  text?: string;
  serviceId?: number;
  canBeConnected?: 'true';
}

const ServicesPromoteModal = ({
  text,
  serviceId,
  canBeConnected,
}: IServicesPromoteModalProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleAdditionalAction = () => {
    navigate(ROUTES.ADD_SERVICE, { state: { toolId: serviceId } });
    dispatch(closeModal());
  };

  const handleLeave = () => {
    dispatch(closeModal());
  };

  return (
    <WrapperServicesPromoteModal>
      <MainText style={{ whiteSpace: 'pre-wrap' }}>{text}</MainText>
      {canBeConnected === 'true' ? (
        <Buttons>
          <FlatButton
            onClick={handleAdditionalAction}
            variant="outlined"
            width="223px"
            height="59px"
            borderradius="12px"
          >
            Connect
          </FlatButton>

          <FlatButton
            onClick={handleLeave}
            variant="contained"
            width="223px"
            height="59px"
            borderradius="12px"
          >
            Close
          </FlatButton>
        </Buttons>
      ) : (
        <FlatButton
          onClick={handleLeave}
          variant="contained"
          width="223px"
          height="59px"
          borderradius="12px"
        >
          Close
        </FlatButton>
      )}
    </WrapperServicesPromoteModal>
  );
};

export default ServicesPromoteModal;
