import React, { useState } from 'react';
import {
  StyledActionBlock,
  StyledTableContainer,
  Title,
  StyledWrapperTitle,
  DataTable,
} from './ServerModeDataTable.styled';
import { PositiveNegativeNeutralButton, RoundButton, SelectActions } from 'ui';
import { ITableRow } from 'types';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useAppDispatch } from 'hooks';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import {
  resetDeleteData,
  setDeleteDataType,
  setDeleteList,
} from 'reduxStore/organization/organizationSlice';
import {
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import { Box, Tooltip } from '@mui/material';

interface IServerModeDataTableProps {
  height?: string;
  width?: string;
  title?: string;
  addAction?: 'true';
  isCheckbox?: boolean;
  isWarning?: boolean;
  showPictures?: boolean;
  disableColumnMenus?: boolean;
  nowRowsText?: string;
  addCreateTicket?: 'true';

  rows: ITableRow[];
  columns: GridColDef[];
  rowCount: number;
  loading: boolean;
  page: number;
  pageSize: number;
  pageSizeOptions?: number[];

  onClick?: (item: ITableRow) => void;
  //   enabledSwitchFlag?: (row: ITableRow) => boolean;
  //   onClickShowMore?: () => void;
  //   onChangeSwitch?: (item: IUserType) => void;
  onEdit?: (item: ITableRow) => void;
  onPagePropsChange?: (page: number, pageSize: number) => void; // LATER USE ONLY THIS AND REMOVE onPageChange AND onPageSizeChange
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onSortChange?: (sortModel: GridSortModel) => void;

  //Do not support connection to selected elements for custom handleAdd or handleDelete
  handleAdd?: () => void;
  handleDelete?: () => void;
  handleReload?: () => void;
}

//Used to show huge amounts of data
const ServerModeDataTable = ({
  height,
  width,
  title,
  addAction,
  isCheckbox,
  isWarning,
  showPictures,
  disableColumnMenus,
  nowRowsText,
  addCreateTicket,
  onClick,
  onEdit,
  handleAdd,
  handleDelete,
  handleReload,
  onPagePropsChange,
  onPageChange,
  onPageSizeChange,
  onSortChange,
  rows,
  rowCount,
  loading,
  page,
  pageSize,
  pageSizeOptions,
  columns,
}: IServerModeDataTableProps) => {
  const dispatch = useAppDispatch();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [checkboxStatus, setCheckboxStatus] = useState(isCheckbox);
  const [selectedAction, setSelectedAction] = useState<string>('');

  const getUniqueValues = (field: string) => {
    return Array.from(new Set(rows.map(row => row[field]).filter(Boolean)));
  };

  const handlePaginationChange = (params: GridPaginationModel) => {
    if (onPagePropsChange) {
      onPagePropsChange(params.page, params.pageSize);
    } else {
      onPageChange(params.page);
      onPageSizeChange(params.pageSize);
    }
  };

  const handleSortChange = (newSortModel: GridSortModel) => {
    onSortChange && onSortChange(newSortModel);
  };

  const necessaryColumns =
    checkboxStatus || !showPictures
      ? columns.filter(
          column => column.field !== 'id' && column.field !== 'icon'
        )
      : columns.filter(column => column.field !== 'id');

  const reformatedNecessaryColumns: GridColDef[] = necessaryColumns.map(
    element => {
      return {
        ...element,
        type: 'singleSelect',
        valueOptions: getUniqueValues(element.field),
        flex: element.field === 'icon' ? 0.25 : 1,
        hideable: false,
        headerName: element.field === 'icon' ? '' : element.headerName,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu:
          element.field === 'icon' || disableColumnMenus ? true : false,
        renderCell: params =>
          params.field === 'icon' ? (
            params.value ? (
              <img
                src={params.value as string}
                style={{ width: '24px', height: '24px', borderRadius: '50%' }}
                alt=""
              />
            ) : (
              <AccountCircleOutlinedIcon sx={{ fontSize: '24px' }} />
            )
          ) : (
            // Default rendering for not 'icon' fields
            <Tooltip title={params.value || ''} placement="left" arrow>
              <span>{params.value}</span>
            </Tooltip>
          ),
      };
    }
  );

  if (onEdit)
    reformatedNecessaryColumns.push({
      headerName: 'Edit',
      field: 'edit',
      flex: 1,
      hideable: false,
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: true,
      renderCell: params => <EditNoteOutlinedIcon sx={{ fontSize: '24px' }} />,
    });

  if (addCreateTicket)
    reformatedNecessaryColumns.push({
      headerName: 'Create Ticket',
      field: 'create_ticket',
      flex: 1,
      hideable: false,
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: true,
      renderCell: params => (
        <ConfirmationNumberOutlinedIcon sx={{ fontSize: '24px' }} />
      ),
    });

  const onChange = (action: string) => {
    if (action === 'Add') {
      handleAdd && handleAdd();
      return;
    }
    setSelectedAction(action);
    setCheckboxStatus(action === 'Delete' && !handleDelete && true);
  };

  const onCancel = () => {
    setSelectedAction('');
    setCheckboxStatus(isCheckbox);
    dispatch(resetDeleteData());
  };

  const onDelete = () => {
    if (handleDelete) {
      handleDelete();
      return;
    }

    dispatch(setDeleteList(selectedRows));
    dispatch(
      setDeleteDataType(
        title === 'Clients' || title === 'Team Members' ? title : null
      )
    );
    dispatch(
      setModalProps({
        radius: '23px',
        title: `Are you sure you want to delete following ${title?.toLowerCase()}:`,
      })
    );
    dispatch(openModal());
    dispatch(setContent('MassDeletionConfirm'));
    setSelectedAction('');
    setCheckboxStatus(isCheckbox);
  };

  const handleCheckboxClick = (newSelection: GridRowSelectionModel) => {
    setSelectedRows(newSelection as number[]);
  };

  const handleClickRow: GridEventListener<'rowClick'> = params => {
    !checkboxStatus && onClick?.(params.row);
  };

  const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
    if (params.field === 'edit' && !checkboxStatus) {
      event.stopPropagation();
      onEdit?.(params.row);
    } else if (params.field === 'create_ticket' && !checkboxStatus) {
      event.stopPropagation();
      window.open('https://home.connectwise.com/', '_blank');
    }
  };

  return (
    <StyledTableContainer
      iswarning={isWarning ? 'true' : undefined}
      height={height}
      width={width}
    >
      {(addAction || title || handleReload) && (
        <StyledWrapperTitle>
          <Title>{title}</Title>
          {addAction ? (
            <StyledActionBlock>
              {selectedAction !== '' && (
                <PositiveNegativeNeutralButton
                  onClick={onCancel}
                  width="87px"
                  height="27px"
                  borderradius="23px"
                >
                  Cancel
                </PositiveNegativeNeutralButton>
              )}
              <SelectActions
                name="Edit"
                onAdd={handleAdd}
                onDelete={onDelete}
                actionState={selectedAction}
                onChange={onChange}
              />
            </StyledActionBlock>
          ) : handleReload ? (
            <StyledActionBlock>
              <RoundButton
                width="24px"
                height="24px"
                type="submit"
                variant="outlined"
                onClick={handleReload}
              >
                <AutorenewOutlinedIcon fontSize="medium" />
              </RoundButton>
            </StyledActionBlock>
          ) : (
            <></>
          )}
        </StyledWrapperTitle>
      )}

      {/* TODO: Fix the error about whether DataGrid's paginationMode is client or server */}
      <Box
        sx={{
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <DataTable
          rows={rows}
          columns={reformatedNecessaryColumns}
          localeText={{ noRowsLabel: nowRowsText ?? 'No Rows' }}
          pagination
          paginationMode="server"
          sortingMode="server"
          rowCount={rowCount}
          initialState={{
            pagination: {
              paginationModel: {
                page: page,
                pageSize: pageSize,
              },
            },
          }}
          loading={loading} // Show loader while fetching data
          onPaginationModelChange={handlePaginationChange}
          onSortModelChange={handleSortChange}
          pageSizeOptions={pageSizeOptions ?? [5, 10, 25, 50, 100]}
          checkboxSelection={checkboxStatus}
          density="standard"
          disableColumnResize
          onCellClick={handleCellClick}
          disableRowSelectionOnClick={!checkboxStatus && !onClick}
          onRowClick={handleClickRow}
          onRowSelectionModelChange={handleCheckboxClick}
        />
      </Box>
    </StyledTableContainer>
  );
};

export default ServerModeDataTable;
