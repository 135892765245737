import {
  IConnectWiseComputer,
  IConnectWiseDeviceCategory,
} from 'types/connectWise.type';
import {
  CustomMenuItem,
  CustomTextField,
  MenuItemBox,
  MenuItemText,
  Wrapper,
} from './ConnectWiseComputers.styled';
import {
  Cloud,
  Dns,
  LaptopMac,
  LaptopWindows,
  Storage,
} from '@mui/icons-material';
import { DeviceCategory } from '../components';
import {
  CONNECT_WISE_CLIENT_VIEW_COMPUTERS_TABLE,
  CONNECT_WISE_MSP_VIEW_COMPUTERS_TABLE,
} from 'constants/tablesHead';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';
import { getMSPClients } from 'reduxStore/createProfile/createProfileSelectors';
import { getToolsSelectedOrgId } from 'reduxStore/tools/toolsSelectors';
import { ClientModeDataTable, FlatButton } from 'ui';
import React, { useEffect, useState } from 'react';
import { retrieveMSPClients } from 'reduxStore/createProfile/createProfileOperations';
import { CONNECT_WISE_COMPUTER_TYPES, OSES } from 'constants/filterArrays';
import { Field, Form, Formik } from 'formik';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { IClientStatisticBody } from 'types';
import { generateConnectWiseComputers } from 'utils/mockDataGenerators';

interface IConnectWiseComputersProps {
  organization: string;
  type: string;
  os: string;
}
const ConnectWiseComputers = () => {
  const dispatch = useAppDispatch();

  const mainOrgId = useAppSelector(getMainOrganizationID);
  const selectedOrgId = useAppSelector(getToolsSelectedOrgId);
  const clients = useAppSelector(getMSPClients);

  useEffect(() => {
    dispatch(retrieveMSPClients(null));
  }, [dispatch, mainOrgId]);

  const [computers, setComputers] = useState<IConnectWiseComputer[]>([]);

  const reformatedComputers = computers.map(computer => {
    return {
      id: computer.id,
      organization: computer.organization,
      name: computer.name,
      last_log_in: computer.last_log_in,
      type: computer.type,
      last_user: computer.last_user,
      os: computer.os,
    };
  });

  const amountOfComputersByOS = (os: string): number => {
    return computers.filter(computer => computer.os === os).length;
  };

  const computersStatistics: IConnectWiseDeviceCategory[] = [
    {
      icon: <LaptopWindows fontSize="large" />,
      label: OSES[0],
      count: amountOfComputersByOS(OSES[0]),
    },
    {
      icon: <LaptopMac fontSize="large" />,
      label: OSES[1],
      count: amountOfComputersByOS(OSES[1]),
    },
    {
      icon: <Storage fontSize="large" />,
      label: OSES[2],
      count: amountOfComputersByOS(OSES[2]),
    },
    {
      icon: <Dns fontSize="large" />,
      label: OSES[3],
      count: amountOfComputersByOS(OSES[3]),
    },
    {
      icon: <Cloud fontSize="large" />,
      label: OSES[4],
      count: amountOfComputersByOS(OSES[4]),
    },
  ];

  const initialValues: IConnectWiseComputersProps = {
    organization: '',
    os: 'all',
    type: 'all',
  };

  const handleSubmit = (value: IConnectWiseComputersProps) => {
    const amount =
      (value.organization !== '' && value.organization !== null) ||
      value.type !== 'all' ||
      value.os !== 'all'
        ? 67
        : 134;

    setComputers(
      generateConnectWiseComputers(
        amount,
        value.organization ? value.organization : undefined,
        value.os !== 'all' ? value.os : undefined,
        value.type !== 'all' ? value.type : undefined,
        clients.map(client => {
          return client.name;
        })
      )
    );
  };

  return (
    <Wrapper>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ setFieldValue }) => (
          <Form>
            <Grid container display="flex" flexDirection="row" gap="20px">
              {mainOrgId === selectedOrgId && (
                <Grid item xs={3}>
                  <Field name="organization" id="organization">
                    {({ field }: any) => (
                      <Autocomplete
                        {...field}
                        id="organization"
                        options={clients ?? []}
                        value={
                          clients.find(client => client.name === field.value) ||
                          null
                        }
                        getOptionLabel={(client: IClientStatisticBody) =>
                          client.name
                        }
                        renderOption={(props, client: IClientStatisticBody) => (
                          <MenuItemBox
                            {...(props as React.HTMLAttributes<HTMLLIElement>)}
                            key={props.key}
                          >
                            {client.name}
                          </MenuItemBox>
                        )}
                        onChange={(
                          event: React.ChangeEvent<{}>,
                          newValue: IClientStatisticBody | null
                        ) =>
                          setFieldValue('organization', newValue?.name ?? null)
                        }
                        renderInput={(
                          params: AutocompleteRenderInputParams
                        ) => (
                          <CustomTextField
                            {...params}
                            label="Choose Organization"
                            placeholder="Organizations"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{
                              htmlFor: 'organization',
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    )}
                  </Field>
                </Grid>
              )}
              <Grid item xs={3}>
                <Field
                  name="type"
                  as={TextField}
                  id="type"
                  label="Type"
                  variant="outlined"
                  select
                  fullWidth
                  InputLabelProps={{ htmlFor: 'type', shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      height: '40px',
                    },
                  }}
                >
                  <MenuItem key={`t`} value={'all'} style={CustomMenuItem}>
                    <MenuItemText>All Possible Types</MenuItemText>
                  </MenuItem>
                  {CONNECT_WISE_COMPUTER_TYPES.map((type, index) => (
                    <MenuItem
                      key={index}
                      value={type}
                      style={CustomMenuItem}
                      onClick={() => setFieldValue('type', type)}
                    >
                      <MenuItemText>{type}</MenuItemText>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="os"
                  as={TextField}
                  id="os"
                  label="OS"
                  variant="outlined"
                  select
                  fullWidth
                  InputLabelProps={{ htmlFor: 'os', shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      height: '40px',
                    },
                  }}
                >
                  <MenuItem key={`os`} value={'all'} style={CustomMenuItem}>
                    <MenuItemText>All Possible OS</MenuItemText>
                  </MenuItem>
                  {OSES.map((os, index) => (
                    <MenuItem
                      key={index}
                      value={os}
                      style={CustomMenuItem}
                      onClick={() => setFieldValue('os', os)}
                    >
                      <MenuItemText>{os}</MenuItemText>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={2}>
                <FlatButton
                  width="100%"
                  type="submit"
                  variant="contained"
                  height="40px"
                >
                  List Computers
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <DeviceCategory
        title="Workstations and Servers"
        elements={computersStatistics}
      />
      <ClientModeDataTable
        rows={reformatedComputers}
        columns={
          mainOrgId === selectedOrgId
            ? CONNECT_WISE_MSP_VIEW_COMPUTERS_TABLE
            : CONNECT_WISE_CLIENT_VIEW_COMPUTERS_TABLE
        }
        nowRowsText="No Rows. For them to appear please select filters and press List Computers"
      />
    </Wrapper>
  );
};

export default ConnectWiseComputers;
