import {
  IConnectWiseDeviceCategory,
  IConnectWiseNetworkDevice,
} from 'types/connectWise.type';
import {
  CustomMenuItem,
  CustomTextField,
  MenuItemBox,
  MenuItemText,
  Wrapper,
} from './ConnectWiseNetworkDevices.styled';
import RouterIcon from '@mui/icons-material/Router';
import DvrIcon from '@mui/icons-material/Dvr';
import SecurityIcon from '@mui/icons-material/Security';
import LanIcon from '@mui/icons-material/Lan';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { DeviceCategory } from '../components';
import { retrieveMSPClients } from 'reduxStore/createProfile/createProfileOperations';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';
import { getToolsSelectedOrgId } from 'reduxStore/tools/toolsSelectors';
import { getMSPClients } from 'reduxStore/createProfile/createProfileSelectors';
import {
  AVAILABILITY_STATUSES,
  CONNECT_WISE_NETWORK_DEVICES_TYPES,
  VENDORS,
} from 'constants/filterArrays';
import { ClientModeDataTable, FlatButton } from 'ui';
import {
  CONNECT_WISE_CLIENT_VIEW_NETWORK_DEVICES_TABLE,
  CONNECT_WISE_MSP_VIEW_NETWORK_DEVICES_TABLE,
} from 'constants/tablesHead';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { IClientStatisticBody } from 'types';
import { generateConnectWiseNetworkDevices } from 'utils/mockDataGenerators';

interface IConnectWiseNetworkDevicesProps {
  organization: string;
  type: string;
  status: string;
  vendor: string;
}

const ConnectWiseNetworkDevices = () => {
  const dispatch = useAppDispatch();

  const mainOrgId = useAppSelector(getMainOrganizationID);
  const selectedOrgId = useAppSelector(getToolsSelectedOrgId);
  const clients = useAppSelector(getMSPClients);

  useEffect(() => {
    dispatch(retrieveMSPClients(null));
  }, [dispatch, mainOrgId]);

  const [networkDevices, setNetworkDevices] = useState<
    IConnectWiseNetworkDevice[]
  >([]);

  const reformatedNetworkDevices = networkDevices.map(networkDevice => {
    return {
      id: networkDevice.id,
      organization: networkDevice.organization,
      name: networkDevice.name,
      type: networkDevice.type,
      status: networkDevice.status,
      ip: networkDevice.ip,
      vendor: networkDevice.vendor,
    };
  });

  const amountOfDevicesByType = (type: string): number => {
    return networkDevices.filter(device => device.type === type).length;
  };

  const networkDevicesStatistics: IConnectWiseDeviceCategory[] = [
    {
      icon: <RouterIcon fontSize="large" />,
      label: CONNECT_WISE_NETWORK_DEVICES_TYPES[0],
      count: amountOfDevicesByType(CONNECT_WISE_NETWORK_DEVICES_TYPES[0]),
    },
    {
      icon: <DvrIcon fontSize="large" />,
      label: CONNECT_WISE_NETWORK_DEVICES_TYPES[1],
      count: amountOfDevicesByType(CONNECT_WISE_NETWORK_DEVICES_TYPES[1]),
    },
    {
      icon: <SecurityIcon fontSize="large" />,
      label: CONNECT_WISE_NETWORK_DEVICES_TYPES[2],
      count: amountOfDevicesByType(CONNECT_WISE_NETWORK_DEVICES_TYPES[2]),
    },
    {
      icon: <LanIcon fontSize="large" />,
      label: CONNECT_WISE_NETWORK_DEVICES_TYPES[3],
      count: amountOfDevicesByType(CONNECT_WISE_NETWORK_DEVICES_TYPES[3]),
    },
    {
      icon: <MoreHorizOutlinedIcon fontSize="large" />,
      label: CONNECT_WISE_NETWORK_DEVICES_TYPES[4],
      count: amountOfDevicesByType(CONNECT_WISE_NETWORK_DEVICES_TYPES[4]),
    },
  ];

  const initialValues: IConnectWiseNetworkDevicesProps = {
    organization: '',
    type: 'all',
    status: 'all',
    vendor: 'all',
  };

  const handleSubmit = (value: IConnectWiseNetworkDevicesProps) => {
    const amount =
      (value.organization !== '' && value.organization !== null) ||
      value.type !== 'all' ||
      value.status !== 'all' ||
      value.vendor !== 'all'
        ? 67
        : 134;

    setNetworkDevices(
      generateConnectWiseNetworkDevices(
        amount,
        value.organization ? value.organization : undefined,
        value.vendor !== 'all' ? value.vendor : undefined,
        value.type !== 'all' ? value.type : undefined,
        value.status !== 'all' ? value.status : undefined,
        clients.map(client => {
          return client.name;
        })
      )
    );
  };

  return (
    <Wrapper>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ setFieldValue }) => (
          <Form>
            <Grid container display="flex" flexDirection="row" gap="20px">
              {mainOrgId === selectedOrgId && (
                <Grid item xs={2}>
                  <Field name="organization" id="organization">
                    {({ field }: any) => (
                      <Autocomplete
                        {...field}
                        id="organization"
                        options={clients ?? []}
                        value={
                          clients.find(client => client.name === field.value) ||
                          null
                        }
                        getOptionLabel={(client: IClientStatisticBody) =>
                          client.name
                        }
                        renderOption={(props, client: IClientStatisticBody) => (
                          <MenuItemBox
                            {...(props as React.HTMLAttributes<HTMLLIElement>)}
                            key={props.key}
                          >
                            {client.name}
                          </MenuItemBox>
                        )}
                        onChange={(
                          event: React.ChangeEvent<{}>,
                          newValue: IClientStatisticBody | null
                        ) =>
                          setFieldValue('organization', newValue?.name ?? null)
                        }
                        renderInput={(
                          params: AutocompleteRenderInputParams
                        ) => (
                          <CustomTextField
                            {...params}
                            label="Choose Organization"
                            placeholder="Organizations"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{
                              htmlFor: 'organization',
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    )}
                  </Field>
                </Grid>
              )}
              <Grid item xs={mainOrgId === selectedOrgId ? 2 : 3}>
                <Field
                  name="type"
                  as={TextField}
                  id="type"
                  label="Type"
                  variant="outlined"
                  select
                  fullWidth
                  InputLabelProps={{ htmlFor: 'type', shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      height: '40px',
                    },
                  }}
                >
                  <MenuItem key={`t`} value={'all'} style={CustomMenuItem}>
                    <MenuItemText>All Possible Types</MenuItemText>
                  </MenuItem>
                  {CONNECT_WISE_NETWORK_DEVICES_TYPES.map((type, index) => (
                    <MenuItem
                      key={index}
                      value={type}
                      style={CustomMenuItem}
                      onClick={() => setFieldValue('type', type)}
                    >
                      <MenuItemText>{type}</MenuItemText>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={mainOrgId === selectedOrgId ? 2 : 3}>
                <Field
                  name="status"
                  as={TextField}
                  id="status"
                  label="Status"
                  variant="outlined"
                  select
                  fullWidth
                  InputLabelProps={{ htmlFor: 'status', shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      height: '40px',
                    },
                  }}
                >
                  <MenuItem key={`st`} value={'all'} style={CustomMenuItem}>
                    <MenuItemText>All Possible Statuses</MenuItemText>
                  </MenuItem>
                  {AVAILABILITY_STATUSES.map((status, index) => (
                    <MenuItem
                      key={index}
                      value={status}
                      style={CustomMenuItem}
                      onClick={() => setFieldValue('status', status)}
                    >
                      <MenuItemText>{status}</MenuItemText>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={mainOrgId === selectedOrgId ? 2 : 3}>
                <Field
                  name="vendor"
                  as={TextField}
                  id="vendor"
                  label="Vendor"
                  variant="outlined"
                  select
                  fullWidth
                  InputLabelProps={{ htmlFor: 'vendor', shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      height: '40px',
                    },
                  }}
                >
                  <MenuItem key={`v`} value={'all'} style={CustomMenuItem}>
                    <MenuItemText>All Possible Vendors</MenuItemText>
                  </MenuItem>
                  {VENDORS.map((vendor, index) => (
                    <MenuItem
                      key={index}
                      value={vendor}
                      style={CustomMenuItem}
                      onClick={() => setFieldValue('vendor', vendor)}
                    >
                      <MenuItemText>{vendor}</MenuItemText>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={2}>
                <FlatButton
                  width="100%"
                  type="submit"
                  variant="contained"
                  height="40px"
                >
                  List Devices
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <DeviceCategory
        title="Network Devices"
        elements={networkDevicesStatistics}
      />
      <ClientModeDataTable
        rows={reformatedNetworkDevices}
        columns={
          mainOrgId === selectedOrgId
            ? CONNECT_WISE_MSP_VIEW_NETWORK_DEVICES_TABLE
            : CONNECT_WISE_CLIENT_VIEW_NETWORK_DEVICES_TABLE
        }
        nowRowsText="No Rows. For them to appear please select filters and press List Devices"
      />
    </Wrapper>
  );
};

export default ConnectWiseNetworkDevices;
