import { Stack } from '@mui/material';
import { Outlet } from 'react-router';
import { TextTabs } from 'ui';
import { ROUTES } from 'routes/routes.const';
import {
  CustomDivider,
  ConnectWiseContainer,
} from './ConnectWiseLayoutPage.styled';
import { useAppDispatch } from 'hooks';
import { useEffect } from 'react';
import { retrieveSaaSAlertsAllSeverityLevels } from 'reduxStore/tools/toolsOperations';

const ConnectWiseLayoutPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(retrieveSaaSAlertsAllSeverityLevels(null));
  }, [dispatch]);

  const navOptions = [
    {
      title: 'Dashboard',
      url: ROUTES.CONNECT_WISE_DASHBOARD,
    },
    {
      title: 'Devices',
      url: ROUTES.CONNECT_WISE_DEVICES,
    },
    {
      title: 'Alerts',
      url: ROUTES.CONNECT_WISE_ALERTS,
    },
    {
      title: 'Users',
      url: ROUTES.CONNECT_WISE_USERS,
    },
  ];

  return (
    <Stack direction="row" width="100%">
      <ConnectWiseContainer>
        <TextTabs
          height="20px"
          //width="100%"
          options={navOptions}
          hierarchy="parent"
        ></TextTabs>
        <CustomDivider />
        <Outlet />
      </ConnectWiseContainer>
    </Stack>
  );
};

export default ConnectWiseLayoutPage;
