import { useAppDispatch, useAppSelector } from 'hooks';
import { getAssignableRoles } from 'reduxStore/createProfile/createProfileSelectors';
import {
  MenuItemBox,
  MenuItemText,
  Title,
  Unassign,
  WrapperAssignmentConfig,
} from './AssignmentConfig.styled';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import {
  retrieveAssignments,
  updateAssignment,
} from 'reduxStore/assignments/assignmentsOperations';
import { IUpdateAssignmentBody } from 'types/assignments.type';
import { getSelectedClient } from 'reduxStore/organization/organizationSelectors';
import { Field } from 'formik';
import { BlockedInput } from 'ui/inputs';

interface IAssignmentConfigProps {
  contactEmail: string;
  width?: string;
  height?: string;
  updateElementId?: number;
  roleId?: number;
  blockRole?: 'true';
  onDelete?: () => void;
}

// THIS ELEMENT NEEDS assignable_roles, BUT ADDING dispatch(retrieveAssignableRoles(null)); HERE
// IS TOO MEMORY CONSUMING, SO USE dispatch(retrieveAssignableRoles(null)); WHEREVER AssignmentConfig IS USED
const AssignmentConfig = ({
  contactEmail,
  width,
  height,
  updateElementId,
  roleId,
  blockRole,
  onDelete,
}: IAssignmentConfigProps) => {
  const dispatch = useAppDispatch();

  const assignableRoles = useAppSelector(getAssignableRoles);
  const clientInfo = useAppSelector(getSelectedClient);

  const handleChange = async (event: SelectChangeEvent<number>) => {
    const body: IUpdateAssignmentBody = {
      id: updateElementId || 0,
      contact_id: null, // need further investigation
      role_id: event.target.value as number,
    };

    try {
      await dispatch(updateAssignment(body)).unwrap();
      dispatch(retrieveAssignments(clientInfo?.id || 0));
    } catch (error) {
      return;
    }
  };

  return (
    <WrapperAssignmentConfig width={width} height={height}>
      <Title>{contactEmail}</Title>
      {roleId && (
        <FormControl fullWidth sx={{ width: '35%' }}>
          {blockRole ? (
            <Field
              component={BlockedInput}
              value={assignableRoles.find(role => role.id === roleId)?.name}
              isBlocked="true"
              blockedText="You can't change your role in clients."
              height="40px"
              borderRadius="4px"
            />
          ) : (
            <Select
              value={roleId}
              onChange={handleChange}
              sx={{ height: '40px' }}
            >
              <MenuItem value={0} style={{ display: 'none' }}>
                <MenuItemText>Select Role</MenuItemText>
              </MenuItem>
              {assignableRoles.map((role, index) => (
                <MenuItem key={index} value={role.id} style={MenuItemBox}>
                  <MenuItemText>{role.name}</MenuItemText>
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      )}

      <Unassign underline="none" onClick={onDelete}>
        Delete Assignment
      </Unassign>
    </WrapperAssignmentConfig>
  );
};

export default AssignmentConfig;
