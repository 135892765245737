import React from 'react';
import {
  WrapperDeleteIntegrationModal,
  Title,
  MenuItemText,
  MenuItemBox,
  Error,
} from './DeleteIntegrationModal.styled';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Grid, MenuItem, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import {
  retrieveClientsRef,
  retrieveToolsRef,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getClientsRef,
  getCreateProfileError,
  getToolsRef,
} from 'reduxStore/createProfile/createProfileSelectors';
import { FlatButton } from 'ui';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import {
  getMainOrganizationID,
  getMSPInformation,
} from 'reduxStore/organization/organizationSelectors';
import { IDeleteIntgrnBody } from 'types/api.type';

interface IDeleteIntegrationForm {
  tool_id: number;
  client_id: number;
  is_total: number;
}

const validationSchema = Yup.object({
  tool_id: Yup.string()
    .required('Tool is required')
    .test('not-select', 'Tool is required', value => value !== '0'),
});

const DeleteIntegrationModal = () => {
  const dispatch = useAppDispatch();
  const error = useAppSelector(getCreateProfileError);
  const toolsAsRefs = useAppSelector(getToolsRef);
  const clientsAsRefs = useAppSelector(getClientsRef);
  const organizationId = useAppSelector(getMainOrganizationID);
  const msp = useAppSelector(getMSPInformation);

  useEffect(() => {
    dispatch(retrieveToolsRef(null));
    dispatch(retrieveClientsRef(null));
  }, [dispatch]);

  const handleSubmit = (value: IDeleteIntegrationForm) => {
    const body: IDeleteIntgrnBody = {
      tool_id: value.tool_id,
      org_id: value.client_id !== 0 ? value.client_id : organizationId,
      is_total: value.is_total === 0 ? false : true,
    };
    dispatch(
      setModalProps({
        radius: '18px',
        title: 'integration',
        apiValue: body,
        anyIsStatement: 'true',
        additionalText: `integration between ${value.client_id !== 0 ? clientsAsRefs.find(client => client.id === value.client_id)?.name : msp?.name} and ${toolsAsRefs.find(tool => tool.id === value.tool_id)?.name} was successfully deleted`,
      })
    );
    dispatch(openModal());
    dispatch(setContent('DeleteConfirmation'));
  };

  const initialValues: IDeleteIntegrationForm = {
    tool_id: 0,
    client_id: 0,
    is_total: 0,
  };

  return (
    <WrapperDeleteIntegrationModal>
      <Title>Delete Integration</Title>
      {!!error && <Error>{error}</Error>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Field
                  name="tool_id"
                  as={TextField}
                  id="tool_id"
                  label="Select Service"
                  variant="outlined"
                  select
                  fullWidth
                  error={touched.tool_id && Boolean(errors.tool_id)}
                  helperText={touched.tool_id && errors.tool_id}
                  InputLabelProps={{ htmlFor: 'tool_id', shrink: true }}
                >
                  <MenuItem value={0} style={{ display: 'none' }}>
                    <MenuItemText>Select Service</MenuItemText>
                  </MenuItem>
                  {Array.isArray(toolsAsRefs) &&
                    toolsAsRefs.map((tool, index) => (
                      <MenuItem key={index} value={tool.id} style={MenuItemBox}>
                        <MenuItemText>{tool.name}</MenuItemText>
                      </MenuItem>
                    ))}
                </Field>
              </Grid>
              <Grid item xs={8}>
                <Field
                  name="client_id"
                  as={TextField}
                  id="client_id"
                  label="Select Client"
                  variant="outlined"
                  select
                  fullWidth
                  InputLabelProps={{ htmlFor: 'client_id', shrink: true }}
                >
                  <MenuItem value={0} style={MenuItemBox}>
                    <MenuItemText>No Client</MenuItemText>
                  </MenuItem>
                  {Array.isArray(clientsAsRefs) &&
                    clientsAsRefs.map((client, index) => (
                      <MenuItem
                        key={index}
                        value={client.id}
                        style={MenuItemBox}
                      >
                        <MenuItemText>{client.name}</MenuItemText>
                      </MenuItem>
                    ))}
                </Field>
              </Grid>
              <Grid item xs={8}>
                <Field
                  name="is_total"
                  as={TextField}
                  id="is_total"
                  label="Is It Forced?"
                  variant="outlined"
                  select
                  fullWidth
                  InputLabelProps={{ htmlFor: 'is_total', shrink: true }}
                >
                  <MenuItem value={0} style={MenuItemBox}>
                    <MenuItemText>No</MenuItemText>
                  </MenuItem>
                  <MenuItem value={1} style={MenuItemBox}>
                    <MenuItemText>Yes</MenuItemText>
                  </MenuItem>
                </Field>
              </Grid>
              <Grid item xs={8}>
                <FlatButton width="150px" type="submit" variant="contained">
                  Delete Integration
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </WrapperDeleteIntegrationModal>
  );
};

export default DeleteIntegrationModal;
