import { useAppDispatch, useAppSelector } from 'hooks';
import {
  closeModal,
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import { MainText, WrapperDeletion } from './DeleteConfirmation.styled';
import { Box } from '@mui/material';
import { FlatButton } from 'ui';
import { ROUTES } from 'routes/routes.const';
import {
  removeClient,
  removeMSPContact,
  deleteIntegration,
  retrieveTools,
} from 'reduxStore/createProfile/createProfileOperations';
import { useNavigate } from 'react-router';
import {
  removeClientContact,
  retrieveClientContacts,
} from 'reduxStore/assignments/assignmentsOperations';
import { getSelectedClient } from 'reduxStore/organization/organizationSelectors';

interface IDeleteConfirmation {
  successTitle?: string;
  deletionType?: string;
  apiValue: any;
  doesNotNeedNavigation?: 'true';
}

const DeleteConfirmation = ({
  successTitle,
  deletionType,
  apiValue,
  doesNotNeedNavigation,
}: IDeleteConfirmation) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const clientInfo = useAppSelector(getSelectedClient);

  const handleAPICall = async () => {
    const newApiValue = { ...apiValue, is_total: true };
    try {
      switch (deletionType) {
        case 'client':
          await dispatch(removeClient(apiValue)).unwrap();
          break;

        case 'integration':
          await dispatch(deleteIntegration(newApiValue)).unwrap();
          dispatch(retrieveTools(null));
          break;

        case 'team member':
          await dispatch(removeMSPContact(apiValue)).unwrap();
          break;
        case 'client contact':
          await dispatch(removeClientContact(apiValue)).unwrap();
          dispatch(retrieveClientContacts(clientInfo?.id ?? 0));
      }
      dispatch(
        setModalProps({
          radius: '18px',
          title: successTitle,
        })
      );
      dispatch(openModal());
      dispatch(setContent('ActionSuccess'));
      if (doesNotNeedNavigation !== 'true' && deletionType !== 'client contact')
        navigate(
          deletionType === 'client'
            ? ROUTES.CLIENTS
            : deletionType === 'integration'
              ? ROUTES.SERVICES
              : deletionType === 'team member'
                ? ROUTES.TEAM_MEMBERS
                : ROUTES.DASHBOARD
        );
    } catch (error) {
      dispatch(closeModal());
      return;
    }
  };

  const handleCancelDelete = () => {
    dispatch(closeModal());
  };

  return (
    <WrapperDeletion>
      <Box width="500px" height="96px">
        <MainText>
          {deletionType === 'integration'
            ? 'Disconnecting MSP from service will automatically disconnect all clients too. Proceed?'
            : `Are you sure you want to delete this ${deletionType}?`}
        </MainText>
      </Box>
      <Box
        width="500px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <FlatButton
          onClick={handleAPICall}
          variant="contained"
          width="134px"
          height="50px"
          isAPI="true"
        >
          YES
        </FlatButton>
        <FlatButton
          onClick={handleCancelDelete}
          variant="outlined"
          width="134px"
          height="50px"
        >
          NO
        </FlatButton>
      </Box>
    </WrapperDeletion>
  );
};

export default DeleteConfirmation;
