import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { ISTOK_WEB } from 'constants/fonts';

export const WrapperClientDashboard = styled(Box)<BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '50px',
}));

export const WrapperStatistics = styled(Box)<BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  //for later enhancements
  //gap: '20px',
  //flexWrap: 'wrap',
}));

export const WrapperErrors = styled(Box)<BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const Error = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.error.main,
  fontFamily: ISTOK_WEB,
  fontSize: '16px',
  lineHeight: '150%',
  fontWeight: '400',
  letterSpacing: '-0.352px',
}));
