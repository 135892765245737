import { RootState } from 'reduxStore/store';

//TODO: Revise selectors and delete unused

const getIsCreatingOrganization = (state: RootState) =>
  state.createProfile.creatingOrganization;

// import users
const getUsersListImported = (state: RootState) =>
  state.createProfile.usersListImported;

const getCreateProfileError = (state: RootState) => state.createProfile.error;

const getClouds = (state: RootState) => state.createProfile.clouds;

const getMSPRoles = (state: RootState) => state.createProfile.mspRoles;

const getAssignableRoles = (state: RootState) =>
  state.createProfile.assignableRoles;

const getMSPContacts = (state: RootState) => state.createProfile.mspContacts;

const getAssignableContacts = (state: RootState) =>
  state.createProfile.assignableContacts;

const getMSPClients = (state: RootState) => state.createProfile.mspClients;

const getConnectedToServiceClients = (state: RootState) =>
  state.createProfile.connectedToServiceClients;

const getClientsRef = (state: RootState) => state.createProfile.clientsAsRefs;

const getTools = (state: RootState) => state.createProfile.tools;

const getToolsRef = (state: RootState) => state.createProfile.toolsAsRefs;

const getToolOrgs = (state: RootState) => state.createProfile.toolOrgs;

export {
  getIsCreatingOrganization,
  getUsersListImported,
  getCreateProfileError,
  getClouds,
  getMSPRoles,
  getAssignableRoles,
  getMSPContacts,
  getAssignableContacts,
  getMSPClients,
  getConnectedToServiceClients,
  getClientsRef,
  getTools,
  getToolsRef,
  getToolOrgs,
};
