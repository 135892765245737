import {
  LogoBox,
  WrapperHeader,
  WrapperOrgSelectionElements,
  WrapperIcons,
  CustomTextField,
} from './HeaderWithOrgSelection.styled';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ActionsDropdown from 'ui/ActionsDropdown/ActionsDropdown';
import { IconButton, PositiveNegativeNeutralButton } from 'ui';
import { resetAuth } from 'reduxStore/auth/authSlice';
import { resetOrganization } from 'reduxStore/organization/organizationSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  getAdminInfo,
  getSelectedClient,
} from 'reduxStore/organization/organizationSelectors';
import { getIsAuth } from 'reduxStore/auth/authSelectors';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { IActionsProps } from 'types/selectItem.type';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import React, { useEffect } from 'react';
import { getMSPClients } from 'reduxStore/createProfile/createProfileSelectors';
import { Autocomplete, FormControl } from '@mui/material';
import {
  retrieveAccountInfo,
  retrieveClientOrganization,
} from 'reduxStore/organization/organizationOperations';
import { retrieveMSPClients } from 'reduxStore/createProfile/createProfileOperations';
import { IClientStatisticBody } from 'types';

const HeaderWithOrgSelection = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isAuth = useAppSelector(getIsAuth);
  const admin = useAppSelector(getAdminInfo);
  const clients = useAppSelector(getMSPClients);
  const clientInfo = useAppSelector(getSelectedClient);

  useEffect(() => {
    dispatch(retrieveMSPClients(null));
  }, [dispatch]);

  const handleMSPViewClick = () => {
    //dispatch(resetSelectedClient());
    navigate(ROUTES.DASHBOARD);
  };

  // Handles "Single Customer" button click (auto-selects first customer + opens dropdown)
  const handleSingleClientClick = async () => {
    if (!clientInfo?.id) {
      try {
        await dispatch(retrieveClientOrganization(clients[0].id)).unwrap();
        navigate(ROUTES.CLIENT_DASHBOARD);
      } catch (error) {
        return;
      }
    }
  };

  // Handles client selection from the dropdown
  const handleSelectClient = async (
    _event: any,
    newValue: IClientStatisticBody | null
  ) => {
    if (newValue !== null) {
      try {
        await dispatch(retrieveClientOrganization(newValue.id)).unwrap();
        navigate(ROUTES.CLIENT_DASHBOARD);
      } catch (error) {
        return;
      }
    }
    //TODO: MIGHT NEED TO ADD THIS, CONSIDERING
    // else {
    //   navigate(ROUTES.DASHBOARD);
    // }
  };

  const handleLogout = () => {
    if (isAuth) {
      dispatch(resetAuth());
      dispatch(resetOrganization());
    }
  };

  const actions: IActionsProps[] = [
    {
      icon: <SettingsOutlinedIcon fontSize="small" />,
      title: 'Settings',
      onClick: async () => {
        await dispatch(retrieveAccountInfo(null)).unwrap();
        navigate(ROUTES.ACCOUNT_SETTINGS);
      },
    },
    {
      icon: <LogoutIcon fontSize="small" />,
      title: 'Logout',
      onClick: handleLogout,
    },
  ];

  return (
    <WrapperHeader>
      <WrapperOrgSelectionElements>
        <PositiveNegativeNeutralButton
          onClick={handleMSPViewClick}
          borderradius="4px"
          state={clientInfo?.id ? undefined : 'Positive'}
          width="200px"
          height="38px"
        >
          MSP View
        </PositiveNegativeNeutralButton>
        <PositiveNegativeNeutralButton
          onClick={handleSingleClientClick}
          borderradius="4px"
          state={clientInfo?.id ? 'Positive' : undefined}
          width="200px"
          height="38px"
        >
          Select Client
        </PositiveNegativeNeutralButton>
        {clientInfo?.id && (
          <FormControl sx={{ width: '30%' }}>
            <Autocomplete
              options={clients}
              value={
                clients.find(client => client.id === clientInfo.id) || null
              }
              getOptionLabel={(client: IClientStatisticBody) =>
                client.name || ''
              }
              onChange={handleSelectClient}
              renderInput={params => (
                <CustomTextField {...params} variant="outlined" fullWidth />
              )}
            />
          </FormControl>
        )}
      </WrapperOrgSelectionElements>
      <WrapperIcons>
        <IconButton>
          <NotificationsNoneOutlinedIcon fontSize="medium" />
        </IconButton>
        <ActionsDropdown actions={actions}>
          {admin?.icon ? (
            <LogoBox src={admin.icon} />
          ) : (
            <AccountCircleOutlinedIcon fontSize="medium" />
          )}
        </ActionsDropdown>
      </WrapperIcons>
    </WrapperHeader>
  );
};

export default HeaderWithOrgSelection;
