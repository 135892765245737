import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { INTER, ISTOK_WEB } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

interface ILogoProps extends BoxProps {
  src: string;
}

export const WrapperUpdateClient = styled(Box)<BoxProps>(() => ({
  background: COLORS.white,
  padding: '30px',
  borderRadius: '12px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '50px',
}));

export const WrapperErrors = styled(Box)<BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const WrapperHeader = styled(Box)<BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const Title = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '36px',
  fontWeight: 600,
  lineHeight: '48px',
  letterSpacing: '-0.701px',
}));

export const SubTitle = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  paddingLeft: '30px',
  fontFamily: INTER,
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '36px',
  letterSpacing: '-0.308px',
}));

export const Error = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.error.main,
  fontFamily: ISTOK_WEB,
  fontSize: '16px',
  lineHeight: '150%',
  fontWeight: '400',
  letterSpacing: '-0.352px',
}));

export const MenuItemText = styled(Typography)(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '21px',
  letterSpacing: '-0.308px',
}));

export const MenuItemBox = {
  display: 'flex',
  justifyContent: 'center',
};

export const WrapperElements = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  width: '100%',
  maxHeight: '57.5vh',
  overflowY: 'auto',
  flexDirection: 'column',
  gap: '2.3vh',
}));

export const WrapperSection = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const WrapperServiceInfo = styled(Button)<ButtonProps>(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '59px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px',
  borderRadius: '16px',
  border: `1px solid ${theme.palette.secondary.light}`,
  background: 'transparent',
  color: COLORS.black,
  paddingLeft: '30px',
  fontFamily: INTER,
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '28px',
  letterSpacing: '-0.308px',
  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
    background: 'transparent',
  },
}));

export const LogoBox = styled(Box)<ILogoProps>(({ src }) => ({
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '52px',
  height: '52px',
  borderRadius: '50%',
}));
