import { Wrapper } from './TeamMembersPage.styled';
import { Outlet } from 'react-router';
import { HeaderWithSearch } from 'ui';

const TeamMembersPage = () => {
  return (
    <Wrapper>
      <HeaderWithSearch />
      <Outlet />
    </Wrapper>
  );
};

export default TeamMembersPage;
