import { FormControl, MenuItem, Select } from '@mui/material';
import {
  BodyText,
  MenuItemBox,
  MenuItemText,
  Title,
  Wrapper,
  WrapperHeader,
} from './SaaSAlertsTickets.styled';
import { useEffect, useState } from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';
import { DATE_RANGE } from 'constants/filterArrays';
import { useAppDispatch, useAppSelector } from 'hooks';
import { retrieveSaaSAlertsTicketStats } from 'reduxStore/tools/toolsOperations';
import { ISaaSAlertsTicketsBody } from 'types/saasAlerts.type';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';
import {
  getSaaSAlertsTicketStatistics,
  getToolsSelectedOrgId,
} from 'reduxStore/tools/toolsSelectors';

const SaaSAlertsTickets = () => {
  const dispatch = useAppDispatch();

  const mainOrgId = useAppSelector(getMainOrganizationID);
  const selectedOrgId = useAppSelector(getToolsSelectedOrgId);
  const ticketStatistics = useAppSelector(getSaaSAlertsTicketStatistics);

  const [dateDifference, setDateDifference] = useState(0);

  useEffect(() => {
    const end_date = new Date();
    const start_date = new Date();

    start_date.setDate(end_date.getDate() - dateDifference);

    const body: ISaaSAlertsTicketsBody = {
      client_id: mainOrgId === selectedOrgId ? null : selectedOrgId,
      from: start_date.toISOString().split('T')[0],
      to: end_date.toISOString().split('T')[0],
    };

    dispatch(retrieveSaaSAlertsTicketStats(body));
  }, [dateDifference, dispatch, mainOrgId, selectedOrgId]);

  const data = ticketStatistics.map(stat => {
    return {
      name: stat.client.name,
      value: stat.count,
      color: `#${Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, '0')}`,
    };
  });
  const handleDateRangeChange = async (dateDifference: number) => {
    const end_date = new Date();
    const start_date = new Date();

    start_date.setDate(end_date.getDate() - dateDifference);

    const body: ISaaSAlertsTicketsBody = {
      client_id: mainOrgId === selectedOrgId ? null : selectedOrgId,
      from: start_date.toISOString().split('T')[0],
      to: end_date.toISOString().split('T')[0],
    };
    try {
      await dispatch(retrieveSaaSAlertsTicketStats(body)).unwrap();
      setDateDifference(dateDifference);
    } catch (error) {
      return;
    }
  };

  return (
    <Wrapper>
      <WrapperHeader>
        <Title>Organizations with the most Tickets Created</Title>
        <FormControl fullWidth sx={{ width: '300px' }}>
          <Select value={dateDifference} sx={{ height: '40px' }}>
            {DATE_RANGE.map(dateRange => (
              <MenuItem
                key={dateRange.id}
                value={dateRange.dayDifference}
                style={MenuItemBox}
                onClick={() => handleDateRangeChange(dateRange.dayDifference)}
              >
                <MenuItemText>{dateRange.name}</MenuItemText>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </WrapperHeader>
      {data.length > 0 ? (
        <PieChart width={800} height={400}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={80}
            outerRadius={120}
            fill="#8884d8"
            paddingAngle={3}
            dataKey="value"
            label={({ name, value }) => `${name} (${value})`}
          >
            {data.map((org, index) => (
              <Cell key={`cell-${index}`} fill={org.color} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      ) : (
        <BodyText>No Tickets Occurred In This Date Range</BodyText>
      )}
    </Wrapper>
  );
};

export default SaaSAlertsTickets;
