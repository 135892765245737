import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'services/api/tools';
import {
  IHuntressIncidentsBody,
  IHuntressReportsBody,
} from 'types/huntress.type';
import {
  ISaaSAlertsDetailedLocationsBody,
  ISaaSAlertsEventReferencesBody,
  ISaaSAlertsEventsBody,
  ISaaSAlertsTicketsBody,
  ISaaSAlertsUsersBody,
} from 'types/saasAlerts.type';

const retrieveHuntressGeneralStatistics = createAsyncThunk(
  'toolHuntress/retrieveHuntressGeneralStatistics',
  async (orgId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveHuntressGeneralStatistics(orgId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving Huntress statistics error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveHuntressAgents = createAsyncThunk(
  'toolHuntress/retrieveHuntressAgents',
  async (orgId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveHuntressAgents(orgId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving Huntress agents error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveHuntressIncidentsRefs = createAsyncThunk(
  'toolHuntress/retrieveHuntressIncidentsRef',
  async (orgId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveHuntressIncidentsRefs(orgId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving Huntress incident refs error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveHuntressIncidents = createAsyncThunk(
  'toolHuntress/retrieveHuntressIncidents',
  async (body: IHuntressIncidentsBody, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveHuntressIncidents(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving Huntress incidents error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveHuntressReportsRefs = createAsyncThunk(
  'toolHuntress/retrieveHuntressReportsRefs',
  async (orgId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveHuntressReportsRefs(orgId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving Huntress report refs error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveHuntressReports = createAsyncThunk(
  'toolHuntress/retrieveHuntressReports',
  async (body: IHuntressReportsBody, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveHuntressReports(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving Huntress reports error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveSaaSAlertsTicketStats = createAsyncThunk(
  'toolSaaSAlerts/retrieveSaaSAlertsTicketStats',
  async (body: ISaaSAlertsTicketsBody, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveSaaSAlertsTicketStats(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving SaaS Alerts tickets statistics error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveSaaSAlertsSeverityLevels = createAsyncThunk(
  'toolSaaSAlerts/retrieveSaaSAlertsSeverityLevels',
  async (def: null, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveSaaSAlertsSeverityLevels(def);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving SaaS Alerts severity levels error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveSaaSAlertsAllSeverityLevels = createAsyncThunk(
  'toolSaaSAlerts/retrieveSaaSAlertsAllSeverityLevels',
  async (def: null, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveSaaSAlertsAllSeverityLevels(def);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving SaaS Alerts All severity levels error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveSaaSAlertsEvents = createAsyncThunk(
  'toolSaaSAlerts/retrieveSaaSAlertsEvents',
  async (body: ISaaSAlertsEventsBody, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveSaaSAlertsEvents(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving SaaS Alerts events error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveSaaSAlertsEventRefs = createAsyncThunk(
  'toolSaaSAlerts/retrieveSaaSAlertsEventRefs',
  async (body: ISaaSAlertsEventReferencesBody, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveSaaSAlertsEventRefs(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving SaaS Alerts event refs error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveSaaSAlertsUsers = createAsyncThunk(
  'toolSaaSAlerts/retrieveSaaSAlertsUsers',
  async (body: ISaaSAlertsUsersBody, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveSaaSAlertsUsers(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving SaaS Alerts users error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveSaaSAlertsLocations = createAsyncThunk(
  'toolSaaSAlerts/retrieveSaaSAlertsLocations',
  async (body: ISaaSAlertsDetailedLocationsBody, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveSaaSAlertsLocations(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving SaaS Alerts locations error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export {
  retrieveHuntressGeneralStatistics,
  retrieveHuntressAgents,
  retrieveHuntressIncidentsRefs,
  retrieveHuntressIncidents,
  retrieveHuntressReportsRefs,
  retrieveHuntressReports,
  retrieveSaaSAlertsTicketStats,
  retrieveSaaSAlertsSeverityLevels,
  retrieveSaaSAlertsAllSeverityLevels,
  retrieveSaaSAlertsEvents,
  retrieveSaaSAlertsEventRefs,
  retrieveSaaSAlertsUsers,
  retrieveSaaSAlertsLocations,
};
