import { Button, ButtonProps, styled, Typography } from '@mui/material';

interface IProviderButtonProps extends ButtonProps {
  width?: string;
  height?: string;
}

export const ProviderButtonWrapper = styled(Button)<IProviderButtonProps>(
  ({ theme, width, height }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center',
    gap: '12px',
    width,
    height: height,
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.352px',
    borderRadius: '8px',
    border: '1px solid',
    background: theme.palette.common.white,
    '&:hover': {
      border: '1px solid',
      color: theme.palette.shades.dark,
      background: theme.palette.common.white,
    },
  })
);

export const ProviderName = styled(Typography)(({ theme }) => ({
  display: 'flex',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '-0.352px',
  color: theme.palette.shades.dark,
}));
