import { ClientModeDataTable } from 'ui';
import {
  MenuItemBox,
  MenuItemText,
  Title,
  Wrapper,
  WrapperHeader,
} from './SaaSAlertsDashboard.styled';
import {
  SAAS_ALERTS_CLIENT_VIEW_CONNECTION_POINTS_TABLE,
  SAAS_ALERTS_MSP_VIEW_CONNECTION_POINTS_TABLE,
} from 'constants/tablesHead';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  getSaasAlertsEventRefs,
  getSaasAlertsLocations,
  getToolsSelectedOrgId,
} from 'reduxStore/tools/toolsSelectors';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';
import { FormControl, MenuItem, Select } from '@mui/material';
import {
  retrieveSaaSAlertsEventRefs,
  retrieveSaaSAlertsLocations,
} from 'reduxStore/tools/toolsOperations';
import { ISaaSAlertsDetailedLocationsBody } from 'types/saasAlerts.type';

const SaaSAlertsDashboard = () => {
  const dispatch = useAppDispatch();
  const mainOrgId = useAppSelector(getMainOrganizationID);
  const selectedOrgId = useAppSelector(getToolsSelectedOrgId);
  const eventRefs = useAppSelector(getSaasAlertsEventRefs);
  const locations = useAppSelector(getSaasAlertsLocations);

  const [orgSelected, setOrgSelected] = useState(0);

  useEffect(() => {
    const body: ISaaSAlertsDetailedLocationsBody = {
      filter: {
        client_id:
          orgSelected > 0
            ? orgSelected
            : mainOrgId === selectedOrgId
              ? null
              : selectedOrgId,
      },
    };
    dispatch(
      retrieveSaaSAlertsEventRefs({
        client_id: mainOrgId === selectedOrgId ? null : selectedOrgId,
      })
    );
    dispatch(retrieveSaaSAlertsLocations(body));
  }, [dispatch, mainOrgId, orgSelected, selectedOrgId]);

  const reformatedLocations = locations.map(location => {
    return {
      id: location.id,
      organizations: location.events
        .map(event => {
          return event.client.name;
        })
        .join(', '),
      names: location.events
        .map(event => {
          return event.user?.name;
        })
        .join(', '),
      emails: location.events
        .map(event => {
          return event.user?.email;
        })
        .join(', '),
      last_time: `${location.events[location.events.length - 1].stamp.split(' ')[0]} - ${location.events[location.events.length - 1].stamp.split(' ')[1]}`,
      events: location.events_count,
      ip_location: `${location.country.name} - ${location.region ?? 'No Info'} - ${location.city ?? 'No Info'} - ${location.latitude} - ${location.longitude}`,
    };
  });
  return (
    <Wrapper>
      <WrapperHeader>
        <Title>Last known connection points</Title>
        {mainOrgId === selectedOrgId && (
          <FormControl fullWidth sx={{ width: '300px' }}>
            <Select value={orgSelected} sx={{ height: '40px' }}>
              <MenuItem
                value={0}
                style={MenuItemBox}
                onClick={() => setOrgSelected(0)}
                key={0}
              >
                <MenuItemText>All Organizations</MenuItemText>
              </MenuItem>
              {eventRefs?.clients.map(client => (
                <MenuItem
                  value={client.id}
                  style={MenuItemBox}
                  onClick={() => setOrgSelected(client.id)}
                  key={client.id}
                >
                  <MenuItemText>{client.name}</MenuItemText>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </WrapperHeader>
      <ClientModeDataTable
        rows={reformatedLocations}
        columns={
          mainOrgId === selectedOrgId && orgSelected === 0
            ? SAAS_ALERTS_MSP_VIEW_CONNECTION_POINTS_TABLE
            : SAAS_ALERTS_CLIENT_VIEW_CONNECTION_POINTS_TABLE
        }
      />
    </Wrapper>
  );
};

export default SaaSAlertsDashboard;
