import React, { useState } from 'react';
import {
  StyledActionBlock,
  StyledTableContainer,
  Title,
  StyledWrapperTitle,
  DataTable,
} from './ClientModeDataTable.styled';
import { PositiveNegativeNeutralButton, RoundButton, SelectActions } from 'ui';
import { ITableRow } from 'types';
import { IUserType } from 'types/organizations.type';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useAppDispatch } from 'hooks';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import {
  resetDeleteData,
  setDeleteDataType,
  setDeleteList,
} from 'reduxStore/organization/organizationSlice';
import {
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import { Tooltip } from '@mui/material';

interface IClientModeDataTableProps {
  height?: string;
  width?: string;
  title?: string;
  addAction?: 'true';
  isCheckbox?: boolean;
  isWarning?: boolean;
  showPictures?: boolean;
  disableColumnMenus?: boolean;
  nowRowsText?: string;
  addCreateTicket?: 'true';

  rows: ITableRow[];
  columns: GridColDef[];

  enabledSwitchFlag?: (item: ITableRow) => boolean;
  onClick?: (item: ITableRow) => void;
  onClickShowMore?: () => void;
  onChangeSwitch?: (item: IUserType) => void;
  onEdit?: (item: ITableRow) => void;

  //Do not support connection to selected elements for custom handleAdd or handleDelete
  handleAdd?: () => void;
  handleDelete?: () => void;
  handleReload?: () => void;
}

//Used to show small amounts of data. For huge amounts (100+) use ServerModeDataTable
const ClientModeDataTable = ({
  height,
  title,
  addAction,
  isCheckbox,
  isWarning,
  showPictures,
  disableColumnMenus,
  nowRowsText,
  width,
  rows,
  columns,
  addCreateTicket,
  onClick,
  onEdit,
  handleAdd,
  handleDelete,
  handleReload,
}: IClientModeDataTableProps) => {
  const dispatch = useAppDispatch();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [checkboxStatus, setCheckboxStatus] = useState(isCheckbox);
  const [selectedAction, setSelectedAction] = useState<string>('');

  const getUniqueValues = (field: string) => {
    return Array.from(new Set(rows.map(row => row[field]).filter(Boolean)));
  };

  const necessaryColumns =
    checkboxStatus || !showPictures
      ? columns.filter(
          column => column.field !== 'id' && column.field !== 'icon'
        )
      : columns.filter(column => column.field !== 'id');

  const reformatedNecessaryColumns: GridColDef[] = necessaryColumns.map(
    element => {
      return {
        ...element,
        type: 'singleSelect',
        valueOptions: getUniqueValues(element.field),
        flex: element.field === 'icon' ? 0.25 : 1,
        hideable: false,
        headerName: element.field === 'icon' ? '' : element.headerName,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu:
          element.field === 'icon' || disableColumnMenus ? true : false,
        renderCell: params =>
          params.field === 'icon' ? (
            params.value ? (
              <img
                src={params.value as string}
                style={{ width: '24px', height: '24px', borderRadius: '50%' }}
                alt=""
              />
            ) : (
              <AccountCircleOutlinedIcon sx={{ fontSize: '24px' }} />
            )
          ) : (
            // Default rendering for not 'icon' fields
            <Tooltip title={params.value || ''} placement="left" arrow>
              <span>{params.value}</span>
            </Tooltip>
          ),
      };
    }
  );

  if (onEdit)
    reformatedNecessaryColumns.push({
      headerName: 'Edit',
      field: 'edit',
      flex: 1,
      hideable: false,
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: true,
      renderCell: params => <EditNoteOutlinedIcon sx={{ fontSize: '24px' }} />,
    });

  if (addCreateTicket)
    reformatedNecessaryColumns.push({
      headerName: 'Create Ticket',
      field: 'create_ticket',
      flex: 1,
      hideable: false,
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: true,
      renderCell: params => (
        <ConfirmationNumberOutlinedIcon sx={{ fontSize: '24px' }} />
      ),
    });

  const onChange = (action: string) => {
    if (action === 'Add') {
      handleAdd && handleAdd();
      return;
    }
    setSelectedAction(action);
    setCheckboxStatus(action === 'Delete' && !handleDelete && true);
  };

  const onCancel = () => {
    setSelectedAction('');
    setCheckboxStatus(isCheckbox);
    dispatch(resetDeleteData());
  };

  const onDelete = () => {
    if (handleDelete) {
      handleDelete();
      return;
    }

    dispatch(setDeleteList(selectedRows));
    dispatch(
      setDeleteDataType(
        title === 'Clients' || title === 'Team Members' ? title : null
      )
    );
    dispatch(
      setModalProps({
        radius: '23px',
        title: `Are you sure you want to delete following ${title?.toLowerCase()}:`,
      })
    );
    dispatch(openModal());
    dispatch(setContent('MassDeletionConfirm'));
    setSelectedAction('');
    setCheckboxStatus(isCheckbox);
  };

  const handleCheckboxClick = (newSelection: GridRowSelectionModel) => {
    setSelectedRows(newSelection as number[]);
  };

  const handleClickRow: GridEventListener<'rowClick'> = params => {
    !checkboxStatus && onClick?.(params.row);
  };

  const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
    if (params.field === 'edit' && !checkboxStatus) {
      event.stopPropagation();
      onEdit?.(params.row);
    } else if (params.field === 'create_ticket' && !checkboxStatus) {
      event.stopPropagation();
      window.open('https://home.connectwise.com/', '_blank');
    }
  };

  return (
    <StyledTableContainer
      iswarning={isWarning ? 'true' : undefined}
      height={height}
      width={width}
    >
      {(addAction || title || handleReload) && (
        <StyledWrapperTitle>
          <Title>{title}</Title>
          {addAction ? (
            <StyledActionBlock>
              {selectedAction !== '' && (
                <PositiveNegativeNeutralButton
                  onClick={onCancel}
                  width="87px"
                  height="27px"
                  borderradius="23px"
                >
                  Cancel
                </PositiveNegativeNeutralButton>
              )}
              <SelectActions
                name="Edit"
                onAdd={handleAdd}
                onDelete={onDelete}
                actionState={selectedAction}
                onChange={onChange}
              />
            </StyledActionBlock>
          ) : handleReload ? (
            <StyledActionBlock>
              <RoundButton
                width="24px"
                height="24px"
                type="submit"
                variant="outlined"
                onClick={handleReload}
              >
                <AutorenewOutlinedIcon fontSize="medium" />
              </RoundButton>
            </StyledActionBlock>
          ) : (
            <></>
          )}
        </StyledWrapperTitle>
      )}

      {/* TODO: Fix the error about whether DataGrid's paginationMode is client or server */}
      <DataTable
        rows={rows}
        columns={reformatedNecessaryColumns}
        localeText={{ noRowsLabel: nowRowsText ?? 'No Rows' }}
        pagination
        paginationMode="client"
        initialState={{
          pagination: {
            paginationModel: {
              page: 0, // Start at page 0
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        checkboxSelection={checkboxStatus}
        density="standard"
        disableColumnResize
        onCellClick={handleCellClick}
        disableRowSelectionOnClick={!checkboxStatus && !onClick}
        onRowClick={handleClickRow}
        onRowSelectionModelChange={handleCheckboxClick}
      />
    </StyledTableContainer>
  );
};

export default ClientModeDataTable;
