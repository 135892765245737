import { useAppDispatch, useAppSelector } from 'hooks';
import {
  Title,
  WrapperUpdateClient,
  Error,
  MenuItemBox,
  MenuItemText,
  WrapperElements,
  SubTitle,
  WrapperSection,
  WrapperErrors,
  WrapperServiceInfo,
  LogoBox,
  WrapperHeader,
} from './UpdateClient.styled';
import { useNavigate } from 'react-router';
import countryList from 'react-select-country-list';
import {
  getAdminInfo,
  getClientIntegrations,
  getMainOrganization,
  getOrganizationError,
  getSelectedClient,
} from 'reduxStore/organization/organizationSelectors';
import { BYTES_IN_2MB } from 'constants/validation';
import { getBase64 } from 'utils';
import { useEffect, useState } from 'react';
import { IOrgProfileFormValues } from 'types';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import createValidationSchema from './validationSchema';
import {
  AssignmentConfig,
  FlatButton,
  Input,
  PositiveNegativeNeutralButton,
  RoundButton,
  SectionUploadSmall,
} from 'ui';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
} from '@mui/material';
import { IUpdateOrgBody } from 'types/api.type';
import {
  retrieveClientIntegrations,
  updateOrganization,
} from 'reduxStore/organization/organizationOperations';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import { ROUTES } from 'routes/routes.const';
import {
  removeAssignment,
  retrieveAssignments,
  retrieveClientContacts,
} from 'reduxStore/assignments/assignmentsOperations';
import {
  getAssignments,
  getAssignmentsError,
  getClientContacts,
} from 'reduxStore/assignments/assignmentsSelectors';
import AddIcon from '@mui/icons-material/Add';
import { retrieveAssignableRoles } from 'reduxStore/createProfile/createProfileOperations';
import {
  resetAssignmentsError,
  setClientContactToUpdate,
} from 'reduxStore/assignments/assignmentsSlice';
import { retrieveHuntressGeneralStatistics } from 'reduxStore/tools/toolsOperations';
import {
  resetToolsAllData,
  setToolsSelectedOrgId,
  setToolsSelectedOrgName,
  setToolsSelectedToolId,
} from 'reduxStore/tools/toolsSlice';
import { IAssignment, IClientContact } from 'types/assignments.type';
import { ClientContact } from './components';
import MuiPhoneNumber from 'material-ui-phone-number';

const UpdateClient = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const clientInfo = useAppSelector(getSelectedClient);
  const countryOptions = countryList().getData();
  const assignments = useAppSelector(getAssignments);
  const clientContacts = useAppSelector(getClientContacts);
  const clientIntegrations = useAppSelector(getClientIntegrations);
  const organizationError = useAppSelector(getOrganizationError);
  const assignmentsError = useAppSelector(getAssignmentsError);
  const mainOrg = useAppSelector(getMainOrganization);
  const admin = useAppSelector(getAdminInfo);

  useEffect(() => {
    dispatch(retrieveAssignments(clientInfo?.id ?? 0));
    dispatch(retrieveClientContacts(clientInfo?.id ?? 0));
    dispatch(retrieveClientIntegrations(clientInfo?.id ?? 0));
    dispatch(retrieveAssignableRoles(null));
  }, [clientInfo?.id, dispatch]);

  const [base64Image, setBase64Image] = useState(clientInfo?.icon || '');
  const [imageError, setImageError] = useState<string | null>(null);

  const handleDeleteImage = () => {
    setBase64Image('');
    setImageError(null);
  };

  const handleImageError = (error: string) => {
    setBase64Image('');
    setImageError(error);
    setTimeout(() => setImageError(null), 3000);
  };

  const handleUpload = async (image: File) => {
    if (!(image instanceof File)) {
      handleImageError('Invalid file type. Please upload a valid image file.');
      return;
    }
    setImageError(null);

    if (image.size > BYTES_IN_2MB) {
      handleImageError('Image size must be less than 2MB');
      return;
    }
    try {
      await getBase64(
        image,
        result => setBase64Image(result),
        () => handleImageError('Error uploading image. Please, try again')
      );
    } catch (err) {
      handleImageError('Error uploading image. Please, try again');
    }
  };

  const handleDeleteAssignment = async (assignment: IAssignment) => {
    try {
      await dispatch(removeAssignment(assignment.id)).unwrap();
      dispatch(
        setModalProps({
          radius: '18px',
          title: `${assignment.contact.email} \n is unassigned from ${clientInfo?.name}`,
        })
      );
      dispatch(openModal());
      dispatch(setContent('ActionSuccess'));
      dispatch(retrieveAssignments(clientInfo?.id ?? 0));
    } catch (error) {
      return;
    }
  };

  const handleCreateAssignment = () => {
    try {
      dispatch(resetAssignmentsError());
      dispatch(
        setModalProps({
          radius: '22px',
        })
      );
      dispatch(openModal());
      dispatch(setContent('CreateAssignment'));
    } catch (error) {
      return;
    }
  };

  const handleCreateOrUpdateClientContact = (
    clientContactToUpdate?: IClientContact
  ) => {
    try {
      dispatch(resetAssignmentsError());
      if (!clientContactToUpdate) {
        dispatch(
          setModalProps({
            radius: '22px',
          })
        );
        dispatch(openModal());
        dispatch(setContent('AddOrUpdateClientContactModal'));
      } else {
        dispatch(setClientContactToUpdate(clientContactToUpdate));
        dispatch(
          setModalProps({
            radius: '22px',
            title: clientContactToUpdate.email,
          })
        );
        dispatch(openModal());
        dispatch(setContent('AddOrUpdateClientContactModal'));
      }
    } catch (error) {
      return;
    }
  };

  const handleRemoveClientContact = (clientContact: IClientContact) => {
    dispatch(
      setModalProps({
        radius: '18px',
        title: 'client contact',
        apiValue: clientContact.id,
        anyIsStatement: 'true',
        additionalText: `${clientContact.email} has been successfully deleted `,
      })
    );
    dispatch(openModal());
    dispatch(setContent('DeleteConfirmation'));
  };

  const handleSelectService = async (toolName: string, toolId: number) => {
    try {
      if (toolName === 'Huntress') {
        await dispatch(
          retrieveHuntressGeneralStatistics(clientInfo?.id || 0)
        ).unwrap();
        dispatch(setToolsSelectedOrgName(clientInfo?.name || ''));
        dispatch(setToolsSelectedOrgId(clientInfo?.id || 0));
        dispatch(setToolsSelectedToolId(toolId));
        navigate(ROUTES.HUNTRESS_DASHBOARD);
      } else if (toolName === 'SaaS Alerts') {
        dispatch(setToolsSelectedOrgName(clientInfo?.name || ''));
        dispatch(setToolsSelectedOrgId(clientInfo?.id || 0));
        dispatch(setToolsSelectedToolId(toolId));
        navigate(ROUTES.SAAS_ALERTS_OVERVIEW_DASHBOARD);
      } else if (toolName === 'Connect Wise') {
        dispatch(setToolsSelectedOrgName(clientInfo?.name || ''));
        dispatch(setToolsSelectedOrgId(clientInfo?.id || 0));
        dispatch(setToolsSelectedToolId(toolId));
        navigate(ROUTES.CONNECT_WISE_DASHBOARD);
      }
    } catch (error) {
      return;
    }
  };

  const handleAddService = () => {
    dispatch(resetToolsAllData());
    navigate(ROUTES.ADD_SERVICE, {
      state: { intgrLevel: 2, clientId: clientInfo?.id },
    });
  };

  const handleDeleteTeamMember = () => {
    dispatch(
      setModalProps({
        radius: '18px',
        title: 'client',
        apiValue: clientInfo?.id,
        additionalText: `${clientInfo?.name} has been successfully deleted `,
      })
    );
    dispatch(openModal());
    dispatch(setContent('DeleteConfirmation'));
  };

  const handleSubmit = async (value: IOrgProfileFormValues) => {
    const body: IUpdateOrgBody = {
      id: clientInfo?.id ?? 0,
      name: value.name === clientInfo?.name ? null : value.name,
      address: {
        state: value.state,
        street: value.street,
        street2: value.street2,
        city: value.city,
        zip_code: value.zipCode,
        country_code: value.country_code,
      },
      phone:
        value.phone === '+' //TODO: need to find better way
          ? ''
          : value.phone === clientInfo?.phone
            ? null
            : value.phone,
      margin: clientInfo?.margin,
      icon: base64Image === clientInfo?.icon ? null : base64Image,
    };
    try {
      await dispatch(updateOrganization(body)).unwrap();
      navigate(ROUTES.CLIENTS);
      dispatch(
        setModalProps({
          radius: '18px',
          title: `${value.name} \n has been updated`,
        })
      );
      dispatch(openModal());
      dispatch(setContent('ActionSuccess'));
    } catch (error) {
      return;
    }
  };

  const initialValues: IOrgProfileFormValues = {
    admins: clientInfo?.admins ?? [],
    name: clientInfo?.name ?? '',
    street: clientInfo?.address.street ?? '',
    street2: clientInfo?.address.street2 ?? '',
    city: clientInfo?.address.city ?? '',
    state: clientInfo?.address.state ?? '',
    zipCode: clientInfo?.address.zip_code ?? '',
    country_code: clientInfo?.address.country_code ?? '',
    phone: clientInfo?.phone ?? null,
  };

  return (
    <WrapperUpdateClient>
      {(organizationError || assignmentsError) && (
        <WrapperErrors>
          {organizationError && <Error>{organizationError}</Error>}
          {assignmentsError && <Error>{assignmentsError}</Error>}
        </WrapperErrors>
      )}
      <WrapperHeader>
        <Title>Update Client Info</Title>
        {mainOrg?.role.id === 1 && (
          <PositiveNegativeNeutralButton
            state="Negative"
            onClick={handleDeleteTeamMember}
          >
            Delete Client
          </PositiveNegativeNeutralButton>
        )}
      </WrapperHeader>

      <Formik
        initialValues={initialValues}
        validationSchema={createValidationSchema()}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <Grid container flexDirection="column">
              <Grid container flexDirection="row" spacing="4vw">
                <Grid item xs={6}>
                  <Grid container flexDirection="column" spacing={2}>
                    <SubTitle>Client Info</SubTitle>
                    <Grid item xs={12}>
                      <Field
                        component={Input}
                        name="name"
                        label="Company Name"
                        helperText={<ErrorMessage name="name" />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container flexDirection="row" spacing="38px">
                        <Grid item xs={6}>
                          <Field
                            component={Input}
                            name="street"
                            label="Address 1"
                            helperText={<ErrorMessage name="street" />}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <Field
                            component={Input}
                            name="street2"
                            label="Address 2"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container flexDirection="row" spacing="38px">
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel shrink>Select Country</InputLabel>
                            <Field name="country_code">
                              {({ field }: FieldProps) => (
                                <TextField
                                  {...field}
                                  select
                                  label="Select Country"
                                  variant="outlined"
                                  fullWidth
                                  error={
                                    touched.country_code &&
                                    Boolean(errors.country_code)
                                  }
                                  helperText={
                                    touched.country_code && errors.country_code
                                  }
                                  InputLabelProps={{ shrink: true }}
                                  sx={{
                                    '& .MuiOutlinedInput-root': {
                                      borderRadius: '12px',
                                    },
                                  }}
                                >
                                  {Array.isArray(countryOptions) &&
                                    countryOptions.map(country => (
                                      <MenuItem
                                        key={country.value}
                                        value={country.value}
                                        style={MenuItemBox}
                                      >
                                        <MenuItemText>
                                          {country.label}
                                        </MenuItemText>
                                      </MenuItem>
                                    ))}
                                </TextField>
                              )}
                            </Field>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            component={Input}
                            name="city"
                            label="City"
                            helperText={<ErrorMessage name="city" />}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container flexDirection="row" spacing="38px">
                        <Grid item xs={6}>
                          <Field
                            component={Input}
                            name="state"
                            label="State"
                            helperText={<ErrorMessage name="state" />}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            component={Input}
                            name="zipCode"
                            label="Zip code"
                            helperText={<ErrorMessage name="zipCode" />}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <MuiPhoneNumber
                          defaultCountry={'us'}
                          name="phone"
                          value={values.phone}
                          label="Phone Number"
                          variant="outlined"
                          placeholder="Enter Phone Number"
                          onChange={value => setFieldValue('phone', value)}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '12px',
                              height: '59px',
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <SectionUploadSmall
                        handleUpload={handleUpload}
                        onDelete={handleDeleteImage}
                        imageUrl={base64Image}
                        error={imageError}
                        title="Update Company Logo"
                      />
                    </Grid>

                    <Grid item xs={6} marginTop="50px">
                      <FlatButton
                        width="250px"
                        type="submit"
                        variant="contained"
                        isIcon="true"
                      >
                        Update Client
                      </FlatButton>
                    </Grid>

                    <Grid item xs={12} marginTop={5}>
                      <Grid container flexDirection="column" spacing={2}>
                        <WrapperSection>
                          <SubTitle>{`Connected Services - ${clientIntegrations.length}`}</SubTitle>
                          <RoundButton
                            width="36px"
                            height="36px"
                            type="button"
                            variant="contained"
                            setExpansion={
                              clientIntegrations.length > 0 ? undefined : 'true'
                            }
                            needsExpansion={true}
                            expansionText="Connect Service"
                            onClick={handleAddService}
                          >
                            <AddIcon fontSize="medium" />
                          </RoundButton>
                        </WrapperSection>
                        <Grid item xs={12}>
                          <WrapperElements>
                            {clientIntegrations.map(
                              (clientIntegration, index) => (
                                <WrapperServiceInfo
                                  key={index}
                                  onClick={() =>
                                    handleSelectService(
                                      clientIntegration.tool.name,
                                      clientIntegration.tool.id
                                    )
                                  }
                                >
                                  <LogoBox
                                    src={clientIntegration.tool.icon ?? ''}
                                  />
                                  {`${clientIntegration.tool.name}: Alerts - 
                                  ${clientIntegration.tool.code !== 'connect-wise' ? clientIntegration.intgrn.alerts_count : 67}, 
                                  Devices - ${clientIntegration.tool.code !== 'connect-wise' ? clientIntegration.intgrn.devices_count : 67}`}
                                </WrapperServiceInfo>
                              )
                            )}
                          </WrapperElements>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container flexDirection="column" spacing={2}>
                    <WrapperSection>
                      <SubTitle>Role Assignments</SubTitle>
                      <RoundButton
                        width="36px"
                        height="36px"
                        type="button"
                        variant="contained"
                        setExpansion={
                          assignments.length > 0 ? undefined : 'true'
                        }
                        needsExpansion={true}
                        expansionText="Create Assignment"
                        onClick={handleCreateAssignment}
                      >
                        <AddIcon fontSize="medium" />
                      </RoundButton>
                    </WrapperSection>

                    <Grid item xs={12}>
                      <WrapperElements>
                        {Array.isArray(assignments) &&
                          assignments.map((assignment, index) => (
                            <AssignmentConfig
                              key={index}
                              contactEmail={assignment.contact.email}
                              updateElementId={assignment.id}
                              roleId={assignment.role.id}
                              height="59px"
                              onDelete={() =>
                                handleDeleteAssignment(assignment)
                              }
                              blockRole={
                                mainOrg?.role.id !== 1 &&
                                assignment.contact.email === admin?.email
                                  ? 'true'
                                  : undefined
                              }
                            />
                          ))}
                      </WrapperElements>
                    </Grid>
                    <WrapperSection marginTop={2}>
                      <SubTitle>Client Contacts</SubTitle>
                      <RoundButton
                        width="36px"
                        height="36px"
                        type="button"
                        variant="contained"
                        setExpansion={
                          clientContacts.length > 0 ? undefined : 'true'
                        }
                        needsExpansion={true}
                        expansionText="Create Client Contact"
                        onClick={handleCreateOrUpdateClientContact}
                      >
                        <AddIcon fontSize="medium" />
                      </RoundButton>
                    </WrapperSection>
                    <Grid item xs={12}>
                      <WrapperElements>
                        {Array.isArray(clientContacts) &&
                          clientContacts.map((clientContact, index) => (
                            <ClientContact
                              key={index}
                              title={clientContact.email}
                              onUpdate={() =>
                                handleCreateOrUpdateClientContact(clientContact)
                              }
                              onDelete={() =>
                                clientContacts.length > 1
                                  ? handleRemoveClientContact(clientContact)
                                  : {}
                              }
                            />
                          ))}
                      </WrapperElements>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </WrapperUpdateClient>
  );
};

export default UpdateClient;
