import { RoundButton } from 'ui/buttons';
import {
  Title,
  WrapperTop,
  WrapperVerticalOrHorizontalBar,
} from './VerticalOrHorizontalBar.styled';
import AddIcon from '@mui/icons-material/Add';
import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { theme } from 'theme';
import { INTER } from 'constants/fonts';

interface IVerticalOrHorizontalBarProps {
  title: string;
  data: any;
  width?: string;
  height?: string;
  layout: 'vertical' | 'horizontal';
  barDataKey: string;
  barSize?: number;
  xAxisDataKey?: string;
  yAxisDataKey?: string;
  onClickAdd?: () => void;
  removeXAxisLines: boolean;
  removeYAxisLines: boolean;
}

const VerticalOrHorizontalBar = ({
  title,
  data,
  width,
  height,
  layout,
  barDataKey,
  barSize,
  xAxisDataKey,
  yAxisDataKey,
  removeXAxisLines,
  removeYAxisLines,
  onClickAdd,
}: IVerticalOrHorizontalBarProps) => {
  return (
    <WrapperVerticalOrHorizontalBar width={width} height={height}>
      <WrapperTop>
        <Title>{title}</Title>
        {onClickAdd ? (
          <RoundButton
            width="52px"
            height="52px"
            type="submit"
            variant="contained"
            onClick={onClickAdd}
          >
            <AddIcon fontSize="medium" />
          </RoundButton>
        ) : (
          <></>
        )}
      </WrapperTop>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} layout={layout} margin={{ top: 10 }}>
          {xAxisDataKey && (
            <XAxis
              dataKey={xAxisDataKey}
              axisLine={!removeXAxisLines}
              tickLine={!removeXAxisLines}
              style={{
                fontFamily: INTER,
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '18px',
                letterSpacing: '-0.264px',
              }}
            />
          )}
          {yAxisDataKey && (
            <YAxis
              dataKey={yAxisDataKey}
              axisLine={!removeYAxisLines}
              tickLine={!removeYAxisLines}
              tickMargin={10}
            />
          )}

          <Bar
            dataKey={barDataKey}
            fill={theme.palette.primary.main}
            radius={10}
            barSize={barSize ? barSize : 8}
          >
            <LabelList dataKey={yAxisDataKey} position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </WrapperVerticalOrHorizontalBar>
  );
};

export default VerticalOrHorizontalBar;
