import { HeaderWithSearch } from 'ui';
import { Wrapper } from './TicketsPage.styled';
import { Outlet } from 'react-router';

const TicketsPage = () => {
  return (
    <Wrapper>
      <HeaderWithSearch />
      <Outlet />
    </Wrapper>
  );
};

export default TicketsPage;
