import { BYTES_IN_2MB } from 'constants/validation';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { getCreateProfileError } from 'reduxStore/createProfile/createProfileSelectors';
import { ROUTES } from 'routes/routes.const';
import { getBase64 } from 'utils';
import {
  HeaderOfView,
  Title,
  WrapperWhite,
  Error,
} from './AccountSettingsView.styled';
import {
  BlockedInput,
  ColorChangingButton,
  Input,
  SectionUploadLarge,
} from 'ui';
import { theme } from 'theme';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Box, FormControl, Grid } from '@mui/material';
import {
  getAdminInfo,
  getMainOrganization,
} from 'reduxStore/organization/organizationSelectors';
import createValidationSchema from './validationSchema';
import MuiPhoneNumber from 'material-ui-phone-number';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import { IUpdateMSPContactBody } from 'types/api.type';
import { updateMSPContact } from 'reduxStore/createProfile/createProfileOperations';
import { retrieveAccountInfo } from 'reduxStore/organization/organizationOperations';

interface IAccountFormValues {
  first_name: string;
  last_name: string;
  email: string;
  phone: string | null;
  role_name: string;
}

const AccountSettingsView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const error = useAppSelector(getCreateProfileError);
  const mainOrganization = useAppSelector(getMainOrganization);
  const admin = useAppSelector(getAdminInfo);

  useEffect(() => {
    dispatch(retrieveAccountInfo(null));
  }, [dispatch]);

  const handleEditChange = () => {
    setIsEditing(prevState => !prevState);
  };
  const [base64Image, setBase64Image] = useState(admin?.icon || '');
  const [imageError, setImageError] = useState<string | null>(null);

  const handleDeleteImage = () => {
    setBase64Image('');
    setImageError(null);
  };

  const handleImageError = (error: string) => {
    setBase64Image('');
    setImageError(error);
    setTimeout(() => setImageError(null), 3000);
  };

  const handleUpload = async (image: File) => {
    if (!(image instanceof File)) {
      handleImageError('Invalid file type. Please upload a valid image file.');
      return;
    }
    setImageError(null);

    if (image.size > BYTES_IN_2MB) {
      handleImageError('Image size must be less than 2MB');
      return;
    }
    try {
      await getBase64(
        image,
        result => setBase64Image(result),
        () => handleImageError('Error uploading image. Please, try again')
      );
    } catch (err) {
      handleImageError('Error uploading image. Please, try again');
    }
  };

  const initialValues: IAccountFormValues = {
    first_name: admin?.first_name || '',
    last_name: admin?.last_name || '',
    email: admin?.email || '',
    phone: admin?.phone || null,
    role_name: mainOrganization?.role.name || '',
  };

  const handleSubmit = async (value: IAccountFormValues) => {
    const body: IUpdateMSPContactBody = {
      id: admin?.id ?? 0,
      first_name: value.first_name,
      last_name: value.last_name,
      email: value.email,
      phone:
        value.phone === '+' //TODO: need to find better way
          ? ''
          : value.phone === admin?.phone
            ? null
            : value.phone,
      role_id: mainOrganization?.role.id,
      icon: base64Image === admin?.icon ? null : base64Image,
    };
    try {
      await dispatch(updateMSPContact(body)).unwrap();
      setIsEditing(false);
      dispatch(setModalProps({ radius: '22px' }));
      dispatch(openModal());
      dispatch(setContent('AccountSettingsChangeConfirm'));
    } catch (error) {
      return;
    }
  };
  const handleClick = () => {
    navigate(ROUTES.DASHBOARD);
  };

  return (
    <WrapperWhite>
      <HeaderOfView>
        <Title>Personal Settings</Title>
        <ColorChangingButton
          onClick={handleEditChange}
          useFirstColor={isEditing ? 'false' : 'true'}
          firstTextAndBorderColor={theme.palette.primary.main}
          secondTextAndBorderColor={theme.palette.error.main}
          borderRadius="23px"
          borderSize="1px"
          fontSize="19px"
        >
          {isEditing ? 'Cancel' : 'Edit'}
        </ColorChangingButton>
      </HeaderOfView>
      {error && <Error>{error}</Error>}
      <Formik
        initialValues={initialValues}
        validationSchema={createValidationSchema()}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Box display="flex" flexDirection="row" gap="15vw" marginTop="46px">
              <Box>
                <Grid container spacing="38px" width="100%" maxWidth="630px">
                  <Grid item xs={12}>
                    <Field
                      component={Input}
                      name="first_name"
                      label="First Name"
                      helperText={<ErrorMessage name="first_name" />}
                      disabled={!isEditing}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={Input}
                      name="last_name"
                      label="Last Name"
                      helperText={<ErrorMessage name="last_name" />}
                      disabled={!isEditing}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <MuiPhoneNumber
                        disabled={!isEditing}
                        defaultCountry={'us'}
                        name="phone"
                        value={values.phone}
                        label="Phone Number"
                        variant="outlined"
                        placeholder="Enter Phone Number"
                        onChange={value => setFieldValue('phone', value)}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '12px',
                            height: '59px',
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={BlockedInput}
                      name="email"
                      label="Email"
                      helperText={<ErrorMessage name="email" />}
                      isBlocked={isEditing ? 'true' : undefined}
                      blockedText="Oops! You can't change your email."
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={BlockedInput}
                      name="role_name"
                      label="Role"
                      helperText={<ErrorMessage name="role_name" />}
                      isBlocked={isEditing ? 'true' : undefined}
                      blockedText="Oops! You can't change your role."
                    />
                  </Grid>
                </Grid>
              </Box>
              <SectionUploadLarge
                handleUpload={handleUpload}
                onDelete={handleDeleteImage}
                imageUrl={base64Image}
                error={imageError}
                notAvailableToEdit={!isEditing ? 'true' : undefined}
                title="Profile Picture"
              />
            </Box>
            <Box
              marginTop="20px"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              width="100%"
            >
              <ColorChangingButton
                width="129px"
                height="59px"
                borderRadius="12px"
                useFirstColor={isEditing ? 'false' : 'true'}
                firstBackgroundColor={theme.palette.secondary.light}
                firstTextAndBorderColor={theme.palette.common.white}
                secondBackgroundColor={theme.palette.primary.main}
                secondTextAndBorderColor={theme.palette.common.white}
                type={isEditing ? 'submit' : 'button'}
                onClick={isEditing ? undefined : handleClick}
              >
                {isEditing ? 'Save' : 'Back'}
              </ColorChangingButton>
            </Box>
          </Form>
        )}
      </Formik>
    </WrapperWhite>
  );
};

export default AccountSettingsView;
