import {
  Box,
  BoxProps,
  styled,
  TextField,
  Typography,
  TypographyProps,
} from '@mui/material';
import { INTER, ISTOK_WEB } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

export const WrapperCreateAssignment = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  width: '50vw',
  height: '70vh',
  flexDirection: 'column',
  gap: '50px',
  padding: '4%',
}));

export const Title = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '36px',
  fontWeight: 600,
  lineHeight: '48px',
  letterSpacing: '-0.701px',
}));

export const MenuItemText = styled(Typography)(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '21px',
  letterSpacing: '-0.308px',
}));

export const CustomMenuItem = {
  display: 'flex',
  justifyContent: 'center',
};

export const MenuItemBox = styled('li')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '21px',
  letterSpacing: '-0.308px',
}));

export const Error = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.error.main,
  fontFamily: ISTOK_WEB,
  fontSize: '16px',
  lineHeight: '150%',
  fontWeight: '400',
  marginTop: '10px',
  letterSpacing: '-0.352px',
}));

export const CustomTextField = styled(TextField)(({ theme }) => ({
  width: '100%',

  '& .MuiInputBase-root': {
    height: '59px',
    borderRadius: '12px',
  },
  '& label': {
    padding: '0px 6px',
  },
  '& .MuiInputBase-input': {
    paddingLeft: '20px',
    color: theme.palette.primary.dark,
    fontFamily: INTER,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '21px',
    letterSpacing: '-0.308px',
  },
  '& legend': {
    marginLeft: '5px',
  },
}));
