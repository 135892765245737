import { useAppDispatch, useAppSelector } from 'hooks';
import { Wrapper } from './SaaSAlertsEventsLow.styled';
import {
  SAAS_ALERTS_EVENTS_CLIENT_VIEW_LOW_TABLE,
  SAAS_ALERTS_EVENTS_MSP_VIEW_LOW_TABLE,
} from 'constants/tablesHead';
import { useEffect } from 'react';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';
import {
  getSaaSAlertsEvents,
  getSaaSAlertsSeverityLevels,
  getToolsSelectedOrgId,
} from 'reduxStore/tools/toolsSelectors';
import { ClientModeDataTable } from 'ui';
import { ISaaSAlertsEventsBody } from 'types/saasAlerts.type';
import { retrieveSaaSAlertsEvents } from 'reduxStore/tools/toolsOperations';

const SaaSAlertsEventsLow = () => {
  const dispatch = useAppDispatch();
  const mainOrgId = useAppSelector(getMainOrganizationID);
  const selectedOrgId = useAppSelector(getToolsSelectedOrgId);
  const lowEvents = useAppSelector(getSaaSAlertsEvents);
  const severityLevels = useAppSelector(getSaaSAlertsSeverityLevels);

  useEffect(() => {
    const body: ISaaSAlertsEventsBody = {
      page: {
        size: 100,
        num: 0,
      },
      filter: {
        client_id: mainOrgId === selectedOrgId ? null : selectedOrgId,
        severity_id: severityLevels.find(level => level.code === 'low')?.id,
      },
      sort_key_id: 0,
    };

    dispatch(retrieveSaaSAlertsEvents(body));
  }, [dispatch, mainOrgId, selectedOrgId, severityLevels]);

  const reformatedLowEvents = Array.isArray(lowEvents)
    ? lowEvents.map(lowEvent => {
        return {
          organization: lowEvent.client.name,
          product: lowEvent.app.name,
          date_time: `${lowEvent.stamp.split(' ')[0]} - ${lowEvent.stamp.split(' ')[1]}`,
          user: `${lowEvent.user?.name ?? 'No Name'} - ${lowEvent.user?.email}`,
          ip_location: `${lowEvent.ip?.address} - ${lowEvent.ip?.owner?.name} - ${lowEvent.loc?.country.name} - ${lowEvent.loc?.region} - ${lowEvent.loc?.city}`,
          description: `${lowEvent.alert.code ?? ' - '} ${lowEvent.alert.name}`,
          id: lowEvent.id,
        };
      })
    : [];

  const handleReload = async () => {
    const body: ISaaSAlertsEventsBody = {
      page: {
        size: 100,
        num: 0,
      },
      filter: {
        client_id: mainOrgId === selectedOrgId ? null : selectedOrgId,
        severity_id: severityLevels.find(level => level.code === 'low')?.id,
      },
      sort_key_id: 0,
    };

    try {
      await dispatch(retrieveSaaSAlertsEvents(body)).unwrap();
    } catch (error) {
      return;
    }
  };

  return (
    <Wrapper>
      <ClientModeDataTable
        title="Real Time Low Alerts"
        handleReload={handleReload}
        rows={reformatedLowEvents}
        columns={
          mainOrgId === selectedOrgId
            ? SAAS_ALERTS_EVENTS_MSP_VIEW_LOW_TABLE
            : SAAS_ALERTS_EVENTS_CLIENT_VIEW_LOW_TABLE
        }
        addCreateTicket="true"
      />
    </Wrapper>
  );
};

export default SaaSAlertsEventsLow;
