import { ClientModeDataTable, FlatButton } from 'ui';
import {
  CustomMenuItem,
  CustomTextField,
  MenuItemBox,
  MenuItemText,
  Wrapper,
} from './ConnectWiseUsers.styled';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';
import { getToolsSelectedOrgId } from 'reduxStore/tools/toolsSelectors';
import { getMSPClients } from 'reduxStore/createProfile/createProfileSelectors';
import React, { useEffect, useState } from 'react';
import { retrieveMSPClients } from 'reduxStore/createProfile/createProfileOperations';
import { IClientStatisticBody } from 'types';
import {
  CONNECT_WISE_CLIENT_VIEW_USERS_TABLE,
  CONNECT_WISE_MSP_VIEW_USERS_TABLE,
} from 'constants/tablesHead';
import { IConnectWiseUser } from 'types/connectWise.type';
import { SECURITY_ROLES, WORK_ROLES } from 'constants/filterArrays';
import { generateConnectWiseUsers } from 'utils/mockDataGenerators';
import parsePhoneNumber from 'libphonenumber-js';

interface IConnectWiseUsersProps {
  organization: string;
  security_role: string;
  work_role: string;
}

const ConnectWiseUsers = () => {
  const dispatch = useAppDispatch();

  const mainOrgId = useAppSelector(getMainOrganizationID);
  const selectedOrgId = useAppSelector(getToolsSelectedOrgId);
  const clients = useAppSelector(getMSPClients);

  useEffect(() => {
    dispatch(retrieveMSPClients(null));
  }, [dispatch, mainOrgId]);

  const [users, setUsers] = useState<IConnectWiseUser[]>([]);

  const reformatedUsers = users.map(user => {
    return {
      organization: user.organization,
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone
        ? parsePhoneNumber(user.phone)?.formatInternational()
        : '-',
      email: user.email,
      security_roles: user.security_roles.join(', '),
      work_roles: user.work_roles.join(', '),
      id: user.id,
    };
  });

  const initialValues: IConnectWiseUsersProps = {
    organization: '',
    security_role: 'all',
    work_role: 'all',
  };

  const handleSubmit = (value: IConnectWiseUsersProps) => {
    const amount =
      (value.organization !== '' && value.organization !== null) ||
      value.security_role !== 'all' ||
      value.work_role !== 'all'
        ? 67
        : 134;
    setUsers(
      generateConnectWiseUsers(
        amount,
        value.organization ? value.organization : undefined,
        value.security_role !== 'all' ? value.security_role : undefined,
        value.work_role !== 'all' ? value.work_role : undefined,
        clients.map(client => {
          return client.name;
        })
      )
    );
  };

  return (
    <Wrapper>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ setFieldValue }) => (
          <Form>
            <Grid container display="flex" flexDirection="row" gap="20px">
              {mainOrgId === selectedOrgId && (
                <Grid item xs={3}>
                  <Field name="organization" id="organization">
                    {({ field }: any) => (
                      <Autocomplete
                        {...field}
                        id="organization"
                        options={clients ?? []}
                        value={
                          clients.find(client => client.name === field.value) ||
                          null
                        }
                        getOptionLabel={(client: IClientStatisticBody) =>
                          client.name
                        }
                        renderOption={(props, client: IClientStatisticBody) => (
                          <MenuItemBox
                            {...(props as React.HTMLAttributes<HTMLLIElement>)}
                            key={props.key}
                          >
                            {client.name}
                          </MenuItemBox>
                        )}
                        onChange={(
                          event: React.ChangeEvent<{}>,
                          newValue: IClientStatisticBody | null
                        ) =>
                          setFieldValue('organization', newValue?.name ?? null)
                        }
                        renderInput={(
                          params: AutocompleteRenderInputParams
                        ) => (
                          <CustomTextField
                            {...params}
                            label="Choose Organization"
                            placeholder="Organizations"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{
                              htmlFor: 'organization',
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    )}
                  </Field>
                </Grid>
              )}
              <Grid item xs={3}>
                <Field
                  name="security_role"
                  as={TextField}
                  id="security_role"
                  label="Security Role"
                  variant="outlined"
                  select
                  fullWidth
                  InputLabelProps={{ htmlFor: 'security_role', shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      height: '40px',
                    },
                  }}
                >
                  <MenuItem
                    key={`sr`}
                    value={'all'}
                    style={CustomMenuItem}
                    //onClick={() => setFieldValue('last_activity', securityRole)}
                  >
                    <MenuItemText>All Possible Roles</MenuItemText>
                  </MenuItem>
                  {SECURITY_ROLES.map((securityRole, index) => (
                    <MenuItem
                      key={index}
                      value={securityRole}
                      style={CustomMenuItem}
                      onClick={() =>
                        setFieldValue('security_role', securityRole)
                      }
                    >
                      <MenuItemText>{securityRole}</MenuItemText>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="work_role"
                  as={TextField}
                  id="work_role"
                  label="Work Role"
                  variant="outlined"
                  select
                  fullWidth
                  InputLabelProps={{ htmlFor: 'work_role', shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      height: '40px',
                    },
                  }}
                >
                  <MenuItem
                    key={`sr`}
                    value={'all'}
                    style={CustomMenuItem}
                    //onClick={() => setFieldValue('last_activity', securityRole)}
                  >
                    <MenuItemText>All Possible Roles</MenuItemText>
                  </MenuItem>
                  {WORK_ROLES.map((workRole, index) => (
                    <MenuItem
                      key={index}
                      value={workRole}
                      style={CustomMenuItem}
                      onClick={() => setFieldValue('work_role', workRole)}
                    >
                      <MenuItemText>{workRole}</MenuItemText>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={2}>
                <FlatButton
                  width="100%"
                  type="submit"
                  variant="contained"
                  height="40px"
                >
                  List Users
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <ClientModeDataTable
        rows={reformatedUsers}
        columns={
          mainOrgId === selectedOrgId
            ? CONNECT_WISE_MSP_VIEW_USERS_TABLE
            : CONNECT_WISE_CLIENT_VIEW_USERS_TABLE
        }
        nowRowsText="No Rows. For them to appear please select filters and press List Users"
      />
    </Wrapper>
  );
};

export default ConnectWiseUsers;
