import { ROUTES } from 'routes/routes.const';
import {
  WrapperChildren,
  WrapperConnectWiseDevicesPage,
} from './ConnectWiseDevicesPage.styled';
import { Outlet } from 'react-router';
import { TextTabs } from 'ui';

const ConnectWiseDevicesPage = () => {
  const navOptions = [
    {
      title: 'Computers',
      url: ROUTES.CONNECT_WISE_DEVICES,
    },
    {
      title: 'Network Devices',
      url: ROUTES.CONNECT_WISE_NETWORK_DEVICES,
    },
  ];

  return (
    <WrapperConnectWiseDevicesPage>
      <WrapperChildren>
        <TextTabs height="20px" options={navOptions} hierarchy="child" />
        <Outlet />
      </WrapperChildren>
    </WrapperConnectWiseDevicesPage>
  );
};

export default ConnectWiseDevicesPage;
