import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'services/api/organization';
import {
  IAuditLogReferencesBody,
  IAuditLogsBody,
  IUpdateOrgBody,
} from 'types/api.type';

const retrieveAccountInfo = createAsyncThunk(
  'organization/getAccountInfo',
  async (def: null, { rejectWithValue }) => {
    try {
      const result = await api.postGetAccountInfo(def);
      return result;
    } catch (error: any) {
      console.error('Get account information error: ', error);
      return rejectWithValue(error.message);
    }
  }
);

const retrieveMSPOrganization = createAsyncThunk(
  'organization/retrieveMSPOrganization',
  async (orgId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveOrganization(orgId); // in the future will have different API calls then retrieveClientOrganization
      return result;
    } catch (error: any) {
      console.error('Getting MSP organization error: ', error);
      return rejectWithValue(error.message);
    }
  }
);

const retrieveClientOrganization = createAsyncThunk(
  'organization/retrieveClientOrganization',
  async (orgId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveOrganization(orgId); // in the future will have different API calls then retrieveMSPOrganization
      return result;
    } catch (error: any) {
      console.error('Getting client organization error: ', error);
      return rejectWithValue(error.message);
    }
  }
);

const updateOrganization = createAsyncThunk(
  'organization/updateOrganization',
  async (body: IUpdateOrgBody, { rejectWithValue }) => {
    try {
      const result = await api.postUpdateOrganization(body);
      return result;
    } catch (error: any) {
      console.error('Update organization error: ', error);
      return rejectWithValue(error.message);
    }
  }
);

const retrieveClientIntegrations = createAsyncThunk(
  'organization/retrieveClientIntegrations',
  async (clientId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveClientIntegrations(clientId);
      return result;
    } catch (error: any) {
      console.error('Get client integrations error: ', error);
      return rejectWithValue(error.message);
    }
  }
);

const retrieveAuditLogs = createAsyncThunk(
  'organization/retrieveAuditLogs',
  async (body: IAuditLogsBody, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveAuditLogs(body);
      return result;
    } catch (error: any) {
      console.error('retrieve audit logs error: ', error);
      return rejectWithValue(error.message);
    }
  }
);

const retrieveAuditLogRefs = createAsyncThunk(
  'organization/retrieveAuditLogRefs',
  async (body: IAuditLogReferencesBody, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveAuditLogRefs(body);
      return result;
    } catch (error: any) {
      console.error('retrieve audit log refs error: ', error);
      return rejectWithValue(error.message);
    }
  }
);

export {
  retrieveAccountInfo,
  retrieveMSPOrganization,
  retrieveClientOrganization,
  updateOrganization,
  retrieveClientIntegrations,
  retrieveAuditLogs,
  retrieveAuditLogRefs,
};
