import React, { useEffect, useState } from 'react';
import {
  CustomMenuItem,
  CustomTextField,
  MenuItemBox,
  MenuItemText,
  SubTitle,
  TitleAndGraph,
  Wrapper,
  WrapperGraphs,
} from './ConnectWiseAlerts.styled';
import { IConnectWiseAlert } from 'types/connectWise.type';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';
import { getToolsSelectedOrgId } from 'reduxStore/tools/toolsSelectors';
import { getMSPClients } from 'reduxStore/createProfile/createProfileSelectors';
import { retrieveMSPClients } from 'reduxStore/createProfile/createProfileOperations';
import {
  CONNECT_WISE_CLIENT_VIEW_ALERTS_TABLE,
  CONNECT_WISE_MSP_VIEW_ALERTS_TABLE,
} from 'constants/tablesHead';
import { ClientModeDataTable, FlatButton } from 'ui';
import { generateConnectWiseAlerts } from 'utils/mockDataGenerators';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import {
  CONNECT_WISE_ALERTS_ACTIONS,
  PRIORITIES,
} from 'constants/filterArrays';
import { IClientStatisticBody } from 'types';
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';
import { theme } from 'theme';

interface IConnectWiseAlertsProps {
  organization: string;
  priority: string;
}

const ConnectWiseAlerts = () => {
  const dispatch = useAppDispatch();

  const mainOrgId = useAppSelector(getMainOrganizationID);
  const selectedOrgId = useAppSelector(getToolsSelectedOrgId);
  const clients = useAppSelector(getMSPClients);

  useEffect(() => {
    dispatch(retrieveMSPClients(null));
  }, [dispatch, mainOrgId]);

  const [alerts, setAlerts] = useState<IConnectWiseAlert[]>([]);

  const reformatedAlerts = alerts.map(alert => {
    return {
      id: alert.id,
      organization: alert.organization,
      name: alert.name,
      description: alert.description,
      priority: alert.priority,
      triggerConditions: alert.triggerConditions,
      associatedMonitor: alert.associatedMonitor,
      action: alert.action,
      notificationRecipients: alert.notificationRecipients.join(', '),
      escalationPolicy: alert.escalationPolicy,
      suppressionRules: alert.suppressionRules,
      alertFrequency: alert.alertFrequency,
      recoveryActions: alert.recoveryActions,
    };
  });

  const alertsByActions =
    alerts.length > 0
      ? CONNECT_WISE_ALERTS_ACTIONS.map(action =>
          alerts.filter(alert => alert.action === action)
        ).filter(actionGroup => actionGroup.length > 0)
      : [];

  const alertsByPriorities =
    alerts.length > 0
      ? PRIORITIES.map(priority =>
          alerts.filter(alert => alert.priority === priority)
        ).filter(priorityGroup => priorityGroup.length > 0)
      : [];

  const alertsByActionsForGraph =
    alertsByActions.length > 0
      ? alertsByActions.map(alert => {
          return {
            name: alert[0].action,
            value: alert.length,
            color: `#${Math.floor(Math.random() * 16777215)
              .toString(16)
              .padStart(6, '0')}`,
          };
        })
      : [];

  const alertsByPrioritiesForGraph =
    alertsByPriorities.length > 0
      ? alertsByPriorities.map(alert => {
          return {
            name: alert[0].priority,
            value: alert.length,
            color:
              alert[0].priority === 'Low'
                ? theme.palette.success.dark
                : alert[0].priority === 'Medium'
                  ? theme.palette.warning.dark
                  : alert[0].priority === 'High'
                    ? theme.palette.warning.contrastText
                    : theme.palette.error.dark,
          };
        })
      : [];

  const initialValues: IConnectWiseAlertsProps = {
    organization: '',
    priority: 'all',
  };

  const handleSubmit = (value: IConnectWiseAlertsProps) => {
    const amount =
      (value.organization !== '' && value.organization !== null) ||
      value.priority !== 'all'
        ? 67
        : 134;

    setAlerts(
      generateConnectWiseAlerts(
        amount,
        clients.map(client => {
          return client.name;
        }),
        value.organization ? value.organization : undefined,
        value.priority !== 'all' ? value.priority : undefined
      )
    );
  };
  return (
    <Wrapper>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ setFieldValue }) => (
          <Form>
            <Grid container display="flex" flexDirection="row" gap="20px">
              {mainOrgId === selectedOrgId && (
                <Grid item xs={3}>
                  <Field name="organization" id="organization">
                    {({ field }: any) => (
                      <Autocomplete
                        {...field}
                        id="organization"
                        options={clients ?? []}
                        value={
                          clients.find(client => client.name === field.value) ||
                          null
                        }
                        getOptionLabel={(client: IClientStatisticBody) =>
                          client.name
                        }
                        renderOption={(props, client: IClientStatisticBody) => (
                          <MenuItemBox
                            {...(props as React.HTMLAttributes<HTMLLIElement>)}
                            key={props.key}
                          >
                            {client.name}
                          </MenuItemBox>
                        )}
                        onChange={(
                          event: React.ChangeEvent<{}>,
                          newValue: IClientStatisticBody | null
                        ) =>
                          setFieldValue('organization', newValue?.name ?? null)
                        }
                        renderInput={(
                          params: AutocompleteRenderInputParams
                        ) => (
                          <CustomTextField
                            {...params}
                            label="Choose Organization"
                            placeholder="Organizations"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{
                              htmlFor: 'organization',
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    )}
                  </Field>
                </Grid>
              )}
              <Grid item xs={3}>
                <Field
                  name="priority"
                  as={TextField}
                  id="priority"
                  label="Priority"
                  variant="outlined"
                  select
                  fullWidth
                  InputLabelProps={{ htmlFor: 'priority', shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      height: '40px',
                    },
                  }}
                >
                  <MenuItem
                    key={`pr`}
                    value={'all'}
                    style={CustomMenuItem}
                    //onClick={() => setFieldValue('last_activity', securityRole)}
                  >
                    <MenuItemText>All Possible Priorities</MenuItemText>
                  </MenuItem>
                  {PRIORITIES.map((priority, index) => (
                    <MenuItem
                      key={index}
                      value={priority}
                      style={CustomMenuItem}
                      onClick={() => setFieldValue('priority', priority)}
                    >
                      <MenuItemText>{priority}</MenuItemText>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={2}>
                <FlatButton
                  width="100%"
                  type="submit"
                  variant="contained"
                  height="40px"
                >
                  List Alerts
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {alerts.length > 0 ? (
        <WrapperGraphs>
          <TitleAndGraph>
            <SubTitle>By Action</SubTitle>

            <PieChart width={700} height={350}>
              <Pie
                data={alertsByActionsForGraph}
                cx="50%"
                cy="50%"
                innerRadius={80}
                outerRadius={120}
                fill="#8884d8"
                paddingAngle={3}
                dataKey="value"
                label={({ name, value }) => `${name} (${value})`}
              >
                {alertsByActionsForGraph.map((element, index) => (
                  <Cell key={`cell-${index}`} fill={element.color} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </TitleAndGraph>

          <TitleAndGraph>
            <SubTitle>By Priority</SubTitle>
            <PieChart width={550} height={350}>
              <Pie
                data={alertsByPrioritiesForGraph}
                cx="50%"
                cy="50%"
                innerRadius={80}
                outerRadius={120}
                fill="#8884d8"
                paddingAngle={3}
                dataKey="value"
                label={({ name, value }) => `${name} (${value})`}
              >
                {alertsByPrioritiesForGraph.map((element, index) => (
                  <Cell key={`cell-${index}`} fill={element.color} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </TitleAndGraph>
        </WrapperGraphs>
      ) : (
        <></>
      )}

      <ClientModeDataTable
        rows={reformatedAlerts}
        columns={
          mainOrgId === selectedOrgId
            ? CONNECT_WISE_MSP_VIEW_ALERTS_TABLE
            : CONNECT_WISE_CLIENT_VIEW_ALERTS_TABLE
        }
        addCreateTicket="true"
        nowRowsText="No Rows. For them to appear please select filters and press List Alerts"
      />
    </Wrapper>
  );
};

export default ConnectWiseAlerts;
