import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setError, setLoading } from 'reduxStore/extraReducersHelpers';
import {
  IUserType,
  ICloudFromServerType,
  ICloudFromServerReformatedType,
  IRegionFromServerType,
  IRegionFromServerReformatedType,
} from 'types/organizations.type';
import { ILoadableState } from 'types/store.type';
import {
  createMSP,
  retrieveClouds,
  retrieveMSPRoles,
  createMSPContact,
  retrieveMSPContacts,
  retrieveAssignableContacts,
  createClient,
  retrieveMSPClients,
  retrieveConnectedToServiceClients,
  retrieveTools,
  checkAPICredentials,
  createIntegration,
  retrieveToolOrgs,
  deleteIntegration,
  removeClient,
  removeMSPContact,
  retrieveClientsRef,
  retrieveToolsRef,
  retrieveAssignableRoles,
  updateMSPContact,
  removeListOfClients,
  removeListOfMSPContacts,
} from './createProfileOperations';
import {
  IAssignableContactBody,
  IClientStatisticBody,
  ITool,
  IToolOrg,
  IReference,
  IReceiveContactBody,
} from 'types/api.type';

//TODO: revise all fields, reducers and builders and delete unused, think if we need new folder for some of functionality

interface ICreateProfileState extends ILoadableState {
  creatingOrganization: boolean;
  // import users
  usersListImported: IUserType[] | null;
  clouds: ICloudFromServerReformatedType[];
  mspRoles: IReference[];
  assignableRoles: IReference[];
  mspContacts: IReceiveContactBody[];
  assignableContacts: IAssignableContactBody[];
  mspClients: IClientStatisticBody[];
  connectedToServiceClients: IClientStatisticBody[];
  clientsAsRefs: IReference[];
  tools: ITool[];
  toolsAsRefs: IReference[];
  toolOrgs: IToolOrg[];
}

const initialState: ICreateProfileState = {
  creatingOrganization: false,
  // import users
  usersListImported: null,
  clouds: [],
  mspRoles: [],
  assignableRoles: [],
  mspContacts: [],
  assignableContacts: [],
  mspClients: [],
  connectedToServiceClients: [],
  clientsAsRefs: [],
  tools: [],
  toolsAsRefs: [],
  toolOrgs: [],
  loading: false,
  error: null,
};

const createProfileSlice = createSlice({
  name: 'createProfile',
  initialState,
  reducers: {
    setCreatingOrganization(state, action: PayloadAction<boolean>) {
      state.creatingOrganization = action.payload;
    },
    setUsersList(state, action: PayloadAction<IUserType[] | null>) {
      state.usersListImported = action.payload;
    },
    addOption(
      state,
      action: PayloadAction<{ cloudProvider: string; regionId: number }>
    ) {
      const { cloudProvider, regionId } = action.payload;
      state.clouds.forEach((cloud: ICloudFromServerReformatedType) => {
        if (cloud.name === cloudProvider) {
          cloud.regions.forEach((region: IRegionFromServerReformatedType) => {
            if (region.defaultRegion.id === regionId) {
              region.checked = true;
            }
          });
        }
      });
    },
    removeOption(
      state,
      action: PayloadAction<{ cloudProvider: string; regionId: number }>
    ) {
      const { cloudProvider, regionId } = action.payload;
      state.clouds.forEach((cloud: ICloudFromServerReformatedType) => {
        if (cloud.name === cloudProvider) {
          cloud.regions.forEach((region: IRegionFromServerReformatedType) => {
            if (region.defaultRegion.id === regionId) {
              region.checked = false;
            }
          });
        }
      });
    },

    selectAllOptions(state, action: PayloadAction<{ cloudProvider: string }>) {
      const { cloudProvider } = action.payload;
      state.clouds.forEach((cloud: ICloudFromServerReformatedType) => {
        if (cloud.name === cloudProvider) {
          cloud.regions.forEach((region: IRegionFromServerReformatedType) => {
            region.checked = true;
          });
        }
      });
    },
    deselectAllOptions(
      state,
      action: PayloadAction<{ cloudProvider: string }>
    ) {
      const { cloudProvider } = action.payload;
      state.clouds.forEach((cloud: ICloudFromServerReformatedType) => {
        if (cloud.name === cloudProvider) {
          cloud.regions.forEach((region: IRegionFromServerReformatedType) => {
            region.checked = false;
          });
        }
      });
    },
    clearCreateProfileData() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      // createMaster
      .addCase(createMSP.pending, setLoading)
      .addCase(createMSP.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(createMSP.rejected, setError)
      // createClient
      .addCase(createClient.pending, setLoading)
      .addCase(createClient.fulfilled, state => {
        state.loading = false;
      })
      .addCase(createClient.rejected, setError)
      // retrieveClouds
      .addCase(retrieveClouds.pending, setLoading)
      .addCase(retrieveClouds.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) {
          const updatedClouds: ICloudFromServerReformatedType[] =
            payload.clouds.map((cloud: ICloudFromServerType) => {
              const regions: IRegionFromServerReformatedType[] =
                cloud.regions.map((region: IRegionFromServerType) => ({
                  defaultRegion: { ...region },
                  checked: false,
                }));
              return {
                ...cloud,
                regions: regions,
              };
            });
          state.clouds = updatedClouds;
        }
      })
      .addCase(retrieveClouds.rejected, setError)
      // retrieveMSPRoles
      .addCase(retrieveMSPRoles.pending, setLoading)
      .addCase(retrieveMSPRoles.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.mspRoles = payload.roles;
      })
      .addCase(retrieveMSPRoles.rejected, setError)
      // retrieveAssignableRoles
      .addCase(retrieveAssignableRoles.pending, setLoading)
      .addCase(retrieveAssignableRoles.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.assignableRoles = payload.roles;
      })
      .addCase(retrieveAssignableRoles.rejected, setError)
      // createMSPContact
      .addCase(createMSPContact.pending, setLoading)
      .addCase(createMSPContact.fulfilled, state => {
        state.loading = false;
      })
      .addCase(createMSPContact.rejected, setError)
      // updateMSPContact
      .addCase(updateMSPContact.pending, setLoading)
      .addCase(updateMSPContact.fulfilled, state => {
        state.loading = false;
      })
      .addCase(updateMSPContact.rejected, setError)
      // retrieveContacts
      .addCase(retrieveMSPContacts.pending, setLoading)
      .addCase(retrieveMSPContacts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.mspContacts = payload.contacts;
      })
      .addCase(retrieveMSPContacts.rejected, setError)
      // retrieveAssignableContacts
      .addCase(retrieveAssignableContacts.pending, setLoading)
      .addCase(retrieveAssignableContacts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.assignableContacts = payload.contacts;
      })
      .addCase(retrieveAssignableContacts.rejected, setError)
      // retrieveMSPClients
      .addCase(retrieveMSPClients.pending, setLoading)
      .addCase(retrieveMSPClients.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.mspClients = payload.clients;
      })
      .addCase(retrieveMSPClients.rejected, setError)
      // retrieveConnectedToServiceClients
      .addCase(retrieveConnectedToServiceClients.pending, setLoading)
      .addCase(
        retrieveConnectedToServiceClients.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.connectedToServiceClients = payload.clients;
        }
      )
      .addCase(retrieveConnectedToServiceClients.rejected, setError)
      // retrieveClientsRef
      .addCase(retrieveClientsRef.pending, setLoading)
      .addCase(retrieveClientsRef.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.clientsAsRefs = payload.clients;
      })
      .addCase(retrieveClientsRef.rejected, setError)
      // retrieveTools
      .addCase(retrieveTools.pending, setLoading)
      .addCase(retrieveTools.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.tools = payload.tools;
      })
      .addCase(retrieveTools.rejected, setError)
      // retrieveToolsRef
      .addCase(retrieveToolsRef.pending, setLoading)
      .addCase(retrieveToolsRef.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.toolsAsRefs = payload.tools;
      })
      .addCase(retrieveToolsRef.rejected, setError)
      // checkAPICredentials
      .addCase(checkAPICredentials.pending, setLoading)
      .addCase(checkAPICredentials.fulfilled, state => {
        state.loading = false;
      })
      .addCase(checkAPICredentials.rejected, setError)
      // createIntegration
      .addCase(createIntegration.pending, setLoading)
      .addCase(createIntegration.fulfilled, state => {
        state.loading = false;
      })
      .addCase(createIntegration.rejected, setError)
      // retrieveToolOrgs
      .addCase(retrieveToolOrgs.pending, setLoading)
      .addCase(retrieveToolOrgs.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.toolOrgs = payload.tool_orgs;
      })
      .addCase(retrieveToolOrgs.rejected, setError)
      // deleteIntegration
      .addCase(deleteIntegration.pending, setLoading)
      .addCase(deleteIntegration.fulfilled, state => {
        state.loading = false;
      })
      .addCase(deleteIntegration.rejected, setError)
      // removeClient
      .addCase(removeClient.pending, setLoading)
      .addCase(removeClient.fulfilled, state => {
        state.loading = false;
      })
      .addCase(removeClient.rejected, setError)
      // removeListOfClients
      .addCase(removeListOfClients.pending, setLoading)
      .addCase(removeListOfClients.fulfilled, state => {
        state.loading = false;
      })
      .addCase(removeListOfClients.rejected, setError)
      // removeMSPContact
      .addCase(removeMSPContact.pending, setLoading)
      .addCase(removeMSPContact.fulfilled, state => {
        state.loading = false;
      })
      .addCase(removeMSPContact.rejected, setError)
      // removeListOfMSPContacts
      .addCase(removeListOfMSPContacts.pending, setLoading)
      .addCase(removeListOfMSPContacts.fulfilled, state => {
        state.loading = false;
      })
      .addCase(removeListOfMSPContacts.rejected, setError);
  },
});

export const {
  setCreatingOrganization,
  setUsersList,
  addOption,
  removeOption,
  selectAllOptions,
  deselectAllOptions,
  clearCreateProfileData,
} = createProfileSlice.actions;

export default createProfileSlice.reducer;
