import { getTools } from 'reduxStore/createProfile/createProfileSelectors';
import {
  TitleSection,
  Wrapper,
  WrapperElements,
  WrapperSection,
  Element,
  LogoBox,
  TitleElement,
  MainTextElement,
} from './ServicesInfo.styled';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { retrieveTools } from 'reduxStore/createProfile/createProfileOperations';
import { getMainOrganization } from 'reduxStore/organization/organizationSelectors';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import { ROUTES } from 'routes/routes.const';
import {
  setToolsSelectedOrgId,
  setToolsSelectedOrgName,
  setToolsSelectedToolId,
} from 'reduxStore/tools/toolsSlice';
import { retrieveHuntressGeneralStatistics } from 'reduxStore/tools/toolsOperations';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';

const ServicesInfo = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const tools = useAppSelector(getTools);
  const mainOrg = useAppSelector(getMainOrganization);

  useEffect(() => {
    dispatch(retrieveTools(null));
  }, [dispatch]);

  const connectedTools = tools.filter(tool =>
    mainOrg?.role.id === 1
      ? (tool.intgrn !== null && tool.intgrn.has_creds === true) ||
        (tool.clients_count !== null && tool.clients_count !== 0)
      : tool.clients_count !== null && tool.clients_count !== 0
  );

  const disconnectedTools = tools.filter(
    tool =>
      !connectedTools.includes(tool) &&
      tool.code !== 'vsa-x' &&
      tool.code !== 'ninja-one'
  );

  const comingSoonTools = tools.filter(
    tool => !connectedTools.includes(tool) && !disconnectedTools.includes(tool)
  );

  const promotionalTextsForServices = [
    {
      code: 'huntress',
      text: 'Huntress - Detect and stop hidden threats with automated threat detection, incident response, and persistent foothold removal.',
    },
    {
      code: 'saas',
      text: 'SaaS Alerts - Monitor and secure SaaS applications with real-time anomaly detection, compliance reporting, and automated alerts.',
    },
    {
      code: 'vsa-x',
      text: 'Kaseya - Enhance IT automation with AI-driven endpoint management, remote monitoring, and powerful cybersecurity tools.',
    },
    {
      code: 'ninja-one',
      text: 'Ninja One - Simplify endpoint management with fast patching, automated backups, and remote access for IT teams.',
    },
    {
      code: 'connect-wise',
      text: 'Connect Wise - Streamline IT operations with automated RMM, ticketing, and patch management to improve efficiency and security.',
    },
  ];

  const handleConnectedToolSelect = async (serviceId: number) => {
    try {
      if (tools.find(tool => tool.id === serviceId)?.name === 'Huntress') {
        await dispatch(retrieveHuntressGeneralStatistics(mainOrg?.id)).unwrap();
        dispatch(setToolsSelectedOrgName(mainOrg?.name));
        dispatch(setToolsSelectedOrgId(mainOrg?.id));
        dispatch(setToolsSelectedToolId(serviceId));
        navigate(ROUTES.HUNTRESS_DASHBOARD);
      } else if (
        tools.find(tool => tool.id === serviceId)?.name === 'SaaS Alerts'
      ) {
        dispatch(setToolsSelectedOrgName(mainOrg?.name));
        dispatch(setToolsSelectedOrgId(mainOrg?.id));
        dispatch(setToolsSelectedToolId(serviceId));
        navigate(ROUTES.SAAS_ALERTS_OVERVIEW);
      } else if (
        tools.find(tool => tool.id === serviceId)?.name === 'Connect Wise'
      ) {
        dispatch(setToolsSelectedOrgName(mainOrg?.name));
        dispatch(setToolsSelectedOrgId(mainOrg?.id));
        dispatch(setToolsSelectedToolId(serviceId));
        navigate(ROUTES.CONNECT_WISE_DASHBOARD);
      }
    } catch (error) {
      return;
    }
  };

  const handleComingSoonToolSelect = (serviceCode: string) => {
    dispatch(
      setModalProps({
        radius: '18px',
        title: promotionalTextsForServices.find(
          text => text.code === serviceCode
        )?.text,
      })
    );
    dispatch(openModal());
    dispatch(setContent('ServicesPromoteModal'));
  };

  const handleDisconnectedToolSelect = (
    serviceCode: string,
    serviceId: number
  ) => {
    dispatch(
      setModalProps({
        radius: '18px',
        title: promotionalTextsForServices.find(
          text => text.code === serviceCode
        )?.text,
        anyNumber: serviceId,
        anyIsStatement: 'true',
      })
    );
    dispatch(openModal());
    dispatch(setContent('ServicesPromoteModal'));
  };

  return (
    <Wrapper>
      {connectedTools.length > 0 && (
        <WrapperSection>
          <TitleSection>Connected Services</TitleSection>
          <WrapperElements>
            {connectedTools.map((tool, index) => (
              <Element
                key={index}
                onClick={() => handleConnectedToolSelect(tool.id)}
              >
                {tool.icon ? (
                  <LogoBox src={tool.icon} />
                ) : (
                  <BrokenImageOutlinedIcon sx={{ fontSize: '24px' }} />
                )}
                <TitleElement>{tool.name}</TitleElement>
                <MainTextElement>{`Alerts - ${tool.code !== 'connect-wise' || tool.clients_count === 0 ? tool.alerts_count : 134}, Clients - ${tool.clients_count}`}</MainTextElement>
              </Element>
            ))}
          </WrapperElements>
        </WrapperSection>
      )}
      {disconnectedTools.length > 0 && (
        <WrapperSection>
          <TitleSection>Disconnected Services</TitleSection>
          <WrapperElements>
            {disconnectedTools.map((tool, index) => (
              <Element
                key={index}
                onClick={() => handleDisconnectedToolSelect(tool.code, tool.id)}
              >
                {tool.icon ? (
                  <LogoBox src={tool.icon ?? ''} />
                ) : (
                  <BrokenImageOutlinedIcon sx={{ fontSize: '24px' }} />
                )}
                <TitleElement>{tool.name}</TitleElement>
              </Element>
            ))}
          </WrapperElements>
        </WrapperSection>
      )}
      {comingSoonTools.length > 0 && (
        <WrapperSection>
          <TitleSection>Also Available</TitleSection>
          <WrapperElements>
            {comingSoonTools.map((tool, index) => (
              <Element
                key={index}
                onClick={() => handleComingSoonToolSelect(tool.code)}
              >
                {tool.icon ? (
                  <LogoBox src={tool.icon} />
                ) : (
                  <BrokenImageOutlinedIcon sx={{ fontSize: '24px' }} />
                )}
                <TitleElement>{tool.name}</TitleElement>
              </Element>
            ))}
          </WrapperElements>
        </WrapperSection>
      )}
    </Wrapper>
  );
};

export default ServicesInfo;
