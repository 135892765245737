import { ROUTES } from 'routes/routes.const';
import {
  WrapperChildren,
  WrapperSaaSAlertsMonitoringPage,
} from './SaaSAlertsMonitoringPage.styled';
import { TextTabs } from 'ui';
import { Navigate, Outlet, useLocation } from 'react-router';

const SaaSAlertsMonitoringPage = () => {
  const location = useLocation();

  const navOptions = [
    {
      title: 'Critical',
      url: ROUTES.SAAS_ALERTS_EVENT_MONITORING_CRITICAL,
    },
    {
      title: 'Medium',
      url: ROUTES.SAAS_ALERTS_EVENT_MONITORING_MEDIUM,
    },
    {
      title: 'Low',
      url: ROUTES.SAAS_ALERTS_EVENT_MONITORING_LOW,
    },
  ];

  // Redirect if the user visits /huntress_dashboard directly
  if (location.pathname === ROUTES.SAAS_ALERTS_EVENT_MONITORING) {
    return (
      <Navigate to={ROUTES.SAAS_ALERTS_EVENT_MONITORING_CRITICAL} replace />
    );
  }

  return (
    <WrapperSaaSAlertsMonitoringPage>
      <WrapperChildren>
        <TextTabs height="20px" options={navOptions} hierarchy="child" />
        <Outlet />
      </WrapperChildren>
    </WrapperSaaSAlertsMonitoringPage>
  );
};

export default SaaSAlertsMonitoringPage;
