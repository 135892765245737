import {
  Amount,
  LogoBox,
  SubTitle,
  Title,
  WrapperBottom,
  WrapperGeneralStatistics,
  WrapperIcons,
  WrapperTop,
} from './GeneralStatistics.styled';
import {
  IClientStatisticBody,
  IReceiveContactBody,
  IReference,
  ITool,
} from 'types/api.type';
import { RoundButton, ActionButton } from 'ui';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import { IAssignment, IClientContact } from 'types/assignments.type';

interface IGeneralStatisticsProps {
  mainActionButtonTitle?: string;
  title: string;
  data:
    | ITool[]
    | IClientStatisticBody[]
    | IReceiveContactBody[]
    | IAssignment[]
    | IClientContact[]
    | IReference[]
    | null;
  width?: string;
  height?: string;
  isactive?: 'true' | 'false';
  onClickAdd?: () => void;
  onClickMainAction?: () => void;
}

const GeneralStatistics = ({
  onClickAdd,
  onClickMainAction,
  mainActionButtonTitle,
  title,
  data,
  width,
  height,
  isactive,
}: IGeneralStatisticsProps) => {
  const icons: string[] = [];

  data
    ?.slice()
    .reverse()
    .forEach(element => {
      if ('icon' in element && element.icon) {
        icons.push(element.icon);
      }
    });

  return (
    <WrapperGeneralStatistics width={width} height={height} isactive={isactive}>
      <Box gap="5px">
        <WrapperTop>
          <Title>{title}</Title>
          {onClickAdd ? (
            <RoundButton
              width="52px"
              height="52px"
              type="submit"
              variant="contained"
              onClick={onClickAdd}
            >
              <AddIcon fontSize="medium" />
            </RoundButton>
          ) : (
            <></>
          )}
        </WrapperTop>
        <Amount>{data?.length}</Amount>
      </Box>
      <Box gap="5px">
        <SubTitle>Recent</SubTitle>
        <WrapperBottom>
          {icons.length !== 0 ? (
            <WrapperIcons>
              {icons.slice(0, 5).map((icon, index) => (
                <LogoBox key={index} src={icon} />
              ))}
            </WrapperIcons>
          ) : (
            <></>
          )}
          <ActionButton
            onClick={onClickMainAction}
            styles={{ flex: '1 1 auto' }}
          >
            {mainActionButtonTitle ? mainActionButtonTitle : 'View All'}
          </ActionButton>
        </WrapperBottom>
      </Box>
    </WrapperGeneralStatistics>
  );
};
export default GeneralStatistics;
