import { Divider, styled } from '@mui/material';
import Container, { ContainerProps } from '@mui/material/Container';
import { COLORS } from 'theme/colors.const';

export const HuntressContainer = styled(Container)<ContainerProps>(
  ({ theme }) => ({
    width: '100%',
    height: 'fit-content',
    minHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
    padding: '28px 79px 49px 49px',
    gap: '10px',
    borderRadius: '24px',
    background: theme.palette.background.paper,
    boxSizing: 'border-box',
  })
);

export const CustomDivider = styled(Divider)(() => ({
  marginLeft: '-49px',
  width: 'calc(100% + 128px)',
  height: '1px',
  borderColor: COLORS.gray,
}));
