import { Box, BoxProps, styled } from '@mui/material';

export const Wrapper = styled(Box)<BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  gap: '33px',
  paddingTop: '40px',
}));
