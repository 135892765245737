import {
  Box,
  BoxProps,
  styled,
  TextField,
  Typography,
  TypographyProps,
} from '@mui/material';
import { INTER } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

export const Wrapper = styled(Box)<BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  gap: '50px',
  paddingTop: '40px',
}));

export const WrapperGraphs = styled(Box)<BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'left',
  justifyContent: 'space-between',
}));

export const TitleAndGraph = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  gap: '10px',
}));

export const SubTitle = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '24px',
  fontWeight: 500,
  lineHeight: '28px',
  letterSpacing: '-0.308px',
}));

export const CustomTextField = styled(TextField)(({ theme }) => ({
  width: '100%',

  '& .MuiInputBase-root': {
    height: '40px',
    borderRadius: '4px',
  },
  '& label': {
    padding: '0px 6px',
  },
  '& .MuiInputBase-input': {
    paddingLeft: '20px',
    color: theme.palette.primary.dark,
    fontFamily: INTER,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '21px',
    letterSpacing: '-0.308px',
  },
  '& legend': {
    marginLeft: '5px',
  },
}));

export const MenuItemBox = styled('li')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '21px',
  letterSpacing: '-0.308px',
}));

export const MenuItemText = styled(Typography)(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '21px',
  letterSpacing: '-0.308px',
}));

export const CustomMenuItem = {
  display: 'flex',
  justifyContent: 'center',
};
