import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { INTER } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

export const Wrapper = styled(Box)<BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  gap: '10px',
}));

export const WrapperDeviceCategory = styled(Box)<BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '20px',
}));

export const DeviceType = styled(Box)<BoxProps>(() => ({
  background: COLORS.lightGray,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: '1 1 auto',
  padding: '10px 0px',
  borderRadius: '18px',
}));

export const Title = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.secondary,
  fontFamily: INTER,
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '150%',
  letterSpacing: '-2.2%',
}));

export const DeviceTypeTitle = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.secondary,
  fontFamily: INTER,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '150%',
  letterSpacing: '-2.2%',
}));

export const DeviceTypeCounter = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.secondary,
  fontFamily: INTER,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '150%',
  letterSpacing: '-2.2%',
}));
