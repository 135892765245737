import { RootState } from 'reduxStore/store';

const getIsMSP = (state: RootState) =>
  state.organization.mainOrganization?.is_msp;

const getAccountInfo = (state: RootState) => state.organization;

const getAdminInfo = (state: RootState) => state.organization.admin;

const getUserOrganizations = (state: RootState) =>
  state.organization.userOrganizations;

const getMainOrganization = (state: RootState) =>
  state.organization.mainOrganization;
const getMainOrganizationID = (state: RootState) =>
  state.organization.mainOrganization?.id;
const mainOrganizationExists = (state: RootState) =>
  !!state.organization.mainOrganization;

const userGotOrganizations = (state: RootState) =>
  !!state.organization.mainOrganization;

const getClients = (state: RootState) => state.organization.clients;

const getSelectedClient = (state: RootState) =>
  state.organization.selectedClient;

const getMSPInformation = (state: RootState) =>
  state.organization.mspInformation;

const getClientIntegrations = (state: RootState) =>
  state.organization.clientIntegrations;

const getTeamMemberToUpdate = (state: RootState) =>
  state.organization.teamMemberToUpdate;

const getDeleteList = (state: RootState) => state.organization.deleteList;

const getDeleteDataType = (state: RootState) =>
  state.organization.deleteDataType;

const getAuditLogs = (state: RootState) => state.organization.auditLogs;

const getAuditLogRefs = (state: RootState) => state.organization.auditLogRefs;

const getAmountOfElements = (state: RootState) =>
  state.organization.amountOfElements;

const getOrganizationError = (state: RootState) => state.organization.error;

const getOrganizationLoading = (state: RootState) => state.organization.loading;

export {
  getIsMSP,
  getAccountInfo,
  getAdminInfo,
  getUserOrganizations,
  getMainOrganization,
  getMainOrganizationID,
  mainOrganizationExists,
  userGotOrganizations,
  getClients,
  getSelectedClient,
  getMSPInformation,
  getClientIntegrations,
  getTeamMemberToUpdate,
  getDeleteList,
  getDeleteDataType,
  getAuditLogs,
  getAuditLogRefs,
  getAmountOfElements,
  getOrganizationError,
  getOrganizationLoading,
};
