import { IConnectWiseDeviceCategory } from 'types/connectWise.type';
import {
  DeviceType,
  DeviceTypeCounter,
  DeviceTypeTitle,
  Title,
  Wrapper,
  WrapperDeviceCategory,
} from './DeviceCategory.styled';

interface IDeviceCategoryProps {
  title: string;
  elements: IConnectWiseDeviceCategory[];
}

const DeviceCategory = ({ title, elements }: IDeviceCategoryProps) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <WrapperDeviceCategory>
        {elements.map((element, index) => (
          <DeviceType key={index}>
            {element.icon}
            <DeviceTypeTitle>{element.label}</DeviceTypeTitle>
            <DeviceTypeCounter>{element.count}</DeviceTypeCounter>
          </DeviceType>
        ))}
      </WrapperDeviceCategory>
    </Wrapper>
  );
};

export default DeviceCategory;
