import React from 'react';
import { Wrapper } from './ConnectWiseDashboard.styled';
import {
  LaptopMac,
  LaptopWindows,
  Storage,
  Dns,
  Cloud,
} from '@mui/icons-material';
import RouterIcon from '@mui/icons-material/Router';
import DvrIcon from '@mui/icons-material/Dvr';
import SecurityIcon from '@mui/icons-material/Security';
import LanIcon from '@mui/icons-material/Lan';
import WbCloudyOutlinedIcon from '@mui/icons-material/WbCloudyOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { DeviceCategory } from '../components';
import { IConnectWiseDeviceCategory } from 'types/connectWise.type';

const workstations: IConnectWiseDeviceCategory[] = [
  { icon: <LaptopWindows fontSize="large" />, label: 'Windows PC', count: 2 },
  { icon: <LaptopMac fontSize="large" />, label: 'Mac', count: 15 },
  { icon: <Storage fontSize="large" />, label: 'Windows Server', count: 65 },
  { icon: <Dns fontSize="large" />, label: 'Linux Server', count: 23 },
  { icon: <Cloud fontSize="large" />, label: 'VM Host', count: 9 },
];

const networkDevices: IConnectWiseDeviceCategory[] = [
  { icon: <RouterIcon fontSize="large" />, label: 'Router', count: 87 },
  { icon: <DvrIcon fontSize="large" />, label: 'Switch', count: 3 },
  { icon: <SecurityIcon fontSize="large" />, label: 'Firewall', count: 59 },
  { icon: <LanIcon fontSize="large" />, label: 'Generic', count: 31 },
  {
    icon: <MoreHorizOutlinedIcon fontSize="large" />,
    label: 'Other',
    count: 0,
  },
];

const clouds: IConnectWiseDeviceCategory[] = [
  {
    icon: <WbCloudyOutlinedIcon fontSize="large" />,
    label: 'Cloud Resources',
    count: 134,
  },
  {
    icon: <CloudDoneOutlinedIcon fontSize="large" />,
    label: 'Available',
    count: 43,
  },
  {
    icon: <CloudOffOutlinedIcon fontSize="large" />,
    label: 'Unavailable',
    count: 17,
  },
  {
    icon: <CloudDownloadOutlinedIcon fontSize="large" />,
    label: 'Downgraded',
    count: 8,
  },
  {
    icon: <HelpOutlineOutlinedIcon fontSize="large" />,
    label: 'Unknown',
    count: 33,
  },
];

const ConnectWiseDashboard = () => {
  return (
    <Wrapper>
      <DeviceCategory
        title="Workstations and Servers"
        elements={workstations}
      />
      <DeviceCategory title="Network Devices" elements={networkDevices} />
      <DeviceCategory title="Cloud Devices" elements={clouds} />
    </Wrapper>
  );
};

export default ConnectWiseDashboard;
