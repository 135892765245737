import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import persistReducer from 'redux-persist/es/persistReducer';
import authSlice from './auth/authSlice';
import organizationSlice from './organization/organizationSlice';
import createProfileSlice from './createProfile/createProfileSlice';
import modalSlice from './modal/modalSlice';
import toolsSlice from './tools/toolsSlice';
import assignmentsSlice from './assignments/assignmentsSlice';

const persistAuthConfig = {
  key: 'auth',
  storage: storageSession,
  whitelist: ['token', 'user', 'email'],
};

const persistOrgConfig = {
  key: 'organization',
  storage: storageSession,
  blacklist: ['error', 'loading'],
};

const persistCreateProfileConfig = {
  key: 'createProfile',
  storage: storageSession,
  blacklist: ['error', 'loading'],
};

const persistToolsConfig = {
  key: 'tools',
  storage: storageSession,
  blacklist: ['error', 'loading'],
};

const persistAssignmentsConfig = {
  key: 'assignments',
  storage: storageSession,
  blacklist: ['error', 'loading'],
};

const rootReducer = combineReducers({
  auth: persistReducer(persistAuthConfig, authSlice),
  organization: persistReducer(persistOrgConfig, organizationSlice),
  createProfile: persistReducer(persistCreateProfileConfig, createProfileSlice),
  modal: modalSlice,
  tools: persistReducer(persistToolsConfig, toolsSlice),
  assignments: persistReducer(persistAssignmentsConfig, assignmentsSlice),
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
