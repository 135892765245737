import { useAppDispatch, useAppSelector } from 'hooks';
import { Wrapper } from './HuntressReports.styled';
import {
  //getHuntressReportRefs,
  getHuntressReports,
  getToolsSelectedOrgId,
} from 'reduxStore/tools/toolsSelectors';
import { useEffect } from 'react';
import {
  retrieveHuntressReports,
  retrieveHuntressReportsRefs,
} from 'reduxStore/tools/toolsOperations';
import { IHuntressReportsBody } from 'types/huntress.type';
import { ClientModeDataTable } from 'ui';
import { HUNTRESS_REPORTS_TABLE } from 'constants/tablesHead';

const HuntressReports = () => {
  const dispatch = useAppDispatch();
  //NEEDED FOR FILTERS
  //const reportRefs = useAppSelector(getHuntressReportRefs);
  const selectedOrgId = useAppSelector(getToolsSelectedOrgId);
  const reports = useAppSelector(getHuntressReports);

  const reformatedReports = reports.map(report => {
    return {
      type: report.type.name,
      date_range: report.start + ' - ' + report.finish,
      id: reports.indexOf(report),
    };
  });

  useEffect(() => {
    const body: IHuntressReportsBody = {
      org_id: selectedOrgId,
      type_id: null,
    };
    dispatch(retrieveHuntressReportsRefs(selectedOrgId));
    dispatch(retrieveHuntressReports(body));
  }, [dispatch, selectedOrgId]);

  return (
    <Wrapper>
      <ClientModeDataTable
        columns={HUNTRESS_REPORTS_TABLE}
        rows={reformatedReports}
      />
    </Wrapper>
  );
};

export default HuntressReports;
