import {
  IConnectWiseAlert,
  IConnectWiseComputer,
  IConnectWiseNetworkDevice,
  IConnectWiseUser,
  ITicket,
} from 'types/connectWise.type';
import { faker } from '@faker-js/faker';
import {
  AVAILABILITY_STATUSES,
  CONNECT_WISE_ALERTS_ACTIONS,
  CONNECT_WISE_COMPUTER_TYPES,
  CONNECT_WISE_NETWORK_DEVICES_TYPES,
  OSES,
  PRIORITIES,
  SECURITY_ROLES,
  STATUSES,
  VENDORS,
  WORK_ROLES,
} from 'constants/filterArrays';

export const generateTickets = (
  amount: number,
  client?: string,
  user?: string,
  priority?: string,
  status?: string,
  clientNames?: string[],
  userNames?: string[]
): ITicket[] => {
  return Array.from({ length: amount }, (_, index) => ({
    id: index + 1,
    organization: client
      ? client
      : clientNames
        ? clientNames[faker.number.int({ min: 0, max: clientNames.length - 1 })]
        : [][faker.number.int({ min: 0, max: 1 })],
    summary: faker.word.words(3),
    contact_name: user
      ? user
      : userNames
        ? userNames[faker.number.int({ min: 0, max: userNames.length - 1 })]
        : [][faker.number.int({ min: 0, max: 1 })],
    contact_phone: faker.phone.number({ style: 'international' }),
    contact_email: faker.internet.email(),
    assigned_to: faker.internet.email(),
    status: status
      ? status
      : [...STATUSES][faker.number.int({ min: 0, max: STATUSES.length - 1 })],
    priority: priority
      ? priority
      : [...PRIORITIES][
          faker.number.int({ min: 0, max: PRIORITIES.length - 1 })
        ],
    date: faker.date.past().toISOString().split('T')[0],
  }));
};

export const generateConnectWiseUsers = (
  amount: number,
  client?: string,
  securityRole?: string,
  workRole?: string,
  clientNames?: string[]
): IConnectWiseUser[] => {
  return Array.from({ length: amount }, (_, index) => ({
    id: index + 1,
    organization: client
      ? client
      : clientNames
        ? clientNames[faker.number.int({ min: 0, max: clientNames.length - 1 })]
        : [][faker.number.int({ min: 0, max: 1 })],
    first_name: faker.person.firstName(),
    last_name: faker.person.lastName(),
    phone: faker.phone.number({ style: 'international' }),
    email: faker.internet.email(),
    assigned_to: faker.internet.email(),
    security_roles: securityRole
      ? [securityRole]
      : [...SECURITY_ROLES].sort(() => Math.random() - 0.5).slice(0, 3),
    work_roles: workRole
      ? [workRole]
      : [WORK_ROLES[faker.number.int({ min: 0, max: WORK_ROLES.length - 1 })]],
  }));
};

export const generateConnectWiseAlerts = (
  amount: number,
  clientNames: string[],
  client?: string,
  priority?: string
): IConnectWiseAlert[] => {
  return Array.from({ length: amount }, (_, index) => ({
    id: index + 1,
    organization: client
      ? client
      : clientNames[
          faker.number.int({
            min: 0,
            max: clientNames.length > 0 ? clientNames.length - 1 : 0,
          })
        ],
    name: faker.lorem.words(3),
    description: faker.lorem.sentence(),
    priority: priority
      ? priority
      : [...PRIORITIES][
          faker.number.int({ min: 0, max: PRIORITIES.length - 1 })
        ],
    triggerConditions: `CPU Usage > ${faker.number.int({ min: 80, max: 100 })}% for ${faker.number.int({ min: 5, max: 30 })} minutes`,
    associatedMonitor: [
      'CPU Performance Monitor',
      'Disk Usage Monitor',
      'Memory Usage Monitor',
    ][faker.number.int({ min: 0, max: 2 })],
    action: [...CONNECT_WISE_ALERTS_ACTIONS][
      faker.number.int({ min: 0, max: CONNECT_WISE_ALERTS_ACTIONS.length - 1 })
    ],
    notificationRecipients: Array.from(
      { length: faker.number.int({ min: 1, max: 3 }) },
      () => faker.internet.email()
    ),
    escalationPolicy: [
      'Escalate to Level 2 Support after 15 minutes',
      'Page on-call engineer after 30 minutes',
      'Notify CIO if unresolved for 1 hour',
    ][faker.number.int({ min: 0, max: 2 })],
    suppressionRules: [
      'Suppress during maintenance window',
      'Do not trigger outside business hours',
      'Only trigger once per day',
    ][faker.number.int({ min: 0, max: 2 })],
    alertFrequency: `${faker.number.int({ min: 1, max: 10 })} minutes`,
    recoveryActions: [
      'Close ticket upon resolution',
      'Send recovery notification',
      'Log recovery event',
    ][faker.number.int({ min: 0, max: 2 })],
  }));
};

export const generateConnectWiseComputers = (
  amount: number,
  client?: string,
  os?: string,
  type?: string,
  clientNames?: string[]
): IConnectWiseComputer[] => {
  return Array.from({ length: amount }, (_, index) => ({
    id: index + 1,
    organization: client
      ? client
      : clientNames
        ? clientNames[faker.number.int({ min: 0, max: clientNames.length - 1 })]
        : [][faker.number.int({ min: 0, max: 1 })],
    name: faker.lorem.words(1),
    last_log_in: faker.date.past().toISOString().split('T')[0],
    type: type
      ? type
      : [...CONNECT_WISE_COMPUTER_TYPES][
          faker.number.int({
            min: 0,
            max: CONNECT_WISE_COMPUTER_TYPES.length - 1,
          })
        ],
    last_user: faker.internet.email(),
    os: os
      ? os
      : [...OSES][
          faker.number.int({
            min: 0,
            max: OSES.length - 1,
          })
        ],
  }));
};

export const generateConnectWiseNetworkDevices = (
  amount: number,
  client?: string,
  vendor?: string,
  type?: string,
  status?: string,
  clientNames?: string[]
): IConnectWiseNetworkDevice[] => {
  return Array.from({ length: amount }, (_, index) => ({
    id: index + 1,
    organization: client
      ? client
      : clientNames
        ? clientNames[faker.number.int({ min: 0, max: clientNames.length - 1 })]
        : [][faker.number.int({ min: 0, max: 1 })],
    name: faker.lorem.words(1),
    type: type
      ? type
      : [...CONNECT_WISE_NETWORK_DEVICES_TYPES][
          faker.number.int({
            min: 0,
            max: CONNECT_WISE_NETWORK_DEVICES_TYPES.length - 1,
          })
        ],
    ip: faker.internet.ipv4(),
    status: status
      ? status
      : [...AVAILABILITY_STATUSES][
          faker.number.int({
            min: 0,
            max: AVAILABILITY_STATUSES.length - 1,
          })
        ],
    vendor: vendor
      ? vendor
      : [...VENDORS][
          faker.number.int({
            min: 0,
            max: VENDORS.length - 1,
          })
        ],
  }));
};
